import React from "react";
import {
   FormControl,
   IconButton,
   InputLabel,
   Paper,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Select,
   Typography,
   MenuItem,
} from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { Header } from "../components";
import { useCartContext } from "../contexts/CartProvider";
import { formatMoney } from "../services/utils";
import { useTopProducts } from "../services/hooks/useTopProducts";

const TopProducts = () => {
   const { onAdd } = useCartContext();
   const { data: products } = useTopProducts();

   function handleAddToCart(product_id) {
      onAdd(
         {
            id: product_id,
            name: products.find((product) => product.id === product_id).name,
            image: products.find((product) => product.id === product_id).image,
            price: products.find((product) => product.id === product_id)
               .sales_price,
            tax: products.find((product) => product.id === product_id).tax,
            is_teetime: false,
         },
         1
      );
   }
   return (
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl h-screen">
         <Header title="Top Products" />
         <div>
            <TableContainer component={Paper} sx={{ width: "80%" }}>
               <Table sx={{ minWidth: 300 }} aria-label="simple table">
                  <TableHead>
                     <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell align="left">Image</TableCell>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="center">Price</TableCell>
                        <TableCell align="left"></TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {products?.map((product) => (
                        <TableRow key={product.id}>
                           <TableCell>{product.id}</TableCell>
                           <TableCell>
                              <img
                                 className="rounded-lg h-30 w-16"
                                 src={"./img/" + product.image}
                                 alt=""
                              />
                           </TableCell>
                           <TableCell>{product.name}</TableCell>
                           <TableCell align="center">
                              {formatMoney(product.price)}
                           </TableCell>
                           <TableCell>
                              <IconButton
                                 color="primary"
                                 aria-label="add to shopping cart"
                                 onClick={() => handleAddToCart(product.id)}
                              >
                                 <AddShoppingCartIcon />
                              </IconButton>
                           </TableCell>
                        </TableRow>
                     ))}
                  </TableBody>
               </Table>
            </TableContainer>
         </div>
      </div>
   );
};

export default TopProducts;
