import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { useLeagues } from "../services/hooks/useLeagues";

import {
   GridComponent,
   ColumnsDirective,
   ColumnDirective,
   Inject,
   Toolbar,
   Edit,
   Page,
   Sort,
} from "@syncfusion/ej2-react-grids";
import { DataManager, UrlAdaptor } from "@syncfusion/ej2-data";

const LeaguePlayers = () => {
   const [league_id, setLeague_id] = React.useState(1);

   const { data: leagues, loading, error } = useLeagues();

   const handleLeagueChange = (event) => {
      setLeague_id(event.target.value);
   };

   const toolbarOptions = ["Add", "Edit", "Update", "Cancel"];
   const editSettings = {
      allowEditing: true,
      allowAdding: true,
      allowDeleting: false,
      newRowPosition: "Top",
   };
   const editparams = { params: { popupHeight: "300px" } };
   const validationRule = { required: true };
   const dataManager = new DataManager({
      adaptor: new UrlAdaptor(),
      updateUrl:
         process.env.REACT_APP_API_SERVER +
         `/api/league/player/update?league_id=${league_id}`,
      insertUrl:
         process.env.REACT_APP_API_SERVER +
         `/api/league/player/insert?league_id=${league_id}`,
      url:
         process.env.REACT_APP_API_SERVER +
         `/api/league/players?league_id=${league_id}`,
   });

   return (
      <div>
         <div className="flex m-4 pt-3">
            <FormControl sx={{ width: "40%" }}>
               <InputLabel id="league-select-label">League:</InputLabel>
               <Select
                  labelId="league-select-label"
                  id="league-select"
                  value={league_id}
                  label="Leagues"
                  onChange={handleLeagueChange}
               >
                  {leagues?.map((league) => (
                     <MenuItem key={league.id} value={league.id}>
                        {league.name}
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>
         </div>
         <div>
            <GridComponent
               dataSource={dataManager}
               editSettings={editSettings}
               toolbar={toolbarOptions}
               allowPaging={true}
               allowSorting={true}
            >
               <ColumnsDirective>
                  <ColumnDirective
                     field="id"
                     headerText="id"
                     width="100"
                     allowEditing={false}
                  />
                  <ColumnDirective
                     field="first_name"
                     headerText="First Name"
                     width="100"
                     validationRules={validationRule}
                  />
                  <ColumnDirective
                     field="last_name"
                     headerText="Last Name"
                     width="100"
                     validationRules={validationRule}
                  />
                  <ColumnDirective
                     field="mobile_phone"
                     headerText="Phone"
                     width="100"
                  />
                  <ColumnDirective
                     field="email"
                     headerText="Email"
                     width="100"
                  />
               </ColumnsDirective>
               <Inject services={[Page, Toolbar, Sort, Edit]} />
            </GridComponent>
         </div>
      </div>
   );
};

export default LeaguePlayers;
