import React, { createContext, useContext, useEffect, useReducer } from "react";

const INITIAL_STATE = {
   user: JSON.parse(localStorage.getItem("user")) || null,
   loading: false,
   error: null,
};

export const AuthContext = createContext(INITIAL_STATE);

const AuthReducer = (state, action) => {
   switch (action.type) {
      case "LOGIN_START":
         return {
            user: null,
            loading: true,
            error: null,
         };
      case "LOGIN_SUCCESS":
         return {
            user: action.payload,
            loading: false,
            error: null,
         };
      case "LOGIN_FAILURE":
         return {
            user: null,
            loading: false,
            error: action.payload,
         };
      case "LOGOUT":
         return {
            user: null,
            loading: false,
            error: null,
         };
      default:
         return state;
   }
};

const AuthProvider = ({ children }) => {
   const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);

   useEffect(() => {
      localStorage.setItem("user", JSON.stringify(state.user));
   }, [state.user]);

   const handleLogin = async (user) => {
      return dispatch({ type: "LOGIN_SUCCESS", payload: user });
   };

   const handleLogout = () => {
      dispatch({ type: "LOGOUT", payload: "" });
   };

   return (
      <AuthContext.Provider
         value={{
            error: state.error,
            login: handleLogin,
            logout: handleLogout,
            userInfo: state.user,
         }}
      >
         {children}
      </AuthContext.Provider>
   );
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
