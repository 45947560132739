import React, { useState } from "react";
import {
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   FormControl,
   FormControlLabel,
   FormLabel,
   Grid,
   Radio,
   RadioGroup,
   Button,
   InputLabel,
   OutlinedInput,
   InputAdornment,
   TextField,
} from "@mui/material";

import { formatMoney } from "../services/utils";
import { LoadingButton } from "@mui/lab";

const LeagueCashPoolPaymentDialog = ({
   open,
   onClose,
   onPayment,
   leagueSchedule,
}) => {
   return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
         <DialogTitle>League Cash Pool Payment</DialogTitle>
         <DialogContent>
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
               <Grid item xs={3}>
                  League:
               </Grid>
               <Grid item xs={9}>
                  {leagueSchedule.name}
               </Grid>
               <Grid item xs={3}>
                  Date:
               </Grid>
               <Grid item xs={9}>
                  {leagueSchedule.league_date}
               </Grid>
               <Grid item xs={3}>
                  Cash Pool Total:
               </Grid>
               <Grid item xs={9}>
                  {formatMoney(leagueSchedule.cash_pool_balance)}
               </Grid>
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button color="primary" onClick={onClose}>
               Cancel
            </Button>
            <LoadingButton
               color="info"
               disabled={
                  leagueSchedule.cash_pool_balance === 0 ||
                  leagueSchedule.is_paid === 1
               }
               variant="contained"
               onClick={onPayment}
            >
               Make Payment
            </LoadingButton>
         </DialogActions>
      </Dialog>
   );
};

export default LeagueCashPoolPaymentDialog;
