import authHeader from "../authHeader";
import axios from "axios";
import { useMutation } from "react-query";

const addLeagueCashPoolPayment = async (payment) => {

   const { data } = await axios.post(
      process.env.REACT_APP_API_SERVER +
         "/api/league/schedule/cash_pool/payment",
      payment
   );
   return data;
};
export function useAddLeagueCashPoolPayment() {
   const { isLoading, mutateAsync } = useMutation(addLeagueCashPoolPayment);
   return { isAddingPayment: isLoading, addLeagueCashPoolPayment: mutateAsync };
}
