import { Typography, Box } from "@mui/material";

import React from "react";

import { green, red } from "@mui/material/colors";

import { formatMoney } from "../services/utils";
import LockIcon from "@mui/icons-material/Lock";
import moment from "moment";

const PlayerInfo = (
   first_name,
   last_name,
   payment_status,
   player_status,
   total,
   reserved_by,
   reserved_ts
) => {
   if (player_status === 0) {
      if (
         reserved_by != null &&
         reserved_ts != null &&
         moment(reserved_ts).add(15, "minutes") >= moment()
      ) {
         return <LockIcon style={{ color: red[500] }} fontSize="small" />;
      } else {
         return "available";
      }
   }
   return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
         <div>
            <Typography variant="body2">
               {first_name === null ? "" : first_name}{" "}
               {last_name === null ? "" : last_name} ({formatMoney(total)})
            </Typography>
         </div>
         {payment_status === 0 && (
            <div>
               <Typography variant="body6" color="error">
                  Unpaid
               </Typography>
            </div>
         )}
         {payment_status === 1 && (
            <div>
               <Typography variant="body6" sx={{ color: green[500] }}>
                  Paid
               </Typography>
            </div>
         )}
      </Box>
   );
};

export default PlayerInfo;
