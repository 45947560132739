import axios from "axios";
import { useQuery } from "react-query";

const fetchCoupon = async (coupon) => {
   const {data} = await axios.get(
      process.env.REACT_APP_API_SERVER + `/api/order/coupon?coupon=${coupon}`
   );

   return data;
};

export function useCoupon(coupon) {
   return useQuery(["coupon",coupon], () => fetchCoupon(coupon));
}
