export {};
const baseUrl = process.env.REACT_APP_API_SERVER;

export function processTransaction(data) {
   return fetch(baseUrl + `/api/transaction/chargeanywhere`, {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
   }).then((res) => res.json())
   .then((data) => {
      return data;
   });
}

export function queryTransaction(uniqueId) {
   return fetch(
      baseUrl + `/api/transaction/chargeanywhere?uniqueId=${uniqueId}`
   )
      .then((res) => res.json())
      .then((data) => {
         return data;
      });
}

export function getEventOrderItems(event_id) {
   return fetch(baseUrl + `/api/event/orderItems?event_id=${event_id}`)
      .then((res) => res.json())
      .then((data) => {
         return {
            count: data.length,
            result: data,
         };
      });
}

export function deleteEventOrderItem(id) {
   return fetch(baseUrl + `/api/event/syncfusion/orderItems/${id}`, {
      method: 'DELETE',
   })
      .then((res) => res.json())
      .then((data) => {
         return data;
      });
}

export function updateEventOrderItem(event_id, data) {
   return fetch(
      baseUrl + `/api/event/syncfusion/orderItems?event_id=${event_id}`,
      {
         method: 'PUT',
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify(data),
      }
   )
      .then((res) => res.json())
      .then((data) => {
         return data;
      });
}

export function addEventOrderItem(event_id, data) {
   return fetch(
      baseUrl + `/api/event/syncfusion/orderItems?event_id=${event_id}`,
      {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify(data),
      }
   )
      .then((res) => res.json())
      .then((data) => {
         return data;
      });
}
