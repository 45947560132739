import axios from 'axios';
import { useQuery } from 'react-query';
import moment from 'moment';

const fetchCurrentTeeSheetTableData = async (date) => {
   const { data } = await axios.get(
      process.env.REACT_APP_API_SERVER +
         `/api/teetimes/current_teesheet_table_data/${date}`
   );
   return data;
};

export function useCurrentTeeSheetTableData(date) {
   return useQuery(
      ['current-tee-sheet', moment(date).format('YYYY-MM-DD')],
      () => fetchCurrentTeeSheetTableData(moment(date).format('YYYY-MM-DD')),
      {
         // Refetch the data every second
         refetchInterval: 1000,
      }
   );
}
