import React from 'react';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import {
   AccumulationChartComponent,
   AccumulationSeriesCollectionDirective,
   AccumulationSeriesDirective,
   Inject,
   AccumulationLegend,
   PieSeries,
   AccumulationTooltip,
   IAccLoadedEventArgs,
   AccumulationTheme,
   AccumulationDataLabel,
} from '@syncfusion/ej2-react-charts';
import moment from 'moment';
import { useStateContext } from '../contexts/ContextProvider';
import { formatMoney } from '../services/utils';
import { useCustomDailySalesSummary } from '../services/hooks/useCustomDailySalesSummary';
import { useCustomDailySalesCategorySummary } from '../services/hooks/useCustomDailySalesCategorySummary';
import { useCustomDailySalesCategoryDiscount} from '../services/hooks/useCustomDailySalesCategoryDiscount';
import { useState } from 'react';

const getCreditCardType = (cardType) => {
   switch (cardType) {
      case '01':
         return 'Visa';
      case '02':
         return 'Master';
      case '03':
         return 'American Express';
      case '04':
         return 'Discover';
      default:
         return 'Cash';
   }
};

const getPaymentData = (data) => {
   let total = data?.reduce((acc, curr) => acc + curr.total, 0);
   return data?.map((item) => {
      item.text = Math.round((item.total / total) * 100) + '%';
      item.y = Math.round((item.total / total) * 100);
      item.x = getCreditCardType(item.cardType);
   });
};

const getCategoryData = (data) => {
   let total = data?.reduce((acc, curr) => acc + curr.total, 0);
   return data?.map((item) => {
      item.text = Math.round((item.total / total) * 100) + '%';
      item.y = Math.round((item.total / total) * 100);
      item.x = item.category_name;
   });
};

const CustomReport = () => {
   const { currentMode } = useStateContext();
   const [fromDateValue, setFromDateValue] = useState(
      moment().format('YYYY-MM-DD')
   );
   const [ toDateValue, setToDateValue ] = useState(
      moment().format('YYYY-MM-DD')
   );
   const { data: categorySales } = useCustomDailySalesCategorySummary(fromDateValue,toDateValue);
   const { data: dailySales } = useCustomDailySalesSummary(fromDateValue,toDateValue);
   const { data: categoryDiscount}= useCustomDailySalesCategoryDiscount(fromDateValue,toDateValue);
   
   const handleFromDateChange = (e) => {
      setFromDateValue(moment(e.value).format('YYYY-MM-DD'));
   };
   const handleToDateChange = (e) => {
      setToDateValue(moment(e.value).format('YYYY-MM-DD'));
   };
   let salesTotal = dailySales?.reduce((acc, curr) => {
      return acc + curr.total;
   }, 0);

   getPaymentData(dailySales);
   getCategoryData(categorySales);


   return (
      <div>
         <div className='m-2 p-3 w-70 flex flex-row items-center justify-start'>
            <p className='text-sm font-semibold m-5'>From Date:</p>
            <div className='w-70'>
               <DatePickerComponent
                  id='fromDate'
                  value={fromDateValue}
                  onChange={handleFromDateChange}
               ></DatePickerComponent>
            </div>
            <p className='text-sm font-semibold m-5'>To Date:</p>
            <div className='w-70'>
               <DatePickerComponent
                  id='toDate'
                  value={toDateValue}
                  onChange={handleToDateChange}
               ></DatePickerComponent>
            </div>
         </div>
   
         <div className='flex flex-row items-center justify-center'>
            <div className='text-lg mt-2 flex flex-col'>
               <AccumulationChartComponent
                  id={'sales-chart'}
                  legendSettings={{
                     visible: true,
                     background: 'white',
                  }}
                  height='400'
                  background={currentMode === 'Dark' ? '#33373E' : '#fff'}
                  tooltip={{ enable: true }}
               >
                  <Inject
                     services={[
                        AccumulationLegend,
                        PieSeries,
                        AccumulationDataLabel,
                        AccumulationTooltip,
                     ]}
                  />
                  <AccumulationSeriesCollectionDirective>
                     <AccumulationSeriesDirective
                        name='Sale'
                        dataSource={dailySales ? dailySales : null}
                        xName='x'
                        yName='y'
                        innerRadius='40%'
                        startAngle={0}
                        endAngle={360}
                        radius='70%'
                        explode
                        explodeOffset='10%'
                        explodeIndex={2}
                        dataLabel={{
                           visible: true,
                           name: 'text',
                           position: 'Inside',
                           font: {
                              fontWeight: '600',
                              color: '#fff',
                           },
                        }}
                     />
                  </AccumulationSeriesCollectionDirective>
               </AccumulationChartComponent>
               <div className='flex flex-col'>
                  <div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
                     <div className='py-2 inline-block min-w-full sm:px-6 lg:px-8'>
                        <div className='overflow-hidden'>
                           <table className='min-w-full border text-center'>
                              <thead className='border-b'>
                                 <tr>
                                    <th
                                       scope='col'
                                       className='text-sm font-medium text-gray-900 px-6 py-4 border-r'
                                    >
                                       Type
                                    </th>
                                    <th
                                       scope='col'
                                       className='text-sm font-medium text-gray-900 px-6 py-4 border-r'
                                    >
                                       Amount
                                    </th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {dailySales?.map((creditCard, index) => (
                                    <tr
                                       className='bg-white border-b'
                                       key={index}
                                    >
                                       <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r'>
                                          {getCreditCardType(
                                             creditCard.cardType
                                          )}
                                       </td>
                                       <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
                                          {formatMoney(creditCard.total)}
                                       </td>
                                    </tr>
                                 ))}

                                 <tr className='bg-white border-b'>
                                    <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r'>
                                       Total
                                    </td>
                                    <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r'>
                                       {formatMoney(salesTotal)}
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className='text-lg mt-2 ml-20 flex flex-col'>
               <AccumulationChartComponent
                  id={'category-chart'}
                  legendSettings={{
                     visible: true,
                     background: 'white',
                  }}
                  height='500'
                  background={currentMode === 'Dark' ? '#33373E' : '#fff'}
                  tooltip={{ enable: true }}
               >
                  <Inject
                     services={[
                        AccumulationLegend,
                        PieSeries,
                        AccumulationDataLabel,
                        AccumulationTooltip,
                     ]}
                  />
                  <AccumulationSeriesCollectionDirective>
                     <AccumulationSeriesDirective
                        name='Sale'
                        dataSource={categorySales ? categorySales : null}
                        xName='x'
                        yName='y'
                        innerRadius='40%'
                        startAngle={0}
                        endAngle={360}
                        radius='70%'
                        explode
                        explodeOffset='10%'
                        explodeIndex={2}
                        dataLabel={{
                           visible: true,
                           name: 'text',
                           position: 'Inside',
                           font: {
                              fontWeight: '600',
                              color: '#fff',
                           },
                        }}
                     />
                  </AccumulationSeriesCollectionDirective>
               </AccumulationChartComponent>
               <div className='flex flex-col'>
                  <div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
                     <div className='py-2 inline-block min-w-full sm:px-6 lg:px-8'>
                        <div className='overflow-hidden'>
                           <table className='min-w-full border text-center'>
                              <thead className='border-b'>
                                 <tr>
                                    <th
                                       scope='col'
                                       className='text-sm font-medium text-gray-900 px-6 py-4 border-r'
                                    >
                                       Type
                                    </th>
                                    <th
                                       scope='col'
                                       className='text-sm font-medium text-gray-900 px-6 py-4 border-r'
                                    >
                                       Amount
                                    </th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {categorySales?.map((item, index) => (
                                    <tr className='border-b' key={index}>
                                       <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r'>
                                          {item.category_name}
                                       </td>
                                       <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
                                          {formatMoney(item.total)}
                                       </td>
                                    </tr>
                                 ))}

                                 <tr className='bg-white border-b'>
                                    <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r'>
                                       Total
                                    </td>
                                    <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r'>
                                       {formatMoney(
                                          categorySales?.reduce((acc, curr) => {
                                             return acc + curr.total;
                                          }, 0)
                                       )}
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default CustomReport;
