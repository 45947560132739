import axios from "axios";
import { useQuery } from "react-query";

const fetchPricingRules = async () => {
   const { data } = await axios.get(
      process.env.REACT_APP_API_SERVER + "/api/pricing-rule"
   );
   return data;
};

export function usePricingRules() {
   return useQuery("pricing_rules", () => fetchPricingRules());
}
