import React from "react";
import { useAuth } from "../contexts/AuthProvider";
import { useParams } from "react-router";
import { useTransactionsDaily } from "../services/hooks/useTransactionsDaily";
import { formatMoney } from "../services/utils";
import moment from "moment";

const SalesReportPrint = () => {
   const { date } = useParams();
   const { userInfo } = useAuth();
   const { data: cashTransactions } = useTransactionsDaily(
      moment(date, "YYYY-MM-DD").format("MM/DD/yyyy"),
      5
   );
   const { data: creditTransactions } = useTransactionsDaily(
      moment(date, "YYYY-MM-DD").format("MM/DD/yyyy"),
      2
   );
   return (
      <div>
         <div className="w-full">
            <div className="flex flex-row justify-between mb-5">
               <span className="text-xl">Sales Report - {date}</span>
               <span className="text-xl"></span>
            </div>

            <div>
               <span className="text-xl">
                  Cash Total:{" "}
                  {formatMoney(
                     cashTransactions?.reduce(
                        (acc, curr) => acc + curr.authorizedAmount,
                        0
                     )
                  )}
               </span>
            </div>
            <div className="flex flex-col">
               <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                     <div className="overflow-hidden">
                        <table className="min-w-full">
                           <thead className="border-b">
                              <tr>
                                 <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                 >
                                    id
                                 </th>
                                 <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                 >
                                    Amount
                                 </th>
                                 <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                 >
                                    Discount
                                 </th>
                                 <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                 >
                                    Type
                                 </th>
                                 <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                 >
                                    Date
                                 </th>
                                 <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                 >
                                    By
                                 </th>
                              </tr>
                           </thead>
                           <tbody>
                              {cashTransactions?.map((transaction) => (
                                 <tr
                                    key={transaction.orderId}
                                    className="border-b"
                                 >
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                       {transaction.orderId}
                                    </td>
                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                       {formatMoney(
                                          transaction.authorizedAmount
                                       )}
                                    </td>
                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                       {formatMoney(transaction.discount)}
                                    </td>
                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                       Cash
                                    </td>
                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                       {transaction.transaction_time}
                                    </td>
                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                       {transaction.name}
                                    </td>
                                 </tr>
                              ))}
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>

            <div className="mt-5 mb-2">
               <span className="text-xl">
                  Credit Card Total:{" "}
                  {formatMoney(
                     creditTransactions?.reduce(
                        (acc, curr) => acc + curr.Amount,
                        0
                     )
                  )}
               </span>
            </div>
            <div className="flex flex-col">
               <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                     <div className="overflow-hidden">
                        <table className="min-w-full">
                           <thead className="border-b">
                              <tr>
                                 <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                 >
                                    id
                                 </th>
                                 <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                 >
                                    Amount
                                 </th>
                                 <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                 >
                                    Discount
                                 </th>
                                 <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                 >
                                    Type
                                 </th>
                                 <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                 >
                                    Date
                                 </th>
                                 <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                 >
                                    By
                                 </th>
                              </tr>
                           </thead>
                           <tbody>
                              {creditTransactions?.map((transaction) => (
                                 <tr
                                    key={transaction.orderId}
                                    className="border-b"
                                 >
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                       {transaction.orderId}
                                    </td>
                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                       {formatMoney(transaction.Amount)}
                                    </td>
                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                       {formatMoney(transaction.discount)}
                                    </td>
                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                       Credit
                                    </td>
                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                       {transaction.transaction_time}
                                    </td>
                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                       {transaction.name}
                                    </td>
                                 </tr>
                              ))}
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default SalesReportPrint;
