import {
   Paper,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Typography,
} from '@mui/material';
import React from 'react';
import PlayerInfo from './PlayerInfo';

const TeeSheetTable = ({ tableData, handleTableCellClick }) => {
   console.log(tableData,'😀')
   return (
      <TableContainer component={Paper}>
         <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
               <TableRow>
                  <TableCell>Teetime</TableCell>
                  <TableCell align='left'>Player 1</TableCell>
                  <TableCell align='left'>Player 2</TableCell>
                  <TableCell align='left'>Player 3</TableCell>
                  <TableCell align='left'>Player 4</TableCell>
               </TableRow>
            </TableHead>
            <TableBody>
               {tableData?.map((row, index) => (
                  <TableRow
                     key={index}
                     sx={{
                        backgroundColor:
                           row.teesheet_status === 0 ? 'white' : 'gray',
                     }}
                  >
                     <TableCell>{row.teetime}</TableCell>
                     <TableCell
                        onClick={() => handleTableCellClick(row.teesheet_id, 1)}
                     >
                        {PlayerInfo(
                           row.player1_first_name,
                           row.player1_last_name,
                           row.player1_payment_status,
                           row.player1_status,
                           row.player1_total,
                           row.player1_reserved_by,
                           row.player1_reserved_ts
                        )}
                     </TableCell>
                     <TableCell
                        onClick={() => handleTableCellClick(row.teesheet_id, 2)}
                     >
                        {PlayerInfo(
                           row.player2_first_name,
                           row.player2_last_name,
                           row.player2_payment_status,
                           row.player2_status,
                           row.player2_total,
                           row.player2_reserved_by,
                           row.player2_reserved_ts
                        )}
                     </TableCell>
                     <TableCell
                        onClick={() => handleTableCellClick(row.teesheet_id, 3)}
                     >
                        {PlayerInfo(
                           row.player3_first_name,
                           row.player3_last_name,
                           row.player3_payment_status,
                           row.player3_status,
                           row.player3_total,
                           row.player3_reserved_by,
                           row.player3_reserved_ts
                        )}
                     </TableCell>
                     <TableCell
                        onClick={() => handleTableCellClick(row.teesheet_id, 4)}
                     >
                        {PlayerInfo(
                           row.player4_first_name,
                           row.player4_last_name,
                           row.player4_payment_status,
                           row.player4_status,
                           row.player4_total,
                           row.player4_reserved_by,
                           row.player4_reserved_ts
                        )}
                     </TableCell>
                  </TableRow>
               ))}
            </TableBody>
         </Table>
      </TableContainer>
   );
};

export default TeeSheetTable;
