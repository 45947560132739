import axios from "axios";
import { useMutation } from "react-query";

const deleteReservation = async (value) => {
   const { data } = await axios.delete(
      process.env.REACT_APP_API_SERVER + `/api/teetimes/deleteReservation`,
      { data: value }
   );
   return data;
};

export function useDeleteReservation() {
   const { isLoading, mutateAsync } = useMutation(deleteReservation);
   return { isDeleting: isLoading, deleteReservation: mutateAsync };
}
