import { AiOutlineHome, AiOutlineCalendar } from 'react-icons/ai';
import { ImPriceTags } from 'react-icons/im';
import { BsPeople } from 'react-icons/bs';
import { FiUsers } from 'react-icons/fi';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { AiOutlineSetting } from 'react-icons/ai';
import { GiLoincloth } from 'react-icons/gi';
import { BsFillCalendarEventFill } from 'react-icons/bs';

export const themeColors = [
   {
      name: 'blue-theme',
      color: '#1A97F5',
   },
   {
      name: 'green-theme',
      color: '#03C9D7',
   },
   {
      name: 'purple-theme',
      color: '#7352FF',
   },
   {
      name: 'red-theme',
      color: '#FF5C8E',
   },
   {
      name: 'indigo-theme',
      color: '#1E4DB7',
   },
   {
      color: '#FB9678',
      name: 'orange-theme',
   },
];

export const ROLES = [
   {
      id: 1,
      name: 'Admin',
   },
   {
      id: 2,
      name: 'Manager',
   },
   {
      id: 3,
      name: 'User',
   },
];
export const links = [
   {
      title: 'Dashboard',
      links: [
         {
            name: 'home',
            icon: <AiOutlineHome />,
         },

         {
            name: 'booking',
            icon: <AiOutlineCalendar />,
         },
         {
            name: 'top-products',
            icon: <GiLoincloth />,
         },
         {
            name: 'products',
            icon: <GiLoincloth />,
         },
         {
            name: 'members',
            icon: <BsPeople />,
         },
         {
            name: 'pricing-sheet',
            icon: <ImPriceTags />,
         },
         {
            name: 'teetimes',
            icon: <AiOutlineCalendar />,
         },
         {
            name: 'leagues',
            icon: <BsPeople />,
         },
         {
            name: 'events',
            icon: <BsFillCalendarEventFill />,
         },
         {
            name: 'customers',
            icon: <BsPeople />,
         },
         {
            name: 'users',
            icon: <FiUsers />,
         },
         {
            name: 'reports',
            icon: <HiOutlineDocumentReport />,
         },
      ],
   },
];
