import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import SettingsButton from "../components/SettingsButton";
import { useStateContext } from "../contexts/ContextProvider";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import ThemeSettings from "../components/ThemeSettings";

const Layout = () => {
   const {
      themeSettings,
      currentMode,
      activeMenu,
      setCurrentColor,
      setCurrentMode,
      setCurrentPos,
   } = useStateContext();

   useEffect(() => {
      const currentThemeColor = localStorage.getItem("colorMode");
      const currentThemeMode = localStorage.getItem("themeMode");
      const currentPos = localStorage.getItem("pos");

      if (currentThemeColor && currentThemeMode) {
         setCurrentColor(currentThemeColor);
         setCurrentMode(currentThemeMode);
      }
      if (currentPos) {
         setCurrentPos(currentPos);
      }
   }, []);

   return (
      <div className={currentMode === "Dark" ? "dark" : ""}>
         <div className="flex relative dark:bg-main-dark-bg">
            <SettingsButton />
            {activeMenu ? (
               <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
                  <Sidebar />
               </div>
            ) : (
               <div className="w-0 dark:bg-secondary-dark-bg">
                  <Sidebar />
               </div>
            )}

            <div
               className={`dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full 
                     ${activeMenu ? "md:ml-72" : "flex-2"}`}
            >
               <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
                  <Navbar />
               </div>
               <div>
                  {themeSettings && <ThemeSettings />}
                  <Outlet />
               </div>
            </div>
         </div>
      </div>
   );
};

export default Layout;
