import axios from "axios";
import { useMutation } from "react-query";

const updateTransactionResult = async (values) => {
   const { data } = await axios.post(
      process.env.REACT_APP_API_SERVER + `/api/transaction/chargeanywhere/update`,
      values
   );
   return data;
};

export function useUpdateTransactionResult() {
   const { isLoading, mutateAsync } = useMutation(updateTransactionResult);

   return { isUpdating: isLoading, updateTransactionResult: mutateAsync };
}
