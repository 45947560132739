import axios from "axios";
import { useQuery } from "react-query";

const fetchDailySalesSummary = async (date) => {
   const { data } = await axios.get(
      process.env.REACT_APP_API_SERVER +
         `/api/transaction/daily_sales_summary?date=${date}`
   );

   return data;
};

export function useDailySalesSummary(date) {
   return useQuery(["daily_sales_summary", date], () =>
      fetchDailySalesSummary(date)
   );
}
