import React from 'react';
import moment from 'moment';
import { formatMoney } from '../services/utils';

const TeetimeTransactions = ({ transactions }) => {
   return (
      <div className='flex flex-col'>
         <div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='py-2 inline-block min-w-full sm:px-6 lg:px-8'>
               <div className='overflow-hidden'>
                  <table className='min-w-full'>
                     <thead className='border-b'>
                        <tr>
                           <th
                              scope='col'
                              className='text-sm font-medium text-gray-900 px-6 py-4 text-left'
                           >
                              #
                           </th>
                           <th
                              scope='col'
                              className='text-sm font-medium text-gray-900 px-6 py-4 text-left'
                           >
                              Amount
                           </th>
                           <th
                              scope='col'
                              className='text-sm font-medium text-gray-900 px-6 py-4 text-left'
                           >
                              Type
                           </th>
                           <th
                              scope='col'
                              className='text-sm font-medium text-gray-900 px-6 py-4 text-left'
                           >
                              Status
                           </th>
                           <th
                              scope='col'
                              className='text-sm font-medium text-gray-900 px-6 py-4 text-left'
                           >
                              By
                           </th>
                           <th
                              scope='col'
                              className='text-sm font-medium text-gray-900 px-6 py-4 text-left'
                           >
                              Time
                           </th>
                        </tr>
                     </thead>
                     <tbody>
                        {transactions?.map((transaction, index) => (
                           <tr
                              className='border-b'
                              key={
                                 transaction.transactionId
                                    ? transaction.transactionId
                                    : '' + transaction.uniqueId
                                    ? transaction.uniqueId
                                    : ''
                              }
                           >
                              <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                                 1
                              </td>
                              <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
                                 {formatMoney(transaction.authorizedAmount)}
                              </td>
                              <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
                                 {transaction.transactionTender === 5
                                    ? 'Cash'
                                    : 'Credit'}
                              </td>
                              <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
                                 {transaction.transactionResult}
                              </td>
                              <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
                                 {transaction.first_name}{' '}
                                 {transaction.last_name}
                              </td>
                              <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
                                 {moment(transaction.createdTime).format(
                                    'MM-DD-YYYY h:mm a'
                                 )}
                              </td>
                           </tr>
                        ))}
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
      </div>
   );
};

export default TeetimeTransactions;
