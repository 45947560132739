import React from "react";
import { MdOutlineCancel } from "react-icons/md";

import { Button } from ".";
import { useStateContext } from "../contexts/ContextProvider";
import avatar from "../data/user.png";
import { useAuth } from "../contexts/AuthProvider";
import { ROLES } from "../data/settings";
import { useCartContext } from "../contexts/CartProvider";

const UserProfile = () => {
   const { userInfo, logout } = useAuth();
   const { currentColor, setIsClicked, initialState } = useStateContext();
   const { resetCart } = useCartContext();

   /* get name from id */
   const getRoleName = (id) => {
      const role = ROLES.find((role) => role.id === id);
      return role.name;
   };

   const handleLogout = () => {
      logout();
      //clear shopping cart
      resetCart();
      //set intial state
      setIsClicked(initialState);
   };

   return (
      <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
         <div className="flex justify-between items-center">
            <p className="font-semibold text-lg dark:text-gray-200">
               User Profile
            </p>
            <Button
               icon={<MdOutlineCancel />}
               color="rgb(153, 171, 180)"
               bgHoverColor="light-gray"
               size="2xl"
               borderRadius="50%"
            />
         </div>
         <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
            <img
               className="rounded-full h-24 w-24"
               src={avatar}
               alt="user-profile"
            />
            <div>
               <p className="font-semibold text-xl dark:text-gray-200">
                  {" "}
                  {userInfo.first_name} {userInfo.last_name}
               </p>
               <p className="text-gray-500 text-sm dark:text-gray-400">
                  {" "}
                  {getRoleName(userInfo.role_id)}{" "}
               </p>
               <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">
                  {" "}
                  {userInfo.email}{" "}
               </p>
            </div>
         </div>

         <div className="mt-5">
            <button
               style={{
                  color: "white",
                  backgroundColor: currentColor,
                  borderRadius: "10px",
               }}
               className="mt-5 p-3 hover:drop-shadow-xl w-full"
               onClick={handleLogout}
            >
               Logout
            </button>
         </div>
      </div>
   );
};

export default UserProfile;
