import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Otp from '../components/Otp';
import {
   IconButton,
   Paper,
   TextField,
   Button,
   useAutocomplete,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useAuth } from '../contexts/AuthProvider';

function isNumeric(n) {
   return !isNaN(parseInt(n)) && isFinite(n);
}

const Login = () => {
   let navigate = useNavigate();
   const { userInfo, login } = useAuth();

   const [code, setCode] = useState('+1');
   const [pno, setPno] = useState('');
   const [otpShow, setOtpShow] = useState(false);
   const [otp, setOtp] = useState('');
   const getCode = async () => {
      const e = code + pno;
      await axios
         .get(process.env.REACT_APP_API_SERVER + '/api/verify/get-code', {
            params: {
               phone_number: e,
               channel: 'sms',
            },
         })
         .then((res) => res.data)
         .then((data) => console.log(data))
         .catch((err) => console.log(err));
   };
   const verifyCode = async () => {
      const e = code + pno;
      await axios
         .get(process.env.REACT_APP_API_SERVER + '/api/verify/verify-code', {
            params: {
               phone_number: e,
               code: otp,
            },
         })
         .then((res) => res.data)
         .then((data) => {
            localStorage.setItem('accessToken', data.accessToken);
            login(data.user);
            navigate('/');
         })
         .catch((err) => console.log(err));
   };

   useEffect(() => {
      console.log(process.env.REACT_APP_API_SERVER);
      if (userInfo) {
         navigate('/');
      }
   }, []);

   return (
      <div
         style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(160, 160, 160, 0.2)',
            height: '100vh',
         }}
      >
         <Paper
            elevation={4}
            style={{ padding: 20, width: 300, marginBottom: 60 }}
         >
            {!otpShow ? (
               <></>
            ) : (
               <IconButton
                  onClick={() => {
                     setOtpShow(false);
                     setOtp('');
                  }}
                  size='small'
               >
                  <ArrowBackIcon />
               </IconButton>
            )}
            {!otpShow ? (
               <h3>Enter your Phone Number</h3>
            ) : (
               <h3>Enter the OTP</h3>
            )}
            {otpShow ? (
               <p>
                  A One Time Password has been sent to your phone number for
                  verification purposes.
               </p>
            ) : null}
            <div>
               {!otpShow ? (
                  <div
                     style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 'auto',
                        justifyContent: 'space-around',
                     }}
                  >
                     <div
                        style={{
                           alignItems: 'flex-end',
                           justifyContent: 'center',
                           display: 'flex',
                           marginRight: 10,
                           width: 60,
                        }}
                     >
                        <TextField
                           id='code'
                           label='Code'
                           color='secondary'
                           value={code}
                           onChange={(e) => {
                              setCode(e.target.value);
                           }}
                        />
                     </div>
                     <div>
                        <TextField
                           id='phone'
                           label='Phone'
                           color='secondary'
                           value={pno}
                           onChange={(e) => {
                              if (
                                 (e.target.value[e.target.value.length - 1] >=
                                    '0' &&
                                    e.target.value[e.target.value.length - 1] <=
                                       '9') ||
                                 !e.target.value
                              ) {
                                 setPno(e.target.value);
                              }
                           }}
                        />
                     </div>
                  </div>
               ) : (
                  <Otp otp={otp} setOtp={(val) => setOtp(val)} />
               )}
               {otpShow ? (
                  <div
                     style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        marginTop: 5,
                     }}
                  >
                     Didn't receive an OTP?{' '}
                     <Button
                        onClick={() => getCode()}
                        color='primary'
                        style={{ textTransform: 'none', fontSize: 15 }}
                     >
                        Resend OTP
                     </Button>
                  </div>
               ) : null}
               <div
                  style={{
                     display: 'flex',
                     flexDirection: 'row',
                     marginTop: 20,
                  }}
               >
                  <Button
                     variant='contained'
                     disabled={
                        pno.length !== 10 ||
                        code === null ||
                        !isNumeric(pno) ||
                        (otpShow && otp.length !== 6)
                     }
                     color='secondary'
                     style={{
                        color: 'white',
                        marginLeft: 'auto',
                        textTransform: 'none',
                     }}
                     onClick={() => {
                        if (otpShow) {
                           verifyCode();
                           navigate('/');
                        } else {
                           getCode();
                           setOtpShow(true);
                        }
                     }}
                  >
                     Verify
                  </Button>
               </div>
            </div>
         </Paper>
      </div>
   );
};

export default Login;
