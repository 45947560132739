import axios from "axios";
import { useQuery } from "react-query";

const fetchTeetimeTransactions = async (teesheet_id, sequence) => {
   const { data } = await axios.get(
      process.env.REACT_APP_API_SERVER +
         `/api/transaction/teetime?teesheet_id=${teesheet_id}&sequence=${sequence}`
   );
   return data;
};

export function useTeetimeTransactions(teesheet_id, sequence) {
   return useQuery(["teetime-transactions", teesheet_id, sequence], () =>
      fetchTeetimeTransactions(teesheet_id, sequence)
   );
}
