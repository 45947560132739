import axios from "axios";
import { useMutation } from "react-query";

const updateTeetime = async (values) => {
   const { data } = await axios.post(
      process.env.REACT_APP_API_SERVER + `/api/teetimes/updateteetime`,
      values
   );
   return data;
};

export function useUpdateTeetime() {
   const { isLoading, mutateAsync } = useMutation(updateTeetime);

   return { isUpdating: isLoading, updateTeetime: mutateAsync };
}
