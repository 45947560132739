import React, { useEffect } from 'react';

import { queryTransaction } from '../services/dataService';

const Test = () => {
   useEffect(() => {
      queryTransaction('23232345433')
         .then((res) => {
            console.log(res);
         })
         .catch((err) => {
            console.log(err);
         });
   }, []);

   return <div>Test</div>;
};

export default Test;
