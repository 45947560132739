import axios from "axios";
import { useQuery } from "react-query";

const fetchProductsByCategory = async (category_id) => {
   const { data } = await axios.get(
      process.env.REACT_APP_API_SERVER +
         "/api/product/category?category_id=" +
         category_id
   );
   return data;
};

export function useProductsByCategory(category_id) {
   return useQuery(["products", category_id], () =>
      fetchProductsByCategory(category_id)
   );
}
