import authHeader from "../authHeader";
import axios from "axios";
import { useMutation } from "react-query";

const addEventPayment = async (payment) => {
   const { data } = await axios.post(
      process.env.REACT_APP_API_SERVER + "/api/event/payment",
      payment
   );
   return data;
};
export function useAddEventPayment() {
   const { isLoading, mutateAsync } = useMutation(addEventPayment);
   return { isAddingEventPayment: isLoading, addEventPayment: mutateAsync };
}
