import axios from "axios";
import { useQuery } from "react-query";

const fetchLeagueScheduleByDate = async (date) => {
   const { data } = await axios.get(
      process.env.REACT_APP_API_SERVER +
         `/api/league/schedule/date?date=${date}`
   );
   return data;
};

export function useLeagueScheduleByDate(date) {
   return useQuery(["league_schedules", date], () =>
      fetchLeagueScheduleByDate(date)
   );
}
