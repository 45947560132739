import axios from "axios";
import { useMutation } from "react-query";

const updateStatus = async (values) => {
   const { data } = await axios.post(
      process.env.REACT_APP_API_SERVER + `/api/teetimes/update-teetime-status`,
      values
   );
   return data;
};

export function useTeetimePlayerStatus() {
   const { isLoading, mutateAsync } = useMutation(updateStatus);
   return { isUpdating: isLoading, updateTeetimePlayerStatus: mutateAsync };
}
