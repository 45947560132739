import axios from "axios";
import { useQuery } from "react-query";

const fetchMembers = async () => {
   const { data } = await axios.get(
      process.env.REACT_APP_API_SERVER + `/api/member`
   );

   return data;
};

export function useMembers() {
   return useQuery("members", () => fetchMembers());
}
