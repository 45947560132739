export default function AuthHeader() {
   const accessToken = localStorage.getItem("accessToken");

   if (accessToken) {
      return { authorization: "Bearer " + accessToken }; // for Spring Boot back-end
      //   return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
   } else {
      return {};
   }
}
