import React from "react";

import {
   TabComponent,
   TabItemDirective,
   TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";

import {
   Header,
   CashSalesReport,
   CreditSalesReport,
   EmployeeReports,
   DailyReport,
   CustomReport,
} from "../components";
import { useAuth } from "../contexts/AuthProvider";

const headerText = [
   { text: "Cash Sales" },
   { text: "Credit Card Sales" },
   { text: "Employee Reports" },
   { text: "Daily Report" },
   { text: "Custom Report" },
];
function contentTemplate1() {
   return <CashSalesReport />;
}
function contentTemplate2() {
   return <CreditSalesReport />;
}
function contentTemplate3() {
   return <EmployeeReports />;
}
function contentTemplate4() {
   return <DailyReport />;
}

function contentTemplate5() {
   return <CustomReport />;
}

const Reports = () => {
   const { userInfo } = useAuth();

   return (
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl h-screen">
         <Header title="Reports" />
         <TabComponent heightAdjustMode="Auto">
            <TabItemsDirective>
               <TabItemDirective
                  header={headerText[0]}
                  content={contentTemplate1}
               />
               <TabItemDirective
                  header={headerText[1]}
                  content={contentTemplate2}
               />
               {userInfo.role_id === 3 && (
                  <TabItemDirective
                     header={headerText[2]}
                     content={contentTemplate3}
                  />
               )}
               <TabItemDirective
                  header={headerText[3]}
                  content={contentTemplate4}
               />
               <TabItemDirective
                  header={headerText[4]}
                  content={contentTemplate5}
               />
            </TabItemsDirective>
         </TabComponent>
      </div>
   );
};

export default Reports;
