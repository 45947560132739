import axios from "axios";
import { useQuery } from "react-query";

const fetchEventById = async (id) => {
   const { data } = await axios.get(
      process.env.REACT_APP_API_SERVER + `/api/event?id=${id}`
   );

   return data;
};

export function useEventById(id) {
   return useQuery(["event", id], () => fetchEventById(id));
}
