import React, { useRef } from "react";
import {
   GridComponent,
   ColumnsDirective,
   ColumnDirective,
   Inject,
   Toolbar,
   Edit,
   Page,
   Sort,
   rowSelected,
   actionComplete,
} from "@syncfusion/ej2-react-grids";
import { DataManager, UrlAdaptor } from "@syncfusion/ej2-data";

import EventOrderItems from "./EventOrderItems";

const Events = () => {
   const gridRef = useRef(null);
   const [selectedEventId, setSelectedEventId] = React.useState(null);
   const [selectedEventName, setSelectedEventName] = React.useState(null);

   const eventIdRef = useRef(0);
   const eventNameRef = useRef("");

   const toolbarOptions = ["Add", "Edit", "Update", "Cancel"];
   const editSettings = {
      allowEditing: true,
      allowAdding: true,
      allowDeleting: false,
      newRowPosition: "Top",
   };
   const selectionsettings = { type: "Multiple" };

   const dataManager = new DataManager({
      adaptor: new UrlAdaptor(),
      updateUrl: process.env.REACT_APP_API_SERVER + "/api/event/update",
      insertUrl: process.env.REACT_APP_API_SERVER + "/api/event/insert",
      url: process.env.REACT_APP_API_SERVER + `/api/event`,
   });

   function rowSelect(args) {
      if (args.name === "rowSelected") {
         eventIdRef.current = args.data.id;
         eventNameRef.current = args.data.event_name;
         setSelectedEventId(args.data.id);
         setSelectedEventName(args.data.event_name);
      }
   }

   return (
      <div>
         <div className="mt-5">
            <GridComponent
               dataSource={dataManager}
               editSettings={editSettings}
               toolbar={toolbarOptions}
               allowPaging={true}
               rowSelected={rowSelect}
               selectionSettings={selectionsettings}
               ref={gridRef}
            >
               <ColumnsDirective>
                  <ColumnDirective
                     field="id"
                     headerText="ID"
                     width="60"
                     allowEditing={false}
                     isPrimaryKey={true}
                  />
                  <ColumnDirective
                     field="event_date"
                     headerText="Date"
                     width="150"
                     allowEditing={true}
                  />
                  <ColumnDirective
                     field="event_name"
                     headerText="Name"
                     width="200"
                     allowEditing={true}
                  />
                  <ColumnDirective
                     field="event_type"
                     headerText="Type"
                     width="100"
                     allowEditing={true}
                  />
                  <ColumnDirective
                     field="status"
                     headerText="Status"
                     width="100"
                     allowEditing={true}
                  />
                  <ColumnDirective
                     field="notes"
                     headerText="Notes"
                     width="200"
                     allowEditing={true}
                  />
               </ColumnsDirective>
               <Inject services={[Page, Toolbar, Edit]} />
            </GridComponent>
         </div>

         <EventOrderItems selectedEventId={selectedEventId} />
      </div>
   );
};

export default Events;
