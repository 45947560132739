import React from "react";

import {
   TabComponent,
   TabItemDirective,
   TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";

import { Header } from "../components";

import { AddTeeTime, BlockTeeTime, UnblockTeeTime } from "../components";

const Teetimes = () => {
   const headerText = [
      { text: "Add Tee Time" },
      { text: "Block Tee Time" },
      { text: "Unblock Tee Time" },
   ];
   function contentTemplate1() {
      return <AddTeeTime />;
   }
   function contentTemplate2() {
      return <BlockTeeTime />;
   }
   function contentTemplate3() {
      return <UnblockTeeTime />;
   }

   return (
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl h-screen">
         <Header title="Tee times" />
         <TabComponent heightAdjustMode="Auto">
            <TabItemsDirective>
               <TabItemDirective
                  header={headerText[0]}
                  content={contentTemplate1}
               />
               <TabItemDirective
                  header={headerText[1]}
                  content={contentTemplate2}
               />
               <TabItemDirective
                  header={headerText[2]}
                  content={contentTemplate3}
               />
            </TabItemsDirective>
         </TabComponent>
      </div>
   );
};

export default Teetimes;
