import React, { useEffect, useRef, useState } from "react";
import {
   GridComponent,
   ColumnDirective,
   ColumnsDirective,
   Page,
   Inject,
   Edit,
   EditSettingsModel,
   Toolbar,
   ToolbarItems,
} from "@syncfusion/ej2-react-grids";
import { getOrders, addOrder, updateOrder, deleteOrder } from "./orderService";
import {
   getEventOrderItems,
   addEventOrderItem,
   updateEventOrderItem,
   deleteEventOrderItem,
} from "../services/dataService";

const EventOrderItems = ({ selectedEventId }) => {
   const editOptions = {
      allowEditing: true,
      allowAdding: true,
      allowDeleting: true,
   };
   const toolbarOptions = ["Add", "Edit", "Delete", "Update", "Cancel"];

   const gridRef = useRef(null);

   const [data, setData] = useState();
   useEffect(() => {
      refreshGrid();
   }, [selectedEventId]);

   function refreshGrid() {
      getEventOrderItems(selectedEventId).then((data) => {
         setData(data);
      });
   }
   function dataStateChange(args) {
      refreshGrid();
   }
   function dataSourceChanged(state) {
      //   debugger;
      if (state.action === "add") {
         addEventOrderItem(selectedEventId, state.data).then((res) =>
            state.endEdit()
         );
      } else if (state.action === "edit") {
         updateEventOrderItem(selectedEventId, state.data).then((res) =>
            state.endEdit()
         );
      } else if (state.requestType === "delete") {
         deleteEventOrderItem(state.data[0].id).then((res) => state.endEdit());
      }
   }
   return (
      <div className="mt-5">
         <GridComponent
            dataSource={data}
            ref={gridRef}
            toolbar={toolbarOptions}
            editSettings={editOptions}
            dataSourceChanged={dataSourceChanged}
            dataStateChange={dataStateChange}
         >
            <ColumnsDirective>
               <ColumnDirective
                  field="id"
                  headerText="ID"
                  width="60"
                  allowEditing={false}
                  isPrimaryKey={true}
               />
               <ColumnDirective
                  field="event_name"
                  headerText="Event"
                  width="200"
                  allowEditing={false}
               />
               <ColumnDirective field="name" headerText="Name" width="200" />
               <ColumnDirective
                  field="price"
                  headerText="Price"
                  width="100"
                  format={"C2"}
               />
               <ColumnDirective
                  field="quantity"
                  headerText="Quantity"
                  width="90"
               />
               <ColumnDirective field="tax" headerText="Tax" width="100" />
               <ColumnDirective
                  field="is_food"
                  headerText="Is food"
                  width="80"
               />
               <ColumnDirective field="notes" headerText="Notes" width="200" />
            </ColumnsDirective>
            <Inject services={[Toolbar, Edit]} />
         </GridComponent>
      </div>
   );
};

export default EventOrderItems;
