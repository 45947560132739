import authHeader from "../authHeader";
import axios from "axios";
import { useMutation } from "react-query";

const addLeagueSchedule = async (values) => {
   const { data } = await axios.post(
      process.env.REACT_APP_API_SERVER + "/api/league/schedule/insert",
      values,
      { headers: authHeader() }
   );
   return data;
};

export function useAddLeagueSchedule() {
   const { isLoading, mutateAsync } = useMutation(addLeagueSchedule);
   return { isAdding: isLoading, addLeagueSchedule: mutateAsync };
}
