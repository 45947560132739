import axios from "axios";
import { useMutation } from "react-query";

const bookTeetime = async (values) => {
   const { data } = await axios.post(
      process.env.REACT_APP_API_SERVER + `/api/teetimes/bookteetime`,
      values
   );
   return data;
};

export function useBookTeetime() {
   const { isLoading, mutateAsync } = useMutation(bookTeetime);

   return { isBooking: isLoading, bookTeetime: mutateAsync };
}
