import { Navigate } from "react-router-dom";
import Layout from "../containers/Layout";

const PrivateRoute = ({ isAllowed, redirectPath = "/login", children }) => {
   if (!isAllowed) {
      return <Navigate to={redirectPath} replace />;
   }

   return children ? children : <Layout />;
};

export default PrivateRoute;
