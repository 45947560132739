import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { CalendarComponent } from '@syncfusion/ej2-react-calendars';
import { Header, TeeSheetTable } from '../components';
import BookingDialog from '../components/BookingDialog';
import { useMembers } from '../services/hooks/useMembers';
import { useMemberTypes } from '../services/hooks/useMemberTypes';
import { useSnackbar } from '../contexts/SnackbarProvider';
import { useBookTeetime } from '../services/hooks/useBookTeetime';
import { useUpdateTeetime } from '../services/hooks/useUpdateTeetime';
import { useDeleteReservation } from '../services/hooks/useDeleteReservation';
import { useQueryClient } from 'react-query';
import { useCurrentTeeSheetTableData } from '../services/hooks/useCurrentTeeSheetTableData';
import { useCartContext } from '../contexts/CartProvider';
import { useBookSingleTeetime } from '../services/hooks/useBookSingleTeetime';

import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { Button } from '@mui/material';
import { useIsTeetimeLocked } from '../services/hooks/useIsTeetimeLocked';

const Home = () => {
   const snackbar = useSnackbar();
   const { addToCart } = useCartContext();
   const [selectedDate, setSelectedDate] = useState(
      moment().format('YYYY-MM-DD')
   );
   const { isLoading, data, error, dataUpdatedAt } =
      useCurrentTeeSheetTableData(selectedDate);
   const { isBookingSingle, bookSingleTeetime } = useBookSingleTeetime();

   const [teesheet_id, setTeesheet_id] = useState(1);
   const [open, setOpen] = useState(false);
   const [sequence, setSequence] = useState(1);

   const { isLoading: isLoadingMembers, data: members } = useMembers();
   const { data: memberTypes } = useMemberTypes();
   const { isBooking, bookTeetime } = useBookTeetime();
   const { isUpdating, updateTeetime } = useUpdateTeetime();
   const { isDeleting, deleteReservation } = useDeleteReservation();
   const { isChecking, isTeetimeLocked } = useIsTeetimeLocked();
   // Create a client
   const queryClient = useQueryClient();

   if (isLoading) {
      return <div>Loading...</div>;
   }

   const handleBookAndPay = async ({ data, teeSheetInfo }) => {
      bookSingleTeetime(data)
         .then(() => {
            if (
               Number(data[0].green_fee) +
                  Number(data[0].cart_fee) +
                  data[0].tax >
               0
            ) {
               addToCart({
                  id: teeSheetInfo.teesheet_players_id,
                  price: Number(data[0].green_fee) + Number(data[0].cart_fee),
                  tax: data[0].tax,
                  image: 'golf-item.png',
                  is_teetime: true,
                  quantity: 1,
                  first_name: data[0].first_name,
                  last_name: data[0].last_name,
                  mobile_phone: data[0].mobile_phone,
                  email: data[0].email,
                  teetime: teeSheetInfo.teetime,
                  roundType: data[0].roundType ? '9 holes' : '18 holes',
                  transportation: data[0].transportation ? 'Walking' : 'Riding',
               });
            }
            snackbar.success('Tee time has been added successfully');
            handleClose();
         })
         .catch((err) =>
            snackbar.error(
               'Tee time is not available. Please select another time'
            )
         );
   };
   // const handleAddTeetime = async (values) => {
   //    bookTeetime(values)
   //       .then(() => {
   //          snackbar.success("Tee time has been added successfully");
   //          handleClose();
   //       })
   //       .catch((err) =>
   //          snackbar.error(
   //             "Something went wrong! If the problem persists, contact us!"
   //          )
   //       );
   // };
   const handleUpdateTeetime = async (values) => {
      updateTeetime(values)
         .then(() => {
            snackbar.success('Tee time has been updated successfully');
            handleClose();
         })
         .catch((err) =>
            snackbar.error(
               'Tee time is not available. Please book another time!'
            )
         );
   };
   const handleDeleteTeetime = () => {
      deleteReservation({ teesheet_id, sequence })
         .then(() => {
            snackbar.success(
               'Tee time reservation has been deleted successfully'
            );
            handleClose();
         })
         .catch((err) => {
            snackbar.error(
               'Something went wrong! If the problem persists, contact us!'
            );
         });
   };

   const handleTableCellClick = (teesheet_id, sequence) => {
      console.log(teesheet_id, sequence);

      //check if the tee time is locked
      isTeetimeLocked({ teesheet_id, sequence })
         .then((data) => {
            if (!data) {
               setTeesheet_id(teesheet_id);
               setSequence(sequence);
               setOpen(true);
            } else {
               snackbar.error('Tee time is locked. Please select another time');
            }
         })
         .catch((err) => {
            snackbar.error(err.message);
         });
   };

   const handleClose = async () => {
      // queryClient.refetchQueries(["teesheet"], { active: true });
      queryClient.invalidateQueries(['tee-sheet-info']);
      queryClient.invalidateQueries(['tee-sheet']);
      queryClient.invalidateQueries(['current-tee-sheet']);
      setOpen(false);
   };

   const onChange = (args) => {
      setSelectedDate(moment(args.value).format('YYYY-MM-DD'));
   };
   const handlePrint = () => {
      window.open(`/teesheet-report/${selectedDate}`, '_blank');
   };
   return (
      <div className='m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl'>
         <Header title='Tee sheet' />
         <Button
            variant='contained'
            endIcon={<LocalPrintshopIcon />}
            onClick={handlePrint}
         >
            Print
         </Button>
         <div className='flex justify-between items-start gap-3'>
            <TeeSheetTable
               tableData={data}
               handleTableCellClick={handleTableCellClick}
            />
            <CalendarComponent change={onChange}></CalendarComponent>
         </div>
         {open && (
            <BookingDialog
               open={open}
               onClose={handleClose}
               onDelete={handleDeleteTeetime}
               onUpdate={handleUpdateTeetime}
               onBookAndPay={handleBookAndPay}
               sequence={sequence}
               teesheet_id={teesheet_id}
               members={members}
               memberTypes={memberTypes}
            />
         )}
      </div>
   );
};

export default Home;
