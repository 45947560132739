import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { registerLicense } from '@syncfusion/ej2-base';
import './index.css';
import { ContextProvider } from './contexts/ContextProvider';
import SnackbarProvider from './contexts/SnackbarProvider';
import AuthProvider from './contexts/AuthProvider';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { CartProvider } from './contexts/CartProvider';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
Sentry.init({
   dsn: 'https://712d34e35cd0449a9f7351458c102a3d@o1367674.ingest.sentry.io/6767211',
   integrations: [new BrowserTracing()],

   // Set tracesSampleRate to 1.0 to capture 100%
   // of transactions for performance monitoring.
   // We recommend adjusting this value in production
   tracesSampleRate: 1.0,
});

// Registering Syncfusion license key
registerLicense(
   'ORg4AjUWIQA/Gnt2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkNhXX9ZcXVQQGJYVUU='
);
const queryClient = new QueryClient();

ReactDOM.render(
   <ContextProvider>
      <QueryClientProvider client={queryClient}>
         <BrowserRouter>
            <SnackbarProvider>
               <AuthProvider>
                  <CartProvider>
                     <App />
                  </CartProvider>
               </AuthProvider>
            </SnackbarProvider>
         </BrowserRouter>
         <ReactQueryDevtools initialIsOpen />
      </QueryClientProvider>
   </ContextProvider>,
   document.getElementById('root')
);
