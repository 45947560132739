import axios from "axios";
import { useMutation } from "react-query";

const blockTeetime = async (values) => {
   const { data } = await axios.post(
      process.env.REACT_APP_API_SERVER + "/api/teetimes/block",
      values
   );

   return data;
};

export function useBlockTeetime() {
   const { isLoading, mutateAsync } = useMutation(blockTeetime);

   return { isBlocking: isLoading, blockTeetime: mutateAsync };
}
