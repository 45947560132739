import axios from "axios";
import { useQuery } from "react-query";

const fetchEventTransactions = async (id) => {
   const { data } = await axios.get(
      process.env.REACT_APP_API_SERVER +
         `/api/event/transactions?event_id=${id}`
   );
   return { result: data, count: data.length };
};

export function useEventTransactions(id) {
   return useQuery(["event_transactions", id], () =>
      fetchEventTransactions(id)
   );
}
