import React, { createContext, useContext, useEffect, useState } from "react";
import moment from "moment";

import { useAddCartItems } from "../services/hooks/useAddCartItems";
import { usePaymentStatus } from "../services/hooks/usePaymentStatus";
import { useQueryClient } from "react-query";

const CartContext = createContext();

export const CartProvider = ({ children }) => {
   const queryClient = useQueryClient();

   const [showCart, setShowCart] = useState(false);
   const [orderId, setOrderId] = useState(0);
   const [orderBalance, setOrderBalance] = useState(0);
   const [cartItems, setCartItems] = useState([]); // {teesheet_players_id}
   const [totalPrice, setTotalPrice] = useState(0);
   const [totalQuantities, setTotalQuantities] = useState(0);
   const [taxFee, setTaxFee] = useState(0);
   const [discount, setDiscount] = useState(0);

   const { isAdding, addCartItems } = useAddCartItems();
   const { isUpdating, updatePaymentStatus } = usePaymentStatus();

   //for regular items
   const onAdd = (product, quantity) => {
      const checkProductInCart = cartItems.find(
         (item) => item.id === product.id
      );
      setOrderBalance(
         totalPrice + product.price * quantity + taxFee + product.tax * quantity
      );
      setTotalPrice(
         (prevTotalPrice) => prevTotalPrice + product.price * quantity
      );

      setTaxFee((prevTaxFee) => prevTaxFee + product.tax * quantity);
      setTotalQuantities(
         (prevTotalQuantities) => prevTotalQuantities + quantity
      );

      if (checkProductInCart) {
         const updatedCartItems = cartItems.map((cartProduct) => {
            if (cartProduct.id === product.id)
               return {
                  ...cartProduct,
                  quantity: cartProduct.quantity + quantity,
               };
            else return cartProduct;
         });
         localStorage.setItem("cart", JSON.stringify(updatedCartItems));
         setCartItems(updatedCartItems);
      } else {
         product.quantity = quantity;
         setCartItems([...cartItems, { ...product }]);
         localStorage.setItem(
            "cart",
            JSON.stringify([...cartItems, { ...product }])
         );
      }
   };

   const onRemove = (id) => {
      let item = cartItems.find((item) => item.id === id);
      if (item) {
         setCartItems(cartItems.filter((item) => item.id !== id));
         if (totalQuantities >= 1) {
            setTotalQuantities((prev) => prev - item.quantity);
            setOrderBalance(
               totalPrice -
                  item.price * item.quantity +
                  taxFee -
                  item.tax * item.quantity
            );
            setTotalPrice((prev) => prev - item.price * item.quantity);
            setTaxFee((prev) => prev - item.tax * item.quantity);
         }
         localStorage.setItem(
            "cart",
            JSON.stringify(cartItems.filter((item) => item.id !== id))
         );
      }

      if (totalQuantities === 1) {
         addOrderBalance(0);
         addOrderId(0);
      }
   };
   const toggleCartItemQuantity = (id, value) => {
      let foundProduct = cartItems.find((item) => item.id === id);
      const newCartItems = cartItems.filter((item) => item.id !== id);

      if (value === "inc") {
         setCartItems([
            ...newCartItems,
            { ...foundProduct, quantity: foundProduct.quantity + 1 },
         ]);
         setOrderBalance(
            totalPrice + foundProduct.price + taxFee + foundProduct.tax
         );
         setTotalPrice((prevTotalPrice) => prevTotalPrice + foundProduct.price);

         setTaxFee((prevTaxFee) => prevTaxFee + foundProduct.tax);
         setTotalQuantities((prevTotalQuantities) => prevTotalQuantities + 1);
      } else if (value === "dec") {
         if (foundProduct.quantity > 1) {
            setCartItems([
               ...newCartItems,
               { ...foundProduct, quantity: foundProduct.quantity - 1 },
            ]);
            setOrderBalance(
               totalPrice - foundProduct.price + taxFee - foundProduct.tax
            );
            setTotalPrice(
               (prevTotalPrice) => prevTotalPrice - foundProduct.price
            );

            setTaxFee((prevTaxFee) => prevTaxFee - foundProduct.tax);
            setTotalQuantities(
               (prevTotalQuantities) => prevTotalQuantities - 1
            );
         }
      }
   };

   //for tee time
   const addToCart = (item) => {
      if (!cartItems.find((cartItem) => cartItem.id === item.id)) {
         setCartItems([...cartItems, item]);
         setTotalQuantities((prev) => prev + 1);
         localStorage.setItem("cart", JSON.stringify([...cartItems, item]));
         setOrderBalance(totalPrice + item.price + taxFee + item.tax);
         setTotalPrice((prev) => prev + item.price);
         setTaxFee((prev) => prev + item.tax);
      }
   };

   const addTeetimesToCart = (items) => {
      //remove duplicate items from cart
      const newCartItems = items.filter(
         (item) => !cartItems.find((cartItem) => cartItem.id === item.id)
      );
      setCartItems([...cartItems, ...newCartItems]);
      setTotalQuantities((prev) => prev + newCartItems.length);
      localStorage.setItem(
         "cart",
         JSON.stringify([...cartItems, ...newCartItems])
      );
      setOrderBalance(
         totalPrice +
            newCartItems.reduce((acc, item) => acc + item.price, 0) +
            taxFee +
            newCartItems.reduce((acc, item) => acc + item.tax, 0)
      );
      setTotalPrice(
         (prev) =>
            prev + newCartItems.reduce((acc, item) => acc + item.price, 0)
      );

      setTaxFee(
         (prev) => prev + newCartItems.reduce((acc, item) => acc + item.tax, 0)
      );
   };

   //for tee time
   const removeFromCart = (id) => {
      let item = cartItems.find((item) => item.id === id);
      if (item) {
         setCartItems(cartItems.filter((item) => item.id !== id));
         if (totalQuantities >= 1) {
            setTotalQuantities((prev) => prev - 1);
            setOrderBalance(totalPrice - item.price + taxFee - item.tax);
            setTotalPrice((prev) => prev - item.price);
            setTaxFee((prev) => prev - item.tax);
         }
         localStorage.setItem(
            "cart",
            JSON.stringify(cartItems.filter((item) => item.id !== id))
         );
      }
      if (totalQuantities === 1) {
         addOrderBalance(0);
         addOrderId(0);
      }
   };

   const resetCart = () => {
      setCartItems([]);
      setTotalQuantities(0);
      setTotalPrice(0);
      setOrderBalance(0);
      setTaxFee(0);
      setDiscount(0);
      setOrderId(0);
      localStorage.removeItem("cart");
      localStorage.removeItem("discount");
      localStorage.removeItem("orderId");
      localStorage.removeItem("orderBalance");
   };

   //add discount
   const addDiscount = (discount) => {
      let num = Number(discount);
      setDiscount(num);
      localStorage.setItem("discount", num);
   };
   //add order id
   const addOrderId = (orderId) => {
      setOrderId(orderId);
      localStorage.setItem("orderId", orderId);
   };
   //add balance
   const addOrderBalance = (balance) => {
      setOrderBalance(balance);
      localStorage.setItem("orderBalance", balance);
   };

   useEffect(() => {
      let cart = JSON.parse(localStorage.getItem("cart")) || [];
      setCartItems(cart);
      setTotalQuantities(cart.length);
      setTotalPrice(cart.reduce((acc, item) => item.price + acc, 0));
      setTaxFee(cart.reduce((acc, item) => item.tax + acc, 0));
      let discount = JSON.parse(localStorage.getItem("discount")) || 0;
      let orderId = JSON.parse(localStorage.getItem("orderId")) || 0;
      let orderBalance = JSON.parse(localStorage.getItem("orderBalance")) || 0;
      setDiscount(discount);
      setOrderId(orderId);
      setOrderBalance(orderBalance);
   }, []);
   return (
      <CartContext.Provider
         value={{
            showCart,
            setShowCart,
            cartItems,
            setCartItems,
            totalPrice,
            setTotalPrice,
            taxFee,
            setTaxFee,
            discount,
            addDiscount,
            orderId,
            addOrderId,
            orderBalance,
            addOrderBalance,
            totalQuantities,
            setTotalQuantities,
            addToCart,
            addTeetimesToCart,
            onAdd,
            toggleCartItemQuantity,
            removeFromCart,
            resetCart,
            onRemove, //remove product
         }}
      >
         {children}
      </CartContext.Provider>
   );
};

export const useCartContext = () => useContext(CartContext);
