import React from "react";
import {
   TabComponent,
   TabItemDirective,
   TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";

import { Header, Events as EventsComponent } from "../components";
import EventReports from "../components/EventReports";
import EventPayments from "../components/EventPayments";

const Events = () => {
   const headerText = [
      { text: "Events" },
      { text: "Payments" },
      { text: "Reports" },
   ];
   function contentTemplate1() {
      return <EventsComponent />;
   }
   function contentTemplate2() {
      return <EventPayments />;
   }
   function contentTemplate3() {
      return <EventReports />;
   }

   return (
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl h-screen">
         <Header title="Events" />
         <TabComponent heightAdjustMode="Auto">
            <TabItemsDirective>
               <TabItemDirective
                  header={headerText[0]}
                  content={contentTemplate1}
               />

               <TabItemDirective
                  header={headerText[1]}
                  content={contentTemplate2}
               />
               <TabItemDirective
                  header={headerText[2]}
                  content={contentTemplate3}
               />
            </TabItemsDirective>
         </TabComponent>
      </div>
   );
};

export default Events;
