import axios from "axios";
import { useQuery } from "react-query";

const fetchDailySalesCategorySummary = async (date) => {
   const { data } = await axios.get(
      process.env.REACT_APP_API_SERVER +
         `/api/transaction/daily_sales_category_summary?date=${date}`
   );

   return data;
};

export function useDailySalesCategorySummary(date) {
   return useQuery(["daily_sales_category_summary", date], () =>
      fetchDailySalesCategorySummary(date)
   );
}
