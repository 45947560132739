import React from "react";
import {
   TabComponent,
   TabItemDirective,
   TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import {
   Header,
   Leagues as LeaguesComponent,
   LeaguePlayers,
   LeagueSchedules,
   LeaguePlayerCheckin,
} from "../components";
import AddLeagueSchedule from "../components/AddLeagueSchedule";

const Leagues = () => {
   const headerText = [
      { text: "League Schedules" },
      { text: "Check in" },
      { text: "Leagues" },
      { text: "Players" },
      { text: "Add League Schedule" },
   ];
   function contentTemplate1() {
      return <LeagueSchedules />;
   }
   function contentTemplate2() {
      return <LeaguePlayerCheckin />;
   }
   function contentTemplate3() {
      return <LeaguesComponent />;
   }
   function contentTemplate4() {
      return <LeaguePlayers />;
   }
   function contentTemplate5() {
      return <AddLeagueSchedule />;
   }

   return (
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl h-screen">
         <Header title="Leagues" />
         <TabComponent heightAdjustMode="Auto">
            <TabItemsDirective>
               <TabItemDirective
                  header={headerText[0]}
                  content={contentTemplate1}
               />

               <TabItemDirective
                  header={headerText[1]}
                  content={contentTemplate2}
               />
               <TabItemDirective
                  header={headerText[2]}
                  content={contentTemplate3}
               />
               <TabItemDirective
                  header={headerText[3]}
                  content={contentTemplate4}
               />
               <TabItemDirective
                  header={headerText[4]}
                  content={contentTemplate5}
               />
            </TabItemsDirective>
         </TabComponent>
      </div>
   );
};

export default Leagues;
