import axios from "axios";
import { useMutation } from "react-query";

const bookSingleTeetime = async (values) => {
   const { data } = await axios.post(
      process.env.REACT_APP_API_SERVER + `/api/teetimes/book_single_teetime`,
      values[0]
   );
   return data;
};

export function useBookSingleTeetime() {
   const { isLoading, mutateAsync } = useMutation(bookSingleTeetime);

   return { isBookingSingle: isLoading, bookSingleTeetime: mutateAsync };
}
