import React from "react";
import { useParams } from "react-router";
import moment from "moment";
import { useTeeSheetTableData } from "../services/hooks/useTeeSheetTableData";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper } from "@mui/material";

const TeesheetReport = () => {
   const { date } = useParams();
   const current_date = moment(date).format("dddd, MMMM Do, YYYY");

   const { data } = useTeeSheetTableData(date);

   function getTableData() {
      let numbers = [];
      for (let i = 0; i < data.length; i += 2) {
         numbers.push(i);
      }
      return <>{numbers.map((num) => getRowData(data[num], data[num + 1]))}</>;
   }
   function getRowData(teetime1, teetime2) {
      if (teetime1 && teetime2) {
         return (
            <>
               <tr className="border-b border-t-4">
                  <td className="border-r border-l">
                     {moment(teetime1.teetime).format("h:mm a")}
                  </td>
                  <td className="border-r border-l">
                     {teetime1.player1_last_name} {teetime1.player1_first_name}
                  </td>
                  <td className="border-r border-l"></td>
                  <td className="border-r border-l">
                     {moment(teetime2.teetime).format("h:mm a")}
                  </td>
                  <td className="border-r border-l">
                     {teetime2.player1_last_name} {teetime2.player1_first_name}
                  </td>
                  <td className="border-r border-l"></td>
               </tr>
               <tr className="border-b">
                  <td className="border-r border-l">&nbsp;</td>
                  <td className="border-r border-l">
                     {teetime1.player2_last_name} {teetime1.player2_first_name}
                  </td>
                  <td className="border-r border-l"></td>
                  <td className="border-r border-l">&nbsp;</td>
                  <td className="border-r border-l">
                     {teetime2.player2_last_name} {teetime2.player2_first_name}
                  </td>
                  <td className="border-r border-l"></td>
               </tr>
               <tr className="border-b">
                  <td className="border-r border-l">&nbsp;</td>
                  <td>
                     {teetime1.player3_last_name} {teetime1.player3_first_name}
                  </td>
                  <td className="border-r border-l"></td>
                  <td className="border-r border-l">&nbsp;</td>
                  <td className="border-r border-l">
                     {teetime2.player3_last_name} {teetime2.player3_first_name}
                  </td>
                  <td className="border-r border-l"></td>
               </tr>
               <tr className="border-b">
                  <td className="border-r border-l">&nbsp;</td>
                  <td className="border-r border-l">
                     {teetime1.player4_last_name} {teetime1.player4_first_name}
                  </td>
                  <td className="border-r border-l"></td>
                  <td className="border-r border-l">&nbsp;</td>
                  <td className="border-r border-l">
                     {teetime2.player4_last_name} {teetime2.player4_first_name}
                  </td>
                  <td className="border-r border-l"></td>
               </tr>
            </>
         );
      } else {
         return <></>;
      }
   }
   return (
      <div className="w-full">
         <div className="flex flex-row justify-between mb-2">
            <span className="text-xl font-semibold">Starter Sheet</span>
            <span className="text-xl font-semibold">Richland Golf Club</span>
         </div>
         <div>
            <span className="text-base mb-2">{current_date}</span>
         </div>
         <table className="w-full">
            <thead className="border">
               <tr>
                  <th
                     scope="col"
                     className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                  >
                     Time
                  </th>
                  <th
                     scope="col"
                     className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                  >
                     Name
                  </th>
                  <th
                     scope="col"
                     className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                  >
                     Notes
                  </th>
                  <th
                     scope="col"
                     className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                  >
                     Time
                  </th>
                  <th
                     scope="col"
                     className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                  >
                     Name
                  </th>
                  <th
                     scope="col"
                     className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                  >
                     Notes
                  </th>
               </tr>
            </thead>
            <tbody>{data ? getTableData() : <div></div>}</tbody>
         </table>
      </div>
   );
};

export default TeesheetReport;
