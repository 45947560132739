import React from "react";
import { LoadingButton } from "@mui/lab";
import {
   Card,
   CardActions,
   CardContent,
   CardHeader,
   TextField,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "../contexts/SnackbarProvider";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useCreateTeetime } from "../services/hooks/useCreateTeetime";

const AddTeeTime = () => {
   const { isAdding, addTeetimes } = useCreateTeetime();

   const snackbar = useSnackbar();
   const handleSubmit = async (values) => {
      addTeetimes(values)
         .then(() => {
            formik.resetForm();
            snackbar.success("Teetimes have been updated.");
         })
         .catch((err) =>
            snackbar.error(
               "Something went wrong! If the problem persists, contact us!"
            )
         );
   };
   const formik = useFormik({
      initialValues: {
         start: moment().format("YYYY-MM-DD"),
         end: moment().add(5, "days").format("YYYY-MM-DD"),
         interval: 10,
         startTime: "07:00",
         endTime: "19:00",
         weekendStartTime: "06:30",
         weekendEndTime: "19:00",
      },
      validationSchema: Yup.object({
         start: Yup.string().required("Required"),
         end: Yup.string().required("Required"),
         interval: Yup.string().required("Required"),
         startTime: Yup.string().required("Required"),
         endTime: Yup.string().required("Required"),
         weekendStartTime: Yup.string().required("Required"),
         weekendEndTime: Yup.string().required("Required"),
      }),
      onSubmit: (values) => handleSubmit(values),
   });

   return (
      <form onSubmit={formik.handleSubmit} noValidate>
         <Card sx={{ width: "300px", marginTop: "20px" }}>
            <CardHeader title={"Add Teetimes"} />
            <CardContent>
               <TextField
                  margin="normal"
                  id="start"
                  label="start"
                  variant="outlined"
                  disabled={isAdding}
                  value={formik.values.start}
                  onChange={formik.handleChange}
                  error={formik.touched.start && Boolean(formik.errors.start)}
                  helperText={formik.touched.start && formik.errors.start}
               />
               <TextField
                  margin="normal"
                  id="end"
                  label="end"
                  variant="outlined"
                  disabled={isAdding}
                  value={formik.values.end}
                  onChange={formik.handleChange}
                  error={formik.touched.end && Boolean(formik.errors.end)}
                  helperText={formik.touched.end && formik.errors.end}
               />
               <TextField
                  margin="normal"
                  id="interval"
                  label="interval"
                  variant="outlined"
                  disabled={isAdding}
                  value={formik.values.interval}
                  onChange={formik.handleChange}
                  error={
                     formik.touched.interval && Boolean(formik.errors.interval)
                  }
                  helperText={formik.touched.interval && formik.errors.interval}
               />
               <TextField
                  margin="normal"
                  id="startTime"
                  label="Start Time"
                  variant="outlined"
                  disabled={isAdding}
                  value={formik.values.startTime}
                  onChange={formik.handleChange}
                  error={
                     formik.touched.startTime &&
                     Boolean(formik.errors.startTime)
                  }
                  helperText={
                     formik.touched.startTime && formik.errors.startTime
                  }
               />
               <TextField
                  margin="normal"
                  id="endTime"
                  label="End Time"
                  variant="outlined"
                  disabled={isAdding}
                  value={formik.values.endTime}
                  onChange={formik.handleChange}
                  error={
                     formik.touched.endTime && Boolean(formik.errors.endTime)
                  }
                  helperText={formik.touched.endTime && formik.errors.endTime}
               />
               <TextField
                  margin="normal"
                  id="weekendStartTime"
                  label="Weekend Start Time"
                  variant="outlined"
                  disabled={isAdding}
                  value={formik.values.weekendStartTime}
                  onChange={formik.handleChange}
                  error={
                     formik.touched.weekendStartTime &&
                     Boolean(formik.errors.weekendStartTime)
                  }
                  helperText={
                     formik.touched.weekendStartTime &&
                     formik.errors.weekendStartTime
                  }
               />
               <TextField
                  margin="normal"
                  id="weekendEndTime"
                  label="Weekend End Time"
                  variant="outlined"
                  disabled={isAdding}
                  value={formik.values.weekendEndTime}
                  onChange={formik.handleChange}
                  error={
                     formik.touched.weekendEndTime &&
                     Boolean(formik.errors.weekendEndTime)
                  }
                  helperText={
                     formik.touched.weekendEndTime &&
                     formik.errors.weekendEndTime
                  }
               />
            </CardContent>
            <CardActions>
               <LoadingButton
                  type="submit"
                  loading={isAdding}
                  variant="contained"
               >
                  {"Update"}
               </LoadingButton>
            </CardActions>
         </Card>
      </form>
   );
};

export default AddTeeTime;
