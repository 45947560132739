import React from "react";
import {
   GridComponent,
   ColumnsDirective,
   ColumnDirective,
   Page,
   Selection,
   Inject,
   Edit,
   Toolbar,
   Sort,
   Filter,
   Search,
} from "@syncfusion/ej2-react-grids";
import { DataManager, UrlAdaptor } from "@syncfusion/ej2-data";
import { Header } from "../components";
import AuthHeader from "../services/authHeader";

const Users = () => {
   const toolbarOptions = ["Add", "Edit", "Update", "Cancel"];
   const editSettings = {
      allowEditing: true,
      allowAdding: true,
      allowDeleting: false,
      newRowPosition: "Top",
   };

   const dataManager = new DataManager({
      adaptor: new UrlAdaptor(),
      updateUrl:
         process.env.REACT_APP_API_SERVER + "/api/user/syncfusion_update",
      insertUrl:
         process.env.REACT_APP_API_SERVER + "/api/user/syncfusion_insert",
      url: process.env.REACT_APP_API_SERVER + `/api/user/syncfusion`,
      headers: [AuthHeader()],
   });
   return (
      <GridComponent
         dataSource={dataManager}
         editSettings={editSettings}
         toolbar={toolbarOptions}
      >
         <ColumnsDirective>
            <ColumnDirective
               field="id"
               headerText="ID"
               width="70"
               allowEditing={false}
            />
            <ColumnDirective
               field="phone"
               headerText="Phone"
               width="100"
               allowEditing={true}
            />
            <ColumnDirective
               field="first_name"
               headerText="First Name"
               width="120"
               allowEditing={true}
            />
            <ColumnDirective
               field="last_name"
               headerText="Last Name"
               width="120"
               allowEditing={true}
            />
            <ColumnDirective
               field="email"
               headerText="Email"
               width="150"
               allowEditing={true}
            />

            <ColumnDirective
               field="isActive"
               headerText="Active"
               width="100"
               allowEditing={true}
            />
            <ColumnDirective
               field="role_id"
               headerText="Role"
               width="100"
               allowEditing={true}
            />
         </ColumnsDirective>
         <Inject services={[Toolbar, Edit]} />
      </GridComponent>
   );
};

export default Users;
