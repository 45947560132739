import React from "react";
import {
   TabComponent,
   TabItemDirective,
   TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import { Header, Category, Product } from "../components";

const headerText = [{ text: "Product" }, { text: "Category" }];
function contentTemplate1() {
   return <Product />;
}

function contentTemplate2() {
   return <Category />;
}
const Products = () => {
   return (
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl h-screen">
         <Header title="Products" />
         <TabComponent heightAdjustMode="Auto">
            <TabItemsDirective>
               <TabItemDirective
                  header={headerText[0]}
                  content={contentTemplate1}
               />

               {/* <TabItemDirective
                  header={headerText[1]}
                  content={contentTemplate2}
               /> */}
            </TabItemsDirective>
         </TabComponent>
      </div>
   );
};

export default Products;
