import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import {
   Autocomplete,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   FormControl,
   FormControlLabel,
   Grid,
   MenuItem,
   Radio,
   RadioGroup,
   Switch,
   TextField,
   ToggleButton,
   ToggleButtonGroup,
   Typography,
} from "@mui/material";
import { useMemberTypes } from "../services/hooks/useMemberTypes";
import { useBookMultipleTeetime } from "../services/hooks/useBookMultipleTeetime";
import { useTeeSheetInfo } from "../services/hooks/useTeeSheetInfo";
import { initForeignKeyColumn } from "@syncfusion/ej2-react-grids";

const QuickBookingDialog = ({
   open,
   onClose,
   onAdd,
   selectedValues,
   teesheet_id, //for first selected tee sheet id
   sequence, //for first selected tee sheet sequence
}) => {
   const [formValues, setFormValues] = useState([]);

   const [roundType, setRoundType] = useState(0);
   const [transportation, setTransportation] = useState(0);
   const [member_type_id, setMember_type_id] = React.useState(1);
   const [quick_entry, setQuick_entry] = useState(false);
   const { data: memberTypes } = useMemberTypes();
   const { bookMultipleTeetime } = useBookMultipleTeetime();
   const [cart_fee, setCart_fee] = React.useState(0);
   const [green_fee, setGreen_fee] = React.useState(0);
   const [pricingInfo, setPricingInfo] = React.useState([]);
   const [email, setEmail] = React.useState("");
   const [mobile_phone, setMobile_phone] = React.useState("");
   async function getPricingInfo(teesheet_id) {
      const { data } = await axios.get(
         process.env.REACT_APP_API_SERVER +
            `/api/teetimes/pricing2?teesheet_id=${teesheet_id}&member_type_id=${member_type_id}&roundType=${roundType}&transportation=${transportation}`
      );
      return data;
   }
   function setInitFormValues(first_name, last_name) {
      let dataArray = [];
      //convert data to array
      selectedValues.map(function (item) {
         Object.keys(item).map(function (key) {
            dataArray.push([item[key], parseInt(key.replace("player", ""))]);
         });
      });

      let formValues = [];
      dataArray.map(function (item) {
         formValues.push({
            teesheet_id: item[0],
            sequence: item[1],
            first_name: first_name,
            last_name: last_name,
         });
      });
      setFormValues(formValues);
   }
   function getTeeSheetInfo() {
      axios
         .get(
            process.env.REACT_APP_API_SERVER +
               `/api/teetimes/teetime?teesheet_id=${teesheet_id}&sequence=${sequence}`
         )
         .then((res) => {
            let info = res.data[0];

            if (info.teesheet_players_status > 0) {
               setMember_type_id(info.member_type_id);
               setTransportation(info.walking_riding);
               setRoundType(info.hole_type);
               setEmail(info.email);
               setMobile_phone(info.mobile_phone);
               setInitFormValues(info.first_name, info.last_name);
               setQuick_entry(true);
            } else {
               setInitFormValues("", "");
               setQuick_entry(false);
            }
         })
         .catch((err) => {
            console.log(err);
         });
   }
   function getSelectedTeesheetIds(items) {
      let dataValues = items.map((item) => {
         return Object.values(item);
      });
      //get datavalues into one dimensional array and remove duplicates
      let dataValuesFlat = dataValues.flat(1);
      let dataValuesUnique = [...new Set(dataValuesFlat)];
      return dataValuesUnique;
   }
   function getTeesheetPricingInfo(teesheet_id) {
 
      return pricingInfo.filter((item) => {
         return (
            item.teesheet_id === teesheet_id &&
            item.member_type_id === parseInt(member_type_id) &&
            item.roundType === parseInt(roundType) &&
            item.transportation === parseInt(transportation)
         );
      });
   }
   useEffect(() => {
      getTeeSheetInfo();
   }, []);

   useEffect(() => {
      let selectedTeesheetIds = getSelectedTeesheetIds(selectedValues);
      selectedTeesheetIds.map(async (teesheet_id) => {
         let pricingInfo = await getPricingInfo(teesheet_id);
         setPricingInfo((prev) => [...prev, pricingInfo]);
      });
   }, [member_type_id, roundType, transportation]);

   let handleChange = (i, e) => {
      let newFormValues = [...formValues];
      newFormValues[i][e.target.name] = e.target.value;
      setFormValues(newFormValues);
   };
   let handleSubmit = (event) => {
      event.preventDefault();
      formValues.forEach((item) => {
         let price = getTeesheetPricingInfo(item.teesheet_id);
         item.cart_fee = price[0].cart_fee;
         item.green_fee = price[0].green_fee;
         item.tax = price[0].tax;
         item.email = email;
         item.mobile_phone = mobile_phone;
         item.member_type_id = member_type_id;
         item.roundType = roundType;
         item.transportation = transportation;
         item.source = 0;
      });
      onAdd(formValues);
   };
   const handleOnClose = () => {
      onClose();
   };
   const handleChangeRoundType = (e) => {
      setRoundType(e.target.value);
   };
   const handleChangeTransportation = (e) => {
      setTransportation(e.target.value);
   };
   const handleMemberTypeChange = (e) => {
      setMember_type_id(e.target.value);
   };
   const handleChangeQuickEntry = (e) => {
      setQuick_entry(e.target.checked);
      let newFormValues = [...formValues];
      if (e.target.checked) {
         let first_name = formValues[0].first_name;
         let last_name = formValues[0].last_name;
         newFormValues.map(function (item) {
            item.first_name = first_name;
            item.last_name = last_name;
         });
         setFormValues(newFormValues);
      }
   };
   return (
      <form onSubmit={handleSubmit}>
         <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Book teetime</DialogTitle>
            <DialogContent>
               <div>
                  <div className="flex flex-row justify-end">
                     <FormControlLabel
                        control={
                           <Switch
                              name="quick_entry"
                              checked={quick_entry}
                              onChange={handleChangeQuickEntry}
                           />
                        }
                        label="Copy first player name"
                     />
                  </div>
                  {formValues.map((element, index) => (
                     <div key={index} className="flex flex-row w-full">
                        <input
                           type="hidden"
                           name="teesheet_id"
                           value={element.teesheet_id}
                           onChange={(e) => handleChange(index, e)}
                        />

                        <input
                           type="hidden"
                           name="sequence"
                           value={element.sequence}
                           onChange={(e) => handleChange(index, e)}
                        />
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                           First name:
                        </label>
                        <input
                           className="appearance-none block w-60 bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                           type="text"
                           name="first_name"
                           value={element.first_name || ""}
                           onChange={(e) => handleChange(index, e)}
                        />
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                           Last name:
                        </label>
                        <input
                           className="appearance-none block w-60 bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                           type="text"
                           name="last_name"
                           value={element.last_name || ""}
                           onChange={(e) => handleChange(index, e)}
                        />
                     </div>
                  ))}
                  <div className="flex flex-row w-full justify-between">
                     <FormControl component="fieldset" margin="normal">
                        <RadioGroup
                           row
                           aria-label="18-holes"
                           name="teetime_type"
                           value={roundType}
                           onChange={handleChangeRoundType}
                        >
                           <FormControlLabel
                              key="18"
                              value={0}
                              control={<Radio />}
                              label="18 holes"
                           />
                           <FormControlLabel
                              key="9"
                              value={1}
                              control={<Radio />}
                              label="9 holes"
                           />
                        </RadioGroup>
                     </FormControl>
                     <FormControl component="fieldset" margin="normal">
                        <RadioGroup
                           row
                           aria-label="riding-walking"
                           name="transportation"
                           value={transportation}
                           onChange={handleChangeTransportation}
                        >
                           <FormControlLabel
                              key="riding"
                              value="0"
                              control={<Radio />}
                              label="Riding"
                           />
                           <FormControlLabel
                              key="walking"
                              value="1"
                              control={<Radio />}
                              label="Walking"
                           />
                        </RadioGroup>
                     </FormControl>
                  </div>
                  <div>
                     <TextField
                        margin="normal"
                        required
                        id="player_type"
                        fullWidth
                        select
                        label={"Player type"}
                        name="role"
                        value={member_type_id}
                        onChange={handleMemberTypeChange}
                     >
                        {memberTypes?.map((memberType) => (
                           <MenuItem key={memberType.id} value={memberType.id}>
                              {memberType.name}
                           </MenuItem>
                        ))}
                     </TextField>
                  </div>
               </div>
            </DialogContent>
            <DialogActions>
               <Button onClick={handleOnClose}>{"Cancel"}</Button>
               <Button type="submit" onClick={handleSubmit}>
                  Book
               </Button>
            </DialogActions>
         </Dialog>
      </form>
   );
};

export default QuickBookingDialog;
