import axios from "axios";
import { useMutation } from "react-query";

const unblockTeetime = async (values) => {
   const { data } = await axios.post(
      process.env.REACT_APP_API_SERVER + "/api/teetimes/unblock",
      values
   );

   return data;
};

export function useUnblockTeetime() {
   const { isLoading, mutateAsync } = useMutation(unblockTeetime);

   return { isBlocking: isLoading, unblockTeetime: mutateAsync };
}
