import axios from "axios";
import { useQuery } from "react-query";

const fetchCustomDailySalesSummary = async (from_date,to_date) => {

   const { data } = await axios.get(
      process.env.REACT_APP_API_SERVER +
         `/api/transaction/custom_daily_sales_summary?from_date=${from_date}&to_date=${to_date}`
   );

   return data;
};

export function useCustomDailySalesSummary(from_date,to_date) {
   return useQuery(["custom_daily_sales_summary", from_date,to_date], () =>
      fetchCustomDailySalesSummary(from_date,to_date)
   );
}
