import { AlertTitle } from '@mui/material';
import Alert from '@mui/material/Alert';
import React from 'react';

const Error = ({ error }) => {
   let open = error !=null &&  error !==''
   return (
      <>
         {open && (
            <Alert severity='error'>
               <AlertTitle>Error</AlertTitle>
               <strong>{error}</strong>
            </Alert>
         )}
      </>
   );
};

export default Error;
