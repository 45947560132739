import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import {
   Autocomplete,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   FormControl,
   FormControlLabel,
   Grid,
   MenuItem,
   Radio,
   RadioGroup,
   Switch,
   TextField,
   ToggleButton,
   ToggleButtonGroup,
   Typography,
} from '@mui/material';
import moment from 'moment';

import { useTeetimePlayerStatus } from '../services/hooks/useTeetimePlayerStatus';
import { useTeetimeTransactions } from '../services/hooks/useTeetimeTransactions';
import { useCartContext } from '../contexts/CartProvider';
import { TeetimeTransactions } from '../components';

const BookingDialog = ({
   open,
   onClose,
   onUpdate,
   onDelete,
   onBookAndPay,
   sequence,
   teesheet_id,
   members,
   memberTypes,
}) => {
   const { addToCart } = useCartContext();
   const { updateTeetimePlayerStatus } = useTeetimePlayerStatus();
   const [pricingInfo, setPricingInfo] = useState(null);
   const [teeSheetInfo, setTeeSheetInfo] = useState(null);
   const { data: teetime_transactions } = useTeetimeTransactions(
      teesheet_id,
      sequence
   );
   const [first_name, setFirst_name] = React.useState('');
   const [last_name, setLast_name] = React.useState('');
   const [email, setEmail] = React.useState('');
   const [mobilePhone, setMobilePhone] = React.useState('');
   const [roundType, setRoundType] = React.useState(0);
   const [cart_fee, setCart_fee] = React.useState(0);
   const [green_fee, setGreen_fee] = React.useState(0);
   const [tax, setTax] = React.useState(0);
   const [member_type_id, setMember_type_id] = React.useState(1);
   const [transportation, setTransportation] = React.useState(0);
   const [reservationStatus, setReservationStatus] = React.useState(0);

   const [quick_entry, setQuick_entry] = useState(false);

   function getMemberPricingInfo(member_type_id) {
      let result = null;
      let type = member_type_id === null ? 1 : member_type_id;

      if (pricingInfo) {
         result = pricingInfo.filter((pricing) => {
            return pricing.member_type_id === type;
         });
      }
      return result;
   }

   function updateFeeInfo() {
      var info = getMemberPricingInfo(teeSheetInfo.member_type_id)[0];
      if (parseInt(roundType) === 0) {
         //18 holes
         setGreen_fee(info.green_fee_18);
         if (parseInt(transportation) === 0)
            //riding
            setCart_fee(info.cart_fee_18);
         else setCart_fee(0);
      } else {
         setGreen_fee(info.green_fee_9);
         if (parseInt(transportation) === 0)
            //riding
            setCart_fee(info.cart_fee_18);
         else setCart_fee(0);
      }
   }

   const handleAutoChange = (value) => {
      setFirst_name(value.first_name);
      setLast_name(value.last_name);
      setMobilePhone(value.mobile_phone);
      setEmail(value.email);
      setMember_type_id(value.member_type_id);
      //update fee info
      let price = getMemberPricingInfo(value.member_type_id);
      if (parseInt(roundType) === 0) {
         //18 holes
         setGreen_fee(price[0].green_fee_18);
         setTax(price[0].tax_18);
         if (parseInt(transportation) === 0)
            //riding
            setCart_fee(price[0].cart_fee_18);
         else setCart_fee(0);
      } else {
         setGreen_fee(price[0].green_fee_9);
         setTax(price[0].tax_9);
         if (parseInt(transportation) === 0)
            //riding
            setCart_fee(price[0].cart_fee_9);
         else setCart_fee(0);
      }
   };

   const handleChangeStatus = (event, value) => {
      if (!value) value = 1; //reserved

      updateTeetimePlayerStatus({
         teesheet_id: teesheet_id,
         sequence: sequence,
         status: value,
      })
         .then(() => {
            console.log('success');
         })
         .catch((err) => {
            console.log(err);
         });
      setReservationStatus(value);
   };
   const handleChangeTransportation = (e) => {
      setTransportation(e.target.value);
      let price = getMemberPricingInfo(member_type_id);
      if (parseInt(e.target.value) === 0) {
         //riding
         if (parseInt(roundType) === 0)
            //18 holes
            setCart_fee(price[0].cart_fee_18);
         else setCart_fee(price[0].cart_fee_9);
      } else setCart_fee(0);
   };

   const handleChangeRoundType = (e) => {
      setRoundType(e.target.value);
      let price = getMemberPricingInfo(member_type_id);
      if (parseInt(e.target.value) === 0) {
         //18 holes
         if (transportation === 0) {
            //riding
            setCart_fee(price[0].cart_fee_18);
         }

         setGreen_fee(price[0].green_fee_18);
         setTax(price[0].tax_18);
      }
      if (parseInt(e.target.value) === 1) {
         //9 holes
         if (parseInt(transportation) === 0) {
            //riding
            setCart_fee(price[0].cart_fee_9);
         }

         setGreen_fee(price[0].green_fee_9);
         setTax(price[0].tax_9);
      }
   };

   const handleMemberTypeChange = (e) => {
      setMember_type_id(e.target.value);
      var info = getMemberPricingInfo(e.target.value)[0];

      if (parseInt(roundType) === 0) {
         //18 holes

         setGreen_fee(info.green_fee_18);
         setTax(info.tax_18);
         if (parseInt(transportation) === 0)
            //riding
            setCart_fee(info.cart_fee_18);
         else setCart_fee(0);
      } else {
         setGreen_fee(info.green_fee_9);
         setTax(info.tax_9);
         if (parseInt(transportation) === 0)
            //riding
            setCart_fee(info.cart_fee_18);
         else setCart_fee(0);
      }
   };

   const handleChangeQuickEntry = (e) => {
      setQuick_entry(e.target.checked);
      if (e.target.checked) {
         setFirst_name('xxxx');
         setLast_name('xxxx');
         setMobilePhone('xxxx');
         setEmail('xxxx@xxx.com');
      } else {
         setFirst_name('');
         setLast_name('');
         setMobilePhone('');
         setEmail('');
      }
      // updateFeeInfo();
   };

   const handleBooking = () => {
      var data = [
         {
            first_name: first_name,
            last_name: last_name,
            mobile_phone: mobilePhone,
            email: email,
            teesheet_id: teesheet_id,
            round_type: roundType,
            transportation: transportation,
            cart_fee: cart_fee,
            green_fee: green_fee,
            tax: tax,
            member_type_id: member_type_id,
            sequence: sequence,
            source: 0,
         },
      ];
      onUpdate(data);
   };

   const handleBookingPayment = () => {
      var data = [
         {
            first_name: first_name,
            last_name: last_name,
            mobile_phone: mobilePhone,
            email: email,
            teesheet_id: teesheet_id,
            sequence: sequence,
            round_type: roundType,
            transportation: transportation,
            cart_fee: cart_fee,
            green_fee: green_fee,
            tax: tax,
            member_type_id: member_type_id,
            source: 0,
         },
      ];

      onBookAndPay({ data, teeSheetInfo });
   };

   const handleOnClose = () => {
      onClose();
   };
   const handlePayClick = () => {
      addToCart({
         id: teeSheetInfo.teesheet_players_id,
         price: Number(green_fee) + Number(cart_fee),
         tax: tax,
         image: 'golf-item.png',
         is_teetime: true,
         quantity: 1,
         first_name: teeSheetInfo.first_name,
         last_name: teeSheetInfo.last_name,
         mobile_phone: teeSheetInfo.mobile_phone,
         email: teeSheetInfo.email,
         teetime: teeSheetInfo.teetime,
         roundType: teeSheetInfo.hole_type ? '9 holes' : '18 holes',
         transportation: teeSheetInfo.walking_riding ? 'Walking' : 'Riding',
      });
      onClose();
   };

   function getPricingInfo() {
      axios
         .get(
            process.env.REACT_APP_API_SERVER +
               `/api/teetimes/pricing?teesheet_id=${teesheet_id}`
         )
         .then((res) => {
            setPricingInfo(res.data);
            var result = res.data.filter((pricing) => {
               return pricing.member_type_id === 1;
            });
            setGreen_fee(result[0].green_fee_18);
            setCart_fee(result[0].cart_fee_18);
            setTax(result[0].tax_18);
         })
         .catch((err) => {
            console.log(err);
         });
   }
   function getTeeSheetInfo() {
      axios
         .get(
            process.env.REACT_APP_API_SERVER +
               `/api/teetimes/teetime?teesheet_id=${teesheet_id}&sequence=${sequence}`
         )
         .then((res) => {
            let info = res.data[0];
            setTeeSheetInfo(res.data[0]);
            if (info.teesheet_players_status > 0) {
               //load from database
               setFirst_name(info.first_name);
               setLast_name(info.last_name);
               setMobilePhone(info.mobile_phone);
               setEmail(info.email);
               setMember_type_id(info.member_type_id);
               setReservationStatus(info.teesheet_players_status);
               setTransportation(info.walking_riding);
               setRoundType(info.hole_type);
               setCart_fee(info.cart_fee);
               setGreen_fee(info.green_fee);
               setTax(info.tax);
            }
         })
         .catch((err) => {
            console.log(err);
         });
   }
   useEffect(() => {
      getPricingInfo();
      getTeeSheetInfo();
   }, [teesheet_id, sequence]);

   const handleFocus = (e) => {
      const { target } = e;
      target.focus();
      target.setSelectionRange(0, target.value.length);
   };

   return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth='md'>
         <DialogTitle>Book teetime</DialogTitle>
         <DialogContent>
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
               <Grid item xs={9}>
                  <Autocomplete
                     id='player_search'
                     freeSolo
                     autoFocus
                     disableClearable
                     options={!members ? [''] : members}
                     getOptionLabel={(option) =>
                        `${option.mobile_phone} - ${option.first_name} - ${option.last_name}`
                     }
                     onChange={(event, value) => {
                        handleAutoChange(value);
                     }}
                     renderInput={(params) => (
                        <TextField
                           {...params}
                           label='Search player'
                           InputProps={{
                              ...params.InputProps,
                              type: 'search',
                           }}
                        />
                     )}
                  />
               </Grid>
               <Grid item xs={3}>
                  <FormControlLabel
                     control={
                        <Switch
                           name='quick_entry'
                           onChange={handleChangeQuickEntry}
                           disabled={teeSheetInfo?.payment_status === 1}
                        />
                     }
                     label='Quick Entry'
                  />
               </Grid>
               <Grid item xs={6}>
                  <TextField
                     margin='normal'
                     fullWidth
                     id='first_name'
                     label={'First name'}
                     name='first_name'
                     value={first_name}
                     onChange={(e) => {
                        setFirst_name(e.target.value);
                     }}
                     onFocus={handleFocus}
                  />
               </Grid>
               <Grid item xs={6}>
                  <TextField
                     margin='normal'
                     fullWidth
                     id='last_name'
                     label={'Last name'}
                     name='last_name'
                     value={last_name}
                     onChange={(e) => {
                        setLast_name(e.target.value);
                     }}
                     onFocus={handleFocus}
                  />
               </Grid>

               <Grid item xs={6}>
                  <TextField
                     margin='normal'
                     fullWidth
                     id='mobile_phone'
                     label={'Phone number'}
                     name='mobile_phone'
                     value={mobilePhone}
                     onChange={(e) => {
                        setMobilePhone(e.target.value);
                     }}
                     onFocus={handleFocus}
                  />
               </Grid>
               <Grid item xs={6}>
                  <TextField
                     margin='normal'
                     fullWidth
                     id='email'
                     label={'Email address'}
                     name='email'
                     autoComplete='email'
                     value={email}
                     onChange={(e) => {
                        setEmail(e.target.value);
                     }}
                     onFocus={handleFocus}
                  />
               </Grid>

               <Grid item xs={12}>
                  <TextField
                     margin='normal'
                     required
                     id='player_type'
                     fullWidth
                     select
                     label={'Player type'}
                     name='role'
                     value={member_type_id}
                     onChange={handleMemberTypeChange}
                     disabled={teeSheetInfo?.payment_status === 1}
                  >
                     {memberTypes?.map((memberType) => (
                        <MenuItem key={memberType.id} value={memberType.id}>
                           {memberType.name}
                        </MenuItem>
                     ))}
                  </TextField>
               </Grid>

               <Grid item xs={12}>
                  <ToggleButtonGroup
                     color='primary'
                     value={reservationStatus}
                     exclusive
                     onChange={handleChangeStatus}
                  >
                     <ToggleButton value={2} sx={{ marginRight: '20px' }}>
                        Arrived
                     </ToggleButton>
                     <ToggleButton value={3} sx={{ marginLeft: '20px' }}>
                        No Show
                     </ToggleButton>
                  </ToggleButtonGroup>
               </Grid>
               {teeSheetInfo?.payment_status === 0 && (
                  <>
                     <Grid item xs={6}>
                        <FormControl component='fieldset' margin='normal'>
                           <RadioGroup
                              row
                              aria-label='18-holes'
                              name='teetime_type'
                              value={roundType}
                              onChange={handleChangeRoundType}
                           >
                              <FormControlLabel
                                 key='18'
                                 value={0}
                                 control={<Radio />}
                                 label='18 holes'
                              />
                              <FormControlLabel
                                 key='9'
                                 value={1}
                                 control={<Radio />}
                                 label='9 holes'
                              />
                           </RadioGroup>
                        </FormControl>
                     </Grid>
                     <Grid item xs={6}>
                        <FormControl component='fieldset' margin='normal'>
                           <RadioGroup
                              row
                              aria-label='riding-walking'
                              name='transportation'
                              value={transportation}
                              onChange={handleChangeTransportation}
                           >
                              <FormControlLabel
                                 key='riding'
                                 value={0}
                                 control={<Radio />}
                                 label='Riding'
                              />
                              <FormControlLabel
                                 key='walking'
                                 value={1}
                                 control={<Radio />}
                                 label='Walking'
                              />
                           </RadioGroup>
                        </FormControl>
                     </Grid>
                     <Grid
                        item
                        xs={12}
                        sx={{
                           display: 'flex',
                           justifyContent: 'flex-end',
                           marginRight: '100px',
                        }}
                     >
                        <Typography
                           variant='body2'
                           sx={{ marginRight: '20px' }}
                        >
                           Green: ${green_fee}
                        </Typography>
                        <Typography
                           variant='body2'
                           sx={{ marginRight: '20px' }}
                        >
                           Cart Fee: ${cart_fee} Tax: ${tax}
                        </Typography>

                        <Typography
                           variant='body2'
                           sx={{ marginRight: '20px' }}
                        >
                           Total: $
                           {parseInt(cart_fee) +
                              parseInt(green_fee) +
                              parseInt(tax)}
                        </Typography>
                     </Grid>
                  </>
               )}

               {teeSheetInfo?.payment_status === 1 && (
                  <Grid item xs={12}>
                     <TeetimeTransactions transactions={teetime_transactions} />
                  </Grid>
               )}
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button onClick={handleOnClose}>{'Cancel'}</Button>
            <LoadingButton
               onClick={handleBooking}
               disabled={
                  teeSheetInfo?.payment_status === 1 || first_name === ''
               }
               variant='contained'
            >
               Book
            </LoadingButton>
            {reservationStatus !== 0 && (
               <Button
                  color='warning'
                  disabled={
                     teeSheetInfo?.payment_status === 1 &&
                     teeSheetInfo?.total > 0.1
                  }
                  variant='contained'
                  onClick={onDelete}
               >
                  Delete
               </Button>
            )}
            <Button
               color='success'
               disabled={
                  teeSheetInfo?.payment_status === 1 ||
                  teeSheetInfo?.teesheet_players_status === 0 ||
                  cart_fee + green_fee + tax === 0 ||
                  moment(teeSheetInfo?.teetime).format('YYYY-MM-DD') !==
                     moment().format('YYYY-MM-DD')
               }
               variant='contained'
               onClick={handlePayClick}
            >
               Pay
            </Button>
            <LoadingButton
               color='info'
               onClick={handleBookingPayment}
               disabled={
                  teeSheetInfo?.payment_status === 1 ||
                  (first_name === '' && last_name === '') ||
                  cart_fee + green_fee + tax === 0 ||
                  moment(teeSheetInfo?.teetime).format('YYYY-MM-DD') !==
                     moment().format('YYYY-MM-DD')
               }
               variant='contained'
            >
               Book and Pay
            </LoadingButton>
         </DialogActions>
      </Dialog>
   );
};

export default BookingDialog;
