import axios from "axios";
import { useQuery } from "react-query";

const fetchLeagues = async () => {
   const { data } = await axios.post(
      process.env.REACT_APP_API_SERVER + `/api/league/all`
   );   return data.result;
};

export function useLeagues() {
   return useQuery("leagues", () => fetchLeagues());
}
