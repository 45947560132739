import {
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   FormControl,
   FormControlLabel,
   FormLabel,
   Grid,
   Radio,
   RadioGroup,
   Button,
   InputLabel,
   OutlinedInput,
   InputAdornment,
   TextField,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import React, { useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { formatMoney } from "../services/utils";
import moment from "moment";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useStateContext } from "../contexts/ContextProvider";
import { useAuth } from "../contexts/AuthProvider";
import { useAddLeagueTransaction } from "../services/hooks/useAddLeagueTransaction";
import { useUpdateLeaguePlayerPaymentStatus } from "../services/hooks/useUpdateLeaguePlayerPaymentStatus";
import { useUpdateLeagueScheduleCashPoolBalance } from "../services/hooks/useUpdateLeagueScheduleCashPoolBalance";

const LeaguePlayerPaymentDialog = ({
   open,
   onClose,
   leagueScheduleDetail,
   leagueScheduleId,
}) => {
   const { currentPos } = useStateContext();
   const { isAddingTransaction, addLeagueTransaction } =
      useAddLeagueTransaction();
   const { isUpdating, updateLeaguePlayerPaymentStatus } =
      useUpdateLeaguePlayerPaymentStatus();
   const { updateLeagueScheduleCashPoolBalance } =
      useUpdateLeagueScheduleCashPoolBalance();
   const socketUrl = `ws://${currentPos}:13000`;
   const serviceFeeLabel = "Tax and Fee";
   const taxFee = 2.0;
   const { userInfo } = useAuth();
   const [isProcessingCredit, setIsProcessingCredit] = useState(false);
   const [isProcessingCash, setIsProcessingCash] = useState(false);
   const [isPaid, setIsPaid] = useState(false);

   async function processMessage(msg) {
      console.log(msg);
      //check if msg start with "charge"
      if (msg.startsWith("ChargeanywhereTransactionResult")) {
         //substring from 0 to index
         msg = msg.substring(msg.indexOf("?") + 1);
         //split msg into array
         let msgArray = msg.split("&");
         //convert array to json
         let msgJson = {};
         msgArray.forEach((item) => {
            let itemArray = item.split("=");
            msgJson[itemArray[0]] = itemArray[1];
         });
         msgJson.userId = userInfo.id;
         msgJson.leagueScheduleDetailId = leagueScheduleDetail.id;

         let result = await addLeagueTransaction(msgJson);
         console.log(result);
         if (msgJson.TransactionResult === "APPROVED") {
            setIsPaid(true);
            await updateLeaguePlayerPaymentStatus({
               id: leagueScheduleDetail.id,
               payment_status: 1,
               transaction_id: result.id,
            });
            await updateLeagueScheduleCashPoolBalance({
               league_schedule_id: leagueScheduleId,
            });
            //close the dialog
            onClose();
         } else {
            alert("Payment failed");
            // console.log("Payment failed");
            // snackbar.error("Payment failed");
         }

         setIsProcessingCredit(false);
         setIsProcessingCash(false);
      }
   }
   const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
      onOpen: () => console.log("opened"),
      onMessage: (message) => processMessage(message.data),
      //Will attempt to reconnect on all close events, such as server shutting down
      shouldReconnect: (closeEvent) => true,
   });
   const connectionStatus = {
      [ReadyState.CONNECTING]: "Connecting",
      [ReadyState.OPEN]: "Open",
      [ReadyState.CLOSING]: "Closing",
      [ReadyState.CLOSED]: "Closed",
      [ReadyState.UNINSTANTIATED]: "Uninstantiated",
   }[readyState];

   const onProcessCreditPayment = async () => {
      try {
         if (connectionStatus === "Open") {
            setIsProcessingCredit(true);
            let subTotal = leagueScheduleDetail.price - taxFee;
            let msg = `ChargeanywhereProcessTransaction://?version=1.0&transactionType=1&tenderType=2&saleAmount=${subTotal}&serviceFeeLabel=${serviceFeeLabel}&serviceFeeAmount=${taxFee}&clerkNumber=${userInfo.id}&invoiceNumber=${leagueScheduleDetail.id}`;

            sendMessage(msg);
         } else {
            alert("POS is not connected");
         }
      } catch (err) {
         console.log(err);
      }
   };

   const onProcessCashPayment = async () => {
      try {
         if (connectionStatus === "Open") {
            setIsProcessingCash(true);
            let subTotal = leagueScheduleDetail.price - taxFee;
            let msg = `ChargeanywhereProcessTransaction://?version=1.0&transactionType=1&tenderType=5&saleAmount=${subTotal}&serviceFeeLabel=${serviceFeeLabel}&serviceFeeAmount=${taxFee}&clerkNumber=${userInfo.id}&invoiceNumber=${leagueScheduleDetail.id}`;

            sendMessage(msg);
         } else {
            alert("POS is not connected");
         }
      } catch (err) {
         console.log(err);
      }
   };

   return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
         <DialogTitle>League Player Payment</DialogTitle>
         <DialogContent>
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
               <Grid item xs={3}>
                  League:
               </Grid>
               <Grid item xs={9}>
                  {leagueScheduleDetail.league_name}
               </Grid>
               <Grid item xs={3}>
                  Date:
               </Grid>
               <Grid item xs={9}>
                  {moment(leagueScheduleDetail.league_date).format(
                     "MM/DD/YYYY"
                  )}
               </Grid>
               <Grid item xs={3}>
                  First Name:
               </Grid>
               <Grid item xs={9}>
                  {leagueScheduleDetail.first_name}
               </Grid>
               <Grid item xs={3}>
                  Last Name:
               </Grid>
               <Grid item xs={9}>
                  {leagueScheduleDetail.last_name}
               </Grid>
               <Grid item xs={3}>
                  Phone:
               </Grid>
               <Grid item xs={9}>
                  {leagueScheduleDetail.mobile_phone}
               </Grid>
               <Grid item xs={3}>
                  Email:
               </Grid>
               <Grid item xs={9}>
                  {leagueScheduleDetail.email}
               </Grid>
               <Grid item xs={3}>
                  Price:
               </Grid>
               <Grid item xs={9}>
                  {formatMoney(leagueScheduleDetail.price)}
               </Grid>
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button color="primary" onClick={onClose}>
               Cancel
            </Button>
            <LoadingButton
               variant="contained"
               disabled={
                  isPaid ||
                  leagueScheduleDetail.payment_status === 1 ||
                  readyState !== ReadyState.OPEN
               }
               loading={isProcessingCredit}
               loadingPosition="start"
               startIcon={<CreditCardIcon />}
               onClick={onProcessCreditPayment}
            >
               Credit
            </LoadingButton>
            <LoadingButton
               variant="contained"
               disabled={
                  isPaid ||
                  leagueScheduleDetail.payment_status === 1 ||
                  readyState !== ReadyState.OPEN
               }
               loading={isProcessingCash}
               loadingPosition="start"
               startIcon={<AttachMoneyIcon />}
               onClick={onProcessCashPayment}
            >
               Cash
            </LoadingButton>
         </DialogActions>
      </Dialog>
   );
};

export default LeaguePlayerPaymentDialog;
