import React, { createContext, useContext, useEffect, useState } from "react";

const StateContext = createContext();

const initialState = {
   chat: false,
   cart: false,
   userProfile: false,
   notification: false,
};

export const ContextProvider = ({ children }) => {
   const [screenSize, setScreenSize] = useState(undefined);
   const [currentColor, setCurrentColor] = useState("#03C9D7");
   const [currentMode, setCurrentMode] = useState("Light");
   const [themeSettings, setThemeSettings] = useState(false);
   const [activeMenu, setActiveMenu] = useState(true);
   const [isClicked, setIsClicked] = useState(initialState);
   const [currentPos, setCurrentPos] = useState("192.168.1.140");

   const setMode = (e) => {
      setCurrentMode(e.target.value);
      localStorage.setItem("themeMode", e.target.value);
   };

   const setColor = (color) => {
      setCurrentColor(color);
      localStorage.setItem("colorMode", color);
   };

   const setPos = (pos) => {
      setCurrentPos(pos);
      localStorage.setItem("pos", pos);
   };
   const hideCart = () => {
      setIsClicked({ ...initialState, cart: false });
   };

   const handleClick = (clicked) => {
      setIsClicked({ ...initialState, [clicked]: true });
   };

   useEffect(() => {
      if (localStorage.getItem("themeMode")) {
         setCurrentMode(localStorage.getItem("themeMode"));
      }
      if (localStorage.getItem("colorMode")) {
         setCurrentColor(localStorage.getItem("colorMode"));
      }
      if (localStorage.getItem("pos")) {
         setCurrentPos(localStorage.getItem("pos"));
      }
   }, []);

   return (
      <StateContext.Provider
         value={{
            currentColor,
            currentMode,
            activeMenu,
            screenSize,
            setScreenSize,
            handleClick,
            isClicked,
            initialState,
            setIsClicked,
            setActiveMenu,
            setCurrentColor,
            setCurrentMode,
            setMode,
            setColor,
            themeSettings,
            setThemeSettings,
            currentPos,
            setPos,
            setCurrentPos,
            hideCart,
         }}
      >
         {children}
      </StateContext.Provider>
   );
};

export const useStateContext = () => useContext(StateContext);
