import React, { useRef } from "react";
import {
   GridComponent,
   ColumnsDirective,
   ColumnDirective,
   Inject,
   Toolbar,
   Edit,
   Page,
   Sort,
   rowSelected,
   actionComplete,
} from "@syncfusion/ej2-react-grids";
import { DataManager, UrlAdaptor } from "@syncfusion/ej2-data";

const Leagues = () => {
   const gridRef = useRef(null);
   const toolbarOptions = ["Add", "Edit", "Update", "Cancel"];
   const editSettings = {
      allowEditing: true,
      allowAdding: true,
      allowDeleting: false,
      newRowPosition: "Top",
   };

   const dataManager = new DataManager({
      adaptor: new UrlAdaptor(),
      updateUrl: process.env.REACT_APP_API_SERVER + "/api/league/update",
      insertUrl: process.env.REACT_APP_API_SERVER + "/api/league/insert",
      url: process.env.REACT_APP_API_SERVER + `/api/league/all`,
   });
   return (
      <div>
         <div className="mt-5">
            <GridComponent
               dataSource={dataManager}
               editSettings={editSettings}
               toolbar={toolbarOptions}
               allowPaging={true}
               ref={gridRef}
            >
               <ColumnsDirective>
                  <ColumnDirective
                     field="id"
                     headerText="ID"
                     width="60"
                     allowEditing={false}
                     isPrimaryKey={true}
                  />
                  <ColumnDirective
                     field="name"
                     headerText="Name"
                     width="200"
                     allowEditing={true}
                  />
                  <ColumnDirective
                     field="price"
                     headerText="Teetime Price"
                     width="100"
                     allowEditing={true}
                     format="C2"
                  />
                  <ColumnDirective
                     field="cash_pool"
                     headerText="Cash Pool"
                     width="100"
                     allowEditing={true}
                     format="C2"
                  />
               </ColumnsDirective>
               <Inject services={[Page, Toolbar, Edit]} />
            </GridComponent>
         </div>
      </div>
   );
};

export default Leagues;
