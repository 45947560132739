import React, { useEffect, useRef, useState } from "react";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import {
   GridComponent,
   ColumnsDirective,
   ColumnDirective,
   Inject,
   Page,
   ContextMenu,
   Selection,
} from "@syncfusion/ej2-react-grids";
import axios from "axios";

import moment from "moment";
import { Header } from "../components";
import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import Button from "@mui/material/Button";
import QuickBookingDialog from "../components/QuickBookingDialog";
import { useMembers } from "../services/hooks/useMembers";
import { useMemberTypes } from "../services/hooks/useMemberTypes";
import { useSnackbar } from "../contexts/SnackbarProvider";
import { useBookTeetime } from "../services/hooks/useBookTeetime";
import { useUpdateTeetime } from "../services/hooks/useUpdateTeetime";
import { useDeleteReservation } from "../services/hooks/useDeleteReservation";
import { useQueryClient } from "react-query";
import { useBookSingleTeetime } from "../services/hooks/useBookSingleTeetime";
import { useCartContext } from "../contexts/CartProvider";
import { useBookMultipleTeetime } from "../services/hooks/useBookMultipleTeetime";
import { formatMoney } from "../services/utils";
import BookingDialog from "../components/BookingDialog";
import { useTeeSheetInfo } from "../services/hooks/useTeeSheetInfo";
import PaymentIcon from "@mui/icons-material/Payment";
import { useTeeSheetTableData } from "../services/hooks/useTeeSheetTableData";
import LockIcon from "@mui/icons-material/Lock";
import { green, red } from "@mui/material/colors";
import { useIsTeetimeLocked } from "../services/hooks/useIsTeetimeLocked";

const Booking = () => {
   const snackbar = useSnackbar();
   const queryClient = useQueryClient();
   const [selectedDate, setSelectedDate] = useState(
      moment().format("YYYY-MM-DD")
   );
   const [data, setData] = useState([]);
   const [teesheet_id, setTeesheet_id] = useState(1);
   const [sequence, setSequence] = useState(1);
   const [selectedValues, setSelectedValues] = useState([]);
   const [firstTeesheetId, setFirstTeesheetId] = useState(1);
   const [firstSequence, setFirstSequence] = useState(1);

   const [open, setOpen] = useState(false);
   const [bookingOpen, setBookingOpen] = useState(false);
   const { isLoading: isLoadingMembers, data: members } = useMembers();
   const { data: memberTypes } = useMemberTypes();
   const { isBooking, bookTeetime } = useBookTeetime();
   const { bookMultipleTeetime } = useBookMultipleTeetime();
   const { isBookingSingle, bookSingleTeetime } = useBookSingleTeetime();
   const { isUpdating, updateTeetime } = useUpdateTeetime();
   const { isDeleting, deleteReservation } = useDeleteReservation();
   const { addToCart, addTeetimesToCart } = useCartContext();
   const { data: tData } = useTeeSheetTableData(
      moment(selectedDate).format("YYYY-MM-DD")
   );
   const { isChecking, isTeetimeLocked } = useIsTeetimeLocked();

   const gridRef = useRef(null);

   const getInitPage = (time1, time2) => {
      var minutes = moment
         .duration(moment(time2, "h:mm a").diff(moment(time1, "h:mm a")))
         .asMinutes();
      var page = Math.floor(minutes / 120) + 1;
      return page;
   };

   const onChange = (args) => {
      setSelectedDate(moment(args.value).format("YYYY-MM-DD"));
   };
   const handleBookAndPay = async ({ data, teeSheetInfo }) => {
      bookSingleTeetime(data)
         .then(() => {
            addToCart({
               id: teeSheetInfo.teesheet_players_id,
               price: Number(data[0].green_fee) + Number(data[0].cart_fee),
               image: "golf-item.png",
               is_teetime: true,
               quantity: 1,
               first_name: data[0].first_name,
               last_name: data[0].last_name,
               mobile_phone: data[0].mobile_phone,
               email: data[0].email,
               teetime: teeSheetInfo.teetime,
               roundType: data[0].roundType ? "9 holes" : "18 holes",
               transportation: data[0].transportation ? "Walking" : "Riding",
            });
            snackbar.success("Tee time has been added successfully");
            handleClose();
         })
         .catch((err) =>
            snackbar.error(
               "Something went wrong! If the problem persists, contact us!"
            )
         );
   };

   function getPlayerStatusContent(status, customer) {
      return (
         <div>
            {customer !== "" ? (
               <div className="customer">{customer}</div>
            ) : null}
            <div>
               {status === "unpaid" ? (
                  <span id="status" style={{ color: "red" }}>
                     {status}
                  </span>
               ) : null}
               {status === "paid" ? (
                  <span id="status" style={{ color: "green" }}>
                     {status}
                  </span>
               ) : null}
               {status === "available" ? (
                  <span id="status" style={{ color: "black" }}>
                     {status}
                  </span>
               ) : null}
               {status === "locked" ? (
                  <LockIcon style={{ color: red[500] }} fontSize="small" />
               ) : null}
            </div>
         </div>
      );
   }
   function player1Template(props) {
      var status = "available";
      var customer = "";
      if (props.player1_status === 1) {
         if (props.player1_payment_status === 0) {
            status = "unpaid";
         } else if (props.player1_payment_status === 1) {
            status = "paid";
         }
         customer =
            props.player1_first_name +
            " " +
            props.player1_last_name +
            "(" +
            formatMoney(props.player1_total) +
            ")";
      } else if (
         props.player1_reserved_by !== null &&
         props.player1_reserved_ts !== null &&
         moment(props.player1_reserved_ts).add(15, "minutes") >= moment()
      ) {
         status = "locked";
         customer = "";
      }

      return getPlayerStatusContent(status, customer);
   }

   function player2Template(props) {
      var status = "available";
      var customer = "";
      if (props.player2_status === 1) {
         if (props.player2_payment_status === 0) {
            status = "unpaid";
         } else if (props.player2_payment_status === 1) {
            status = "paid";
         }
         customer =
            props.player2_first_name +
            " " +
            props.player2_last_name +
            "(" +
            formatMoney(props.player2_total) +
            ")";
      } else if (
         props.player2_reserved_by !== null &&
         props.player2_reserved_ts !== null &&
         moment(props.player2_reserved_ts).add(15, "minutes") >= moment()
      ) {
         status = "locked";
         customer = "";
      }

      return getPlayerStatusContent(status, customer);
   }
   function player3Template(props) {
      var status = "available";
      var customer = "";
      if (props.player3_status === 1) {
         if (props.player3_payment_status === 0) {
            status = "unpaid";
         } else if (props.player3_payment_status === 1) {
            status = "paid";
         }
         customer =
            props.player3_first_name +
            " " +
            props.player3_last_name +
            "(" +
            formatMoney(props.player3_total) +
            ")";
      } else if (
         props.player3_reserved_by !== null &&
         props.player3_reserved_ts !== null &&
         moment(props.player3_reserved_ts).add(15, "minutes") >= moment()
      ) {
         status = "locked";
         customer = "";
      }

      return getPlayerStatusContent(status, customer);
   }
   function player4Template(props) {
      var status = "available";
      var customer = "";
      if (props.player4_status === 1) {
         if (props.player4_payment_status === 0) {
            status = "unpaid";
         } else if (props.player4_payment_status === 1) {
            status = "paid";
         }
         customer =
            props.player4_first_name +
            " " +
            props.player4_last_name +
            "(" +
            formatMoney(props.player4_total) +
            ")";
      } else if (
         props.player4_reserved_by !== null &&
         props.player4_reserved_ts !== null &&
         moment(props.player4_reserved_ts).add(15, "minutes") >= moment()
      ) {
         status = "locked";
         customer = "";
      }

      return getPlayerStatusContent(status, customer);
   }

   function handleQuickBooking() {
      var selectedRowCellIndexs = gridRef.current.getSelectedRowCellIndexes();
      var selectedCellValues = [];

      // get the current view data
      var currentViewData = gridRef.current.getCurrentViewRecords();
      // get the currently selected cell values

      selectedRowCellIndexs.map((item) => {
         var rowData = {};
         item.cellIndexes.map((x) => {
            var col = gridRef.current.getColumnByIndex(x);
            if (col.visible) {
               rowData[col.field] =
                  currentViewData[item.rowIndex]["teesheet_id"];
            }
         });

         selectedCellValues.push(rowData);
      });
      if (selectedCellValues.length > 0) {
         setOpen(true);
         setSelectedValues(selectedCellValues);
         //first teesheet id
         setFirstTeesheetId(
            selectedCellValues[0][Object.keys(selectedCellValues[0])[0]]
         );
         //first selected sequence
         setFirstSequence(
            Object.keys(selectedCellValues[0])[0].replace("player", "")
         );
      } else {
         snackbar.error("Please select at least one tee time");
      }
   }
   const handleClose = async () => {
      setOpen(false);
      gridRef.current.clearSelection();
      queryClient.invalidateQueries(["tee-sheet"]);
   };
   function handleBookingClose() {
      setBookingOpen(false);
      gridRef.current.clearSelection();
      queryClient.invalidateQueries(["tee-sheet"]);
   }
   const handleAddTeetime = async (values) => {
      bookMultipleTeetime(values)
         .then(() => {
            snackbar.success("Tee time has been added successfully");
            handleClose();
         })
         .catch((err) =>
            snackbar.error(
               "Something went wrong! If the problem persists, contact us!"
            )
         );
   };

   const handleRowDataBound = (args) => {
      if (args.row) {
         if (args.data.teesheet_status === 1) {
            args.row.classList.add("blocked-teetime");
         }
      }
   };
   const handleDoubleClick = (args) => {
      if (args.cellIndex > 0) {
         //check if tee time is locked
         isTeetimeLocked({
            teesheet_id: args.rowData.teesheet_id,
            sequence: args.cellIndex,
         })
            .then((data) => {
               if (!data) {
                  setTeesheet_id(args.rowData.teesheet_id);
                  setSequence(args.cellIndex);
                  setBookingOpen(true);
               } else {
                  snackbar.error(
                     "Tee time is locked. Please select another time"
                  );
               }
            })
            .catch((err) => {
               snackbar.error(err.message);
            });
      }
   };

   const handleBookingUpdateTeetime = async (values) => {
      updateTeetime(values)
         .then(() => {
            snackbar.success("Tee time has been updated successfully");
            handleBookingClose();
         })
         .catch((err) =>
            snackbar.error(
               "Something went wrong! If the problem persists, contact us!"
            )
         );
   };
   const handleBookingDeleteTeetime = () => {
      deleteReservation({ teesheet_id, sequence })
         .then(() => {
            snackbar.success(
               "Tee time reservation has been deleted successfully"
            );
            handleBookingClose();
         })
         .catch((err) => {
            snackbar.error(
               "Something went wrong! If the problem persists, contact us!"
            );
         });
   };
   const handleBookingBookAndPay = async ({ data, teeSheetInfo }) => {
      bookSingleTeetime(data)
         .then(() => {
            addToCart({
               id: teeSheetInfo.teesheet_players_id,
               price: Number(data[0].green_fee) + Number(data[0].cart_fee),
               tax: data[0].tax,
               image: "golf-item.png",
               is_teetime: true,
               quantity: 1,
               first_name: data[0].first_name,
               last_name: data[0].last_name,
               mobile_phone: data[0].mobile_phone,
               email: data[0].email,
               teetime: teeSheetInfo.teetime,
               roundType: data[0].roundType ? "9 holes" : "18 holes",
               transportation: data[0].transportation ? "Walking" : "Riding",
            });
            snackbar.success("Tee time has been added successfully");
            handleBookingClose();
         })
         .catch((err) =>
            snackbar.error(
               "Something went wrong! If the problem persists, contact us!"
            )
         );
   };

   function addTeetimeToCart(values) {
      axios
         .post(
            process.env.REACT_APP_API_SERVER + `/api/teetimes/multiple`,
            values
         )
         .then((res) => {
            let items = [];
            res.data.map((teesheetInfo) => {
               if (teesheetInfo.teesheet_players_status > 0) {
                  items.push({
                     id: teesheetInfo.teesheet_players_id,
                     price:
                        Number(teesheetInfo.green_fee) +
                        Number(teesheetInfo.cart_fee),
                     tax: teesheetInfo.tax,
                     image: "golf-item.png",
                     is_teetime: true,
                     quantity: 1,
                     first_name: teesheetInfo.first_name,
                     last_name: teesheetInfo.last_name,
                     mobile_phone: teesheetInfo.mobile_phone,
                     email: teesheetInfo.email,
                     teetime: teesheetInfo.teetime,
                     roundType: teesheetInfo.roundType ? "9 holes" : "18 holes",
                     transportation: teesheetInfo.transportation
                        ? "Walking"
                        : "Riding",
                  });
               }
            });
            addTeetimesToCart(items);
         })
         .catch((err) => {
            console.log(err);
         });
   }
   function handleQuickPay() {
      var selectedRowCellIndexs = gridRef.current.getSelectedRowCellIndexes();
      var selectedCellValues = [];

      // get the current view data
      var currentViewData = gridRef.current.getCurrentViewRecords();
      // get the currently selected cell values

      selectedRowCellIndexs.map((item) => {
         var rowData = {};
         item.cellIndexes.map((x) => {
            var col = gridRef.current.getColumnByIndex(x);
            if (col.visible) {
               rowData[col.field] =
                  currentViewData[item.rowIndex]["teesheet_id"];
            }
         });

         selectedCellValues.push(rowData);
      });
      let dataArray = [];
      //convert data to array
      selectedCellValues.map(function (item) {
         Object.keys(item).map(function (key) {
            dataArray.push([item[key], parseInt(key.replace("player", ""))]);
         });
      });
      addTeetimeToCart(dataArray);
   }
   return (
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
         <Header title="Tee sheet" />
         <div className="m-3 p-3 flex flex-row gap-4">
            <div className="m-2">
               <Button
                  variant="contained"
                  onClick={handleQuickBooking}
                  endIcon={<BorderColorIcon />}
               >
                  Quick Booking
               </Button>
            </div>
            <div className="m-2">
               <Button
                  variant="contained"
                  color="info"
                  onClick={handleQuickPay}
                  endIcon={<PaymentIcon />}
               >
                  Quick Pay
               </Button>
            </div>
         </div>

         <div className="flex justify-between items-start gap-3">
            <GridComponent
               id="Grid"
               dataSource={tData}
               ref={gridRef}
               allowPaging={true}
               pageSettings={{ pageSize: 10 }}
               enableHover={false}
               selectionSettings={{
                  cellSelectionMode: "Box",
                  mode: "Cell",
                  type: "Multiple",
               }}
               rowDataBound={(e) => handleRowDataBound(e)}
               recordDoubleClick={handleDoubleClick}
            >
               <ColumnsDirective>
                  <ColumnDirective
                     field="teetime"
                     headerText="Time"
                     width="150"
                  />
                  <ColumnDirective
                     field="player1"
                     headerText="Player 1"
                     width="100"
                     template={player1Template}
                  />
                  <ColumnDirective
                     field="player2"
                     headerText="Player 2"
                     width="100"
                     template={player2Template}
                  />
                  <ColumnDirective
                     field="player3"
                     headerText="Player 3"
                     width="100"
                     template={player3Template}
                  />
                  <ColumnDirective
                     field="player4"
                     headerText="Player 4"
                     width="100"
                     template={player4Template}
                  />
               </ColumnsDirective>
               <Inject services={[Page, ContextMenu, Selection]} />
            </GridComponent>
            <CalendarComponent change={onChange}></CalendarComponent>
         </div>
         {open && (
            <QuickBookingDialog
               open={open}
               onClose={handleClose}
               onAdd={handleAddTeetime}
               onBookAndPay={handleBookAndPay}
               teesheet_id={firstTeesheetId}
               sequence={firstSequence}
               selectedValues={selectedValues}
            />
         )}
         {bookingOpen && (
            <BookingDialog
               open={bookingOpen}
               onClose={handleBookingClose}
               onDelete={handleBookingDeleteTeetime}
               onUpdate={handleBookingUpdateTeetime}
               onBookAndPay={handleBookingBookAndPay}
               sequence={sequence}
               teesheet_id={teesheet_id}
               members={members}
               memberTypes={memberTypes}
            ></BookingDialog>
         )}
      </div>
   );
};

export default Booking;
