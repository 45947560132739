import React from "react";
import { Header } from "../components";
import { usePricingRuleDetail } from "../services/hooks/usePricingRuleDetail";

import { DataManager, UrlAdaptor } from "@syncfusion/ej2-data";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { usePricingRules } from "../services/hooks/usePricingRules";
import { useMemberTypes } from "../services/hooks/useMemberTypes";

import {
   GridComponent,
   ColumnsDirective,
   ColumnDirective,
   Inject,
   Toolbar,
   Edit,
} from "@syncfusion/ej2-react-grids";

const PricingSheet = () => {
   const { data: pricingRules } = usePricingRules();
   const { data: memberTypes } = useMemberTypes();
   const [pricingRuleId, setPricingRuleId] = React.useState(1);
   const [memberTypeId, setMemberTypeId] = React.useState(1);

   const toolbarOptions = ["Edit", "Update", "Cancel"];
   const editSettings = {
      allowEditing: true,
      allowAdding: false,
      allowDeleting: false,
      newRowPosition: "Top",
   };
   const editparams = { params: { popupHeight: "300px" } };
   const validationRule = { required: true, number: true };

   const dataManager = new DataManager({
      adaptor: new UrlAdaptor(),
      updateUrl: process.env.REACT_APP_API_SERVER + "/api/pricing-rule",
      url:
         process.env.REACT_APP_API_SERVER +
         `/api/pricing-rule/details-syncfusion?member_type_id=${memberTypeId}&pricing_rule_id=${pricingRuleId}`,
   });
   const handlePricingRuleChange = (event) => {
      setPricingRuleId(event.target.value);
   };
   const handleMemberTypeChange = (event) => {
      setMemberTypeId(event.target.value);
   };

   return (
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
         <Header title="Pricing Sheet" />
         <div className="flex m-2 gap-4">
            <FormControl sx={{ width: "40%" }}>
               <InputLabel id="pricing-rule-select-label">
                  Pricing Rule
               </InputLabel>
               <Select
                  labelId="pricing-rule-select-label"
                  id="pricing-rule-select"
                  value={pricingRuleId}
                  label="Pricing Rule"
                  onChange={handlePricingRuleChange}
               >
                  {pricingRules?.map((pricingRule) => (
                     <MenuItem key={pricingRule.id} value={pricingRule.id}>
                        {pricingRule.name}
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>
            <FormControl sx={{ width: "20%" }}>
               <InputLabel id="member-type-select-label">
                  Member Type
               </InputLabel>
               <Select
                  labelId="member-type-select-label"
                  id="member-type-select"
                  value={memberTypeId}
                  label="Member Type"
                  onChange={handleMemberTypeChange}
               >
                  {memberTypes?.map((memberType) => (
                     <MenuItem key={memberType.id} value={memberType.id}>
                        {memberType.name}
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>
         </div>
         <div>
            <GridComponent
               dataSource={dataManager}
               editSettings={editSettings}
               toolbar={toolbarOptions}
            >
               <ColumnsDirective>
                  <ColumnDirective
                     field="pricing_rule_name"
                     headerText="Pricing Rule"
                     width="200"
                     allowEditing={false}
                  />
                  <ColumnDirective
                     field="member_type_name"
                     headerText="Member Type"
                     width="100"
                     allowEditing={false}
                  />
                  <ColumnDirective
                     field="time_period_name"
                     headerText="Period"
                     width="100"
                     allowEditing={false}
                  />
                  <ColumnDirective
                     field="green_fee_18"
                     headerText="Green Fee - 18"
                     width="100"
                     validationRules={validationRule}
                  />
                  <ColumnDirective
                     field="cart_fee_18"
                     headerText="Cart Fee - 18"
                     width="100"
                     validationRules={validationRule}
                  />
                  <ColumnDirective
                     field="green_fee_9"
                     headerText="Green Fee - 9"
                     width="100"
                     validationRules={validationRule}
                  />
                  <ColumnDirective
                     field="cart_fee_9"
                     headerText="Cart Fee - 9"
                     width="100"
                     validationRules={validationRule}
                  />
               </ColumnsDirective>
               <Inject services={[Toolbar, Edit]} />
            </GridComponent>
         </div>
      </div>
   );
};

export default PricingSheet;
