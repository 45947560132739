import axios from "axios";
import { useQuery } from "react-query";

const fetchMemberTypes = async () => {
   const { data } = await axios.get(
      process.env.REACT_APP_API_SERVER + "/api/member/membertypes"
   );
   return data;
};

export function useMemberTypes() {
   return useQuery("MemberTypes", () => fetchMemberTypes());
}
