import axios from "axios";
import { useMutation } from "react-query";

const updateLeagueScheduleCashPoolBalance = async (value) => {
   const { data } = await axios.post(
      process.env.REACT_APP_API_SERVER + `/api/league/schedule/cash_pool`,
      value
   );
   return data;
};

export function useUpdateLeagueScheduleCashPoolBalance() {
   const { isLoading, mutateAsync } = useMutation(
      updateLeagueScheduleCashPoolBalance
   );
   return {
      isUpdating: isLoading,
      updateLeagueScheduleCashPoolBalance: mutateAsync,
   };
}
