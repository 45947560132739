import axios from "axios";
import { useQuery } from "react-query";
import authHeader from "../authHeader";

const fetchUsers = async () => {
   const { data } = await axios.post(
      process.env.REACT_APP_API_SERVER + "/api/user/syncfusion",
      {},
      { headers: authHeader() }
   );
   return data.result;
};

export function useUsers() {
   return useQuery("users", () => fetchUsers());
}
