import authHeader from "../authHeader";
import axios from "axios";
import { useMutation } from "react-query";

const isTeetimeLocked = async (value) => {
   const { data } = await axios.post(
      process.env.REACT_APP_API_SERVER + "/api/teetimes/is_locked",
      value,
      { headers: authHeader() }
   );
   return data;
};

export function useIsTeetimeLocked() {
   const { isLoading, mutateAsync } = useMutation(isTeetimeLocked);
   return { isChecking: isLoading, isTeetimeLocked: mutateAsync };
}
