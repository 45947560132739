import axios from 'axios';
import { useQuery } from 'react-query';
import moment from 'moment';

const fetchTransactions = async (userId, transactionDate, transactionType) => {
   const { data } = await axios.get(
      process.env.REACT_APP_API_SERVER +
         `/api/transaction?userId=${userId}&transactionDate=${transactionDate}&transactionType=${transactionType}`
   );
   return data;
};

export function useTransactions(userId, transactionDate, transactionType) {
   let date = moment(transactionDate).format('MM/DD/YYYY');
   return useQuery(['Transactions', date, transactionType, userId], () =>
      fetchTransactions(userId, date, transactionType)
   );
}
