import React, { useRef, useState } from "react";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { useAuth } from "../contexts/AuthProvider";
import { useTransactions } from "../services/hooks/useTransactions";
import moment from "moment";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";

import {
   GridComponent,
   ColumnsDirective,
   ColumnDirective,
   Page,
   Inject,
   Toolbar,
   Sort,
   PdfExport,
   Aggregate,
   AggregateColumnsDirective,
   AggregateColumnDirective,
   AggregateDirective,
   AggregatesDirective,
   ExcelExport,
} from "@syncfusion/ej2-react-grids";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { Button } from "@mui/material";

const CreditSalesReport = () => {
   const { userInfo } = useAuth();
   const [dateValue, setDateValue] = useState(moment().format("YYYY-MM-DD"));
   const [transactionType, setTransactionType] = useState(2);

   const { data: transactions } = useTransactions(
      userInfo.id,
      dateValue,
      transactionType
   );

   const fields = { text: "Type", value: "Id" };
   const listData = [
      { Id: 5, Type: "Cash" },
      { Id: 2, Type: "Credit Card" },
   ];
   const toolbarOptions = ["PdfExport"];
   const gridRef = useRef(null);

   function formatMoney(number) {
      var num = number ? number : 0;
      return num.toLocaleString("en-US", {
         style: "currency",
         currency: "USD",
      });
   }
   function getRandomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
   }

   const handleDateChange = (e) => {
      setDateValue(moment(e.value).format("YYYY-MM-DD"));
   };
   function footerSum(props) {
      return <span>Sum: {props.Sum}</span>;
   }
   function footerAvg(props) {
      return <span>Average: {props.Average}</span>;
   }
   function getPdfExportProperties() {
      let reportType = transactionType === 2 ? "Credit Card" : "Cash";
      return {
         header: {
            fromTop: 0,
            height: 120,
            contents: [
               {
                  type: "Text",
                  value: "Sales Report",
                  position: { x: 280, y: 0 },
                  style: { textBrushColor: "#C25050", fontSize: 25 },
               },
               {
                  type: "Text",
                  value: reportType + "    " + dateValue,
                  position: { x: 280, y: 50 },
                  style: { textBrushColor: "#000000", fontSize: 15 },
               },
            ],
         },
         footer: {
            fromBottom: 160,
            height: 100,
            contents: [
               {
                  type: "Text",
                  value: "Richland Golf Club",
                  position: { x: 250, y: 20 },
                  style: { textBrushColor: "#C67878", fontSize: 14 },
               },
               {
                  type: "Text",
                  value: moment().format("MMMM Do YYYY h:mm:ss a"),
                  position: { x: 250, y: 40 },
                  style: { textBrushColor: "#C67878", fontSize: 14 },
               },
            ],
         },
         fileName: `${dateValue}-credit-${getRandomNumber(10000, 100000)}.pdf`,
      };
   }
   function toolbarClick(args) {
      if (args.item.text === "Print") {
         gridRef.current.print();
      }
      if (args.item.text === "Excel Export") {
         gridRef.current.excelExport();
      }
      if (args.item.text === "PDF Export") {
         gridRef.current.pdfExport(getPdfExportProperties());
      }
      if (args.item.text === "CSV Export") {
         gridRef.current.csvExport();
      }
   }
   return (
      <div>
         <div className="m-2 p-3 w-70 flex flex-row items-center justify-start">
            <p className="text-sm font-semibold m-5">Date:</p>
            <div className="w-70">
               <DatePickerComponent
                  value={dateValue}
                  onChange={handleDateChange}
               ></DatePickerComponent>
            </div>
         </div>
         <div className="flex flex-row items-center justify-between">
            <div className="text-lg mt-2">
               Total:{" "}
               {transactionType === 2 &&
                  formatMoney(
                     transactions?.reduce((acc, curr) => acc + curr.Amount, 0)
                  )}
               {transactionType === 5 &&
                  formatMoney(
                     transactions?.reduce(
                        (acc, curr) => acc + curr.authorizedAmount,
                        0
                     )
                  )}
            </div>
         </div>
         <div>
            <GridComponent
               dataSource={transactions}
               toolbar={toolbarOptions}
               allowPdfExport={true}
               allowExcelExport={true}
               allowCsvExport={true}
               toolbarClick={toolbarClick}
               ref={gridRef}
            >
               <ColumnsDirective>
                  <ColumnDirective field="orderId" headerText="Id" width="80" />
                  {transactionType === 2 && (
                     <ColumnDirective
                        field="Amount"
                        headerText="Amount"
                        width="100"
                        format="C2"
                     />
                  )}
                  {transactionType === 5 && (
                     <ColumnDirective
                        field="authorizedAmount"
                        headerText="Amount"
                        width="100"
                        format="C2"
                     />
                  )}

                  <ColumnDirective
                     field="discount"
                     headerText="Discount"
                     width="100"
                     format="C2"
                  />
                  <ColumnDirective
                     field="transaction_type_name"
                     headerText="Type"
                     width="100"
                  />
                  <ColumnDirective
                     field="transaction_time"
                     headerText="Date"
                     width="150"
                  />
                  <ColumnDirective field="name" headerText="By" width="150" />
               </ColumnsDirective>
               <AggregatesDirective>
                  <AggregateDirective>
                     <AggregateColumnsDirective>
                        <AggregateColumnDirective
                           field="Amount"
                           type="Sum"
                           format="C2"
                           footerTemplate={footerSum}
                        ></AggregateColumnDirective>
                     </AggregateColumnsDirective>
                  </AggregateDirective>
                  <AggregateDirective>
                     <AggregateColumnsDirective>
                        <AggregateColumnDirective
                           field="Amount"
                           type="Average"
                           format="C2"
                           footerTemplate={footerAvg}
                        ></AggregateColumnDirective>
                     </AggregateColumnsDirective>
                  </AggregateDirective>
               </AggregatesDirective>
               <Inject
                  services={[Toolbar, Aggregate, PdfExport, ExcelExport]}
               />
            </GridComponent>
         </div>
      </div>
   );
};

export default CreditSalesReport;
