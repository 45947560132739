import axios from "axios";
import { useQuery } from "react-query";

const fetchEventOrderItemsByDate = async (start, end) => {
   const { data } = await axios.get(
      process.env.REACT_APP_API_SERVER +
         `/api/event/orderItemsByDate?start=${start}&end=${end}`
   );

   return data;
};

export function useEventOrderItemsByDate(start, end) {
   return useQuery(["event_order_items-date", start, end], () =>
      fetchEventOrderItemsByDate(start, end)
   );
}
