import React from "react";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import moment from "moment";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { Button } from "@mui/material";

const EventReports = () => {
   const [startDate, setStartDate] = React.useState(
      moment().format("YYYY-MM-DD")
   );
   const [endDate, setEndDate] = React.useState(moment().format("YYYY-MM-DD"));
   function handleReport() {
      window.open(`/event-revenue-report/${startDate}&${endDate}`, "_blank");
   }
   return (
      <div className="mt-5 mb-5">
         <div className="flex flex-row items-center w-400">
            <div className="flex flex-row items-center">
               <span className="mr-5 text-lg">Start:</span>
               <DatePickerComponent
                  value={startDate}
                  onChange={(e) => {
                     setStartDate(moment(e.value).format("YYYY-MM-DD"));
                  }}
               ></DatePickerComponent>
            </div>
            <div className="flex flex-row items-center">
               <span className="mr-5 ml-10 text-lg">End:</span>
               <DatePickerComponent
                  value={endDate}
                  onChange={(e) => {
                     setEndDate(moment(e.value).format("YYYY-MM-DD"));
                  }}
               ></DatePickerComponent>
            </div>
         </div>
         <div className="mt-10">
            <Button
               variant="contained"
               endIcon={<AssessmentIcon />}
               onClick={handleReport}
            >
               Generate Report
            </Button>
         </div>
      </div>
   );
};

export default EventReports;
