import authHeader from "../authHeader";
import axios from "axios";
import { useMutation } from "react-query";

const addTransaction = async (transaction) => {
   const { data } = await axios.post(
      process.env.REACT_APP_API_SERVER + "/api/transaction",
      transaction,
      { headers: authHeader() }
   );
   return data;
};
export function useAddTransaction() {
   const { isLoading, mutateAsync } = useMutation(addTransaction);
   return { isAddingTransaction: isLoading, addTransaction: mutateAsync };
}
