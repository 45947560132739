import React, { useRef } from "react";
import {
   GridComponent,
   ColumnsDirective,
   ColumnDirective,
   Inject,
   Page,
} from "@syncfusion/ej2-react-grids";
import { DataManager, UrlAdaptor } from "@syncfusion/ej2-data";
import { Button } from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payment";
import EventPaymentDialog from "./EventPaymentDialog";
import { useEventById } from "../services/hooks/useEventById";
import { useEventTransactions } from "../services/hooks/useEventTransactions";
import { useQueryClient } from "react-query";

const EventPayments = () => {
   const gridRef = useRef(null);
   const queryClient = useQueryClient();
   const [selectedEventId, setSelectedEventId] = React.useState(0);
   const { data: eventData } = useEventById(selectedEventId);
   const { data: paymentHistory } = useEventTransactions(selectedEventId);

   const [open, setOpen] = React.useState(false);

   const dataManager = new DataManager({
      adaptor: new UrlAdaptor(),
      url: process.env.REACT_APP_API_SERVER + `/api/event`,
   });
   const eventIdRef = useRef(0);
   const eventNameRef = useRef("");
   function rowSelect(args) {
      if (args.name === "rowSelected") {
         eventIdRef.current = args.data.id;
         eventNameRef.current = args.data.event_name;
         setSelectedEventId(args.data.id);
      }
   }

   function handlePayment() {
      setOpen(true);
   }
   const handleClose = () => {
      queryClient.invalidateQueries(["event_transactions"]);
      setOpen(false);
   };

   return (
      <div>
         <div className="mt-5">
            <Button
               variant="contained"
               endIcon={<PaymentIcon />}
               onClick={handlePayment}
            >
               Pay
            </Button>
            <GridComponent
               dataSource={dataManager}
               allowPaging={true}
               rowSelected={rowSelect}
               ref={gridRef}
            >
               <ColumnsDirective>
                  <ColumnDirective
                     field="id"
                     headerText="ID"
                     width="60"
                     isPrimaryKey={true}
                  />
                  <ColumnDirective
                     field="event_date"
                     headerText="Date"
                     width="150"
                  />
                  <ColumnDirective
                     field="event_name"
                     headerText="Name"
                     width="200"
                     allowEditing={true}
                  />

                  <ColumnDirective
                     field="subtotal"
                     headerText="Subtotal"
                     width="140"
                     format={"c2"}
                  />
                  <ColumnDirective
                     field="taxes"
                     headerText="Taxes"
                     width="100"
                     format={"c2"}
                  />
                  <ColumnDirective
                     field="gratuity"
                     headerText="Gratuity"
                     width="100"
                     format={"c2"}
                  />
                  <ColumnDirective
                     field="total"
                     headerText="Total"
                     width="150"
                     format={"c2"}
                  />
                  <ColumnDirective
                     field="payment"
                     headerText="Payment"
                     width="150"
                     format={"c2"}
                  />

                  <ColumnDirective
                     field="balance"
                     headerText="Balance"
                     width="100"
                     format={"c2"}
                  />
               </ColumnsDirective>
               <Inject services={[Page]} />
            </GridComponent>
            {open && (
               <EventPaymentDialog
                  open={open}
                  onClose={handleClose}
                  event={eventData}
               />
            )}
         </div>

         <div className="mt-5">
            <span>Payment history</span>
            <GridComponent dataSource={paymentHistory}>
               <ColumnsDirective>
                  <ColumnDirective
                     field="id"
                     headerText="ID"
                     width="60"
                     isPrimaryKey={true}
                  />
                  <ColumnDirective
                     field="event_name"
                     headerText="Event"
                     width="150"
                  />
                  <ColumnDirective
                     field="amount"
                     headerText="Amount"
                     width="100"
                     format={"C2"}
                  />
                  <ColumnDirective
                     field="name"
                     headerText="Payment type"
                     width="100"
                  />
                  <ColumnDirective
                     field="notes"
                     headerText="Notes"
                     width="150"
                  />
                  <ColumnDirective
                     field="time"
                     headerText="Time"
                     width="150"
                     format={"datetime"}
                  />
               </ColumnsDirective>
            </GridComponent>
         </div>
      </div>
   );
};

export default EventPayments;
