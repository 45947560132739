import axios from "axios";
import { useQuery } from "react-query";
import moment from "moment";

const fetchTransactionsDaily = async (transactionDate, transactionType) => {
   const { data } = await axios.get(
      process.env.REACT_APP_API_SERVER +
         `/api/transaction/daily?date=${transactionDate}&transactionType=${transactionType}`
   );
   return data;
};

export function useTransactionsDaily(transactionDate, transactionType) {
   let date = moment(transactionDate).format("MM/DD/YYYY");
   return useQuery(["Transactions", date,transactionType], () =>
      fetchTransactionsDaily(date, transactionType)
   );
}
