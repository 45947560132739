import authHeader from "../authHeader";
import axios from "axios";
import { useMutation } from "react-query";

const addCartItems = async (items) => {
   const { data } = await axios.post(
      process.env.REACT_APP_API_SERVER + "/api/order",
      items,
      { headers: authHeader() }
   );
   return data;
};

export function useAddCartItems() {
   const { isLoading, mutateAsync } = useMutation(addCartItems);
   return { isAdding: isLoading, addCartItems: mutateAsync };
}
