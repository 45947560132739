import React, { useRef } from "react";
import {
   GridComponent,
   ColumnsDirective,
   ColumnDirective,
   Inject,
   Toolbar,
   Edit,
   Page,
   Sort,
   rowSelected,
   actionComplete,
} from "@syncfusion/ej2-react-grids";
import { DataManager, UrlAdaptor } from "@syncfusion/ej2-data";
import LeagueScheduleDetail from "./LeagueScheduleDetail";
import LeaguePlayerPaymentDialog from "./LeaguePlayerPaymentDialog";
import LeagueCashPoolPaymentDialog from "./LeagueCashPoolPaymentDialog";
import { useAddLeagueCashPoolPayment } from "../services/hooks/useAddLeagueCashPoolPayment";
import { useSnackbar } from "../contexts/SnackbarProvider";
import { useAuth } from "../contexts/AuthProvider";
import { useLeagueSchedules } from "../services/hooks/useLeagueSchedules";
import { useQueryClient } from "react-query";

const LeagueSchedules = () => {
   const snackbar = useSnackbar();
   const queryClient = useQueryClient();
   const { userInfo } = useAuth();
   const { isAddingPayment, addLeagueCashPoolPayment } =
      useAddLeagueCashPoolPayment();
   const [selectedLeagueScheduleId, setSelectedLeagueScheduleId] =
      React.useState(null);
   const [selectedLeagueSchedule, setSelectedLeagueSchedule] =
      React.useState(null);

   const { data: leagueSchedules } = useLeagueSchedules();

   const [isPaymentOpen, setIsPaymentOpen] = React.useState(false);

   const dataManager = new DataManager({
      adaptor: new UrlAdaptor(),
      url: process.env.REACT_APP_API_SERVER + `/api/league/schedule/all`,
   });
   const gridRef = useRef(null);
   const leagueIdRef = useRef(0);

   const toolbarOptions = ["Edit", "Update", "Cancel"];
   const editSettings = {
      allowEditing: false,
      allowAdding: false,
      allowDeleting: false,
      newRowPosition: "Top",
   };

   const handleDoubleClick = (args) => {
      if (args.cellIndex > 0) {
         setSelectedLeagueScheduleId(args.rowData.id);
         setSelectedLeagueSchedule(args.rowData);
         setIsPaymentOpen(true);
      }
   };

   function paymentTemplate(props) {
      if (props.cash_pool_balance > 0 && props.is_paid === 0) {
         return <span className="text-red-600">UnPaid</span>;
      } else return <span className="text-green-600">Paid</span>;
   }

   const handlePaymentClose = () => {
      queryClient.invalidateQueries("league_schedules");
      setIsPaymentOpen(false);
   };

   const handlePayment = () => {

      addLeagueCashPoolPayment({
         league_schedule_id: selectedLeagueScheduleId,
         amount: selectedLeagueSchedule.cash_pool_balance,
         user_id: userInfo.id,
      })
         .then(() => {
            snackbar.success("Payment added successfully");
            setIsPaymentOpen(false);
            gridRef.current.refresh();
         })
         .catch(() => {
            snackbar.error("Error adding payment");
         });
   };
   function refreshGrid() {}
   function dataStateChange(args) {
      refreshGrid();
   }
   function dataSourceChanged(state) {
      console.log(state);
   }

   return (
      <div>
         <div className="mt-5">
            <GridComponent
               dataSource={leagueSchedules}
               editSettings={editSettings}
               toolbar={toolbarOptions}
               recordDoubleClick={handleDoubleClick}
               allowPaging={true}
               dataSourceChanged={dataSourceChanged}
               dataStateChange={dataStateChange}
               ref={gridRef}
            >
               <ColumnsDirective>
                  <ColumnDirective
                     field="id"
                     headerText="ID"
                     width="60"
                     allowEditing={false}
                     isPrimaryKey={true}
                  />
                  <ColumnDirective
                     field="league_date"
                     headerText="Date"
                     width="150"
                     allowEditing={false}
                  />
                  <ColumnDirective
                     field="name"
                     headerText="Name"
                     width="150"
                     allowEditing={false}
                  />
                  <ColumnDirective
                     field="price"
                     headerText="Teetime Price"
                     width="100"
                     allowEditing={true}
                     format="C2"
                  />
                  <ColumnDirective
                     field="cash_pool"
                     headerText="Cash Pool"
                     width="100"
                     allowEditing={true}
                     format="C2"
                  />
                  <ColumnDirective
                     field="cash_pool_balance"
                     headerText="Cash Pool Balance"
                     width="100"
                     allowEditing={false}
                     format="C2"
                  />
                  <ColumnDirective
                     field="is_paid"
                     headerText="Status"
                     width="150"
                     allowEditing={false}
                     template={paymentTemplate}
                  />
               </ColumnsDirective>
               <Inject services={[Page, Sort, Edit]} />
            </GridComponent>
         </div>
         {isPaymentOpen && (
            <LeagueCashPoolPaymentDialog
               open={isPaymentOpen}
               onClose={handlePaymentClose}
               onPayment={handlePayment}
               leagueSchedule={selectedLeagueSchedule}
            />
         )}
      </div>
   );
};

export default LeagueSchedules;
