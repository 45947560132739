import React, { useState } from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { LoadingButton } from '@mui/lab';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AddTaskIcon from '@mui/icons-material/AddTask';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import Alert from '@mui/material/Alert';

import { MdOutlineCancel, MdOutlineRemove } from 'react-icons/md';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { RiDeleteBin6Line } from 'react-icons/ri';

import { useStateContext } from '../contexts/ContextProvider';

import { Button } from '.';
import { useCartContext } from '../contexts/CartProvider';
import { useAuth } from '../contexts/AuthProvider';
import { useAddCartItems } from '../services/hooks/useAddCartItems';
import { useAddTransaction } from '../services/hooks/useAddTransaction';
import { usePaymentStatus } from '../services/hooks/usePaymentStatus';
import { useQueryClient } from 'react-query';
import { useSnackbar } from '../contexts/SnackbarProvider';
import { formatMoney } from '../services/utils';
import { TextField } from '@mui/material';
import { processTransaction, queryTransaction } from '../services/dataService';
import { useUpdateTransactionResult } from '../services/hooks/useUpdateTransactionResult';
import { useCoupon } from '../services/hooks/useCoupon';
import { useRedeemCoupon } from '../services/hooks/useRedeemCoupon';
import Error from './Error';

const Cart = () => {
   const queryClient = new useQueryClient();
   const snackbar = useSnackbar();
   const { currentColor, currentPos, hideCart } = useStateContext();
   const { isAdding, addCartItems } = useAddCartItems();
   const { isAddingTransaction, addTransaction } = useAddTransaction();
   const { isUpdating, updatePaymentStatus } = usePaymentStatus();
   const { updateTransactionResult } = useUpdateTransactionResult();

   const { userInfo } = useAuth();

   const {
      setShowCart,
      cartItems,
      setCartItems,
      removeFromCart, //for tee time
      onRemove, //for products
      totalQuantities,
      totalPrice,
      setTotalPrice,
      taxFee,
      setTotalQuantities,
      toggleCartItemQuantity,
      resetCart,
      orderId,
      addOrderId,
      discount,
      addDiscount,
      orderBalance,
      addOrderBalance,
   } = useCartContext();

   const { redeemCoupon } = useRedeemCoupon();
   const [isProcessingCredit, setIsProcessingCredit] = useState(false);
   const [isProcessingCash, setIsProcessingCash] = useState(false);
   const [isPaid, setIsPaid] = useState(false);
   const [cashTotal, setCashTotal] = useState(0);
   const [creditTotal, setCreditTotal] = useState(0);
   const [isSplit, setIsSplit] = useState(false);
   const [uuid, setUuid] = useState(uuidv4());
   const [coupon, setCoupon] = useState('');
   const { isLoading: isLoadingCoupon, data: couponData } = useCoupon(coupon);
   const [couponError, setCouponError] = useState(false);
   const [errorMessage, setErrorMessage] = useState('');

   const serviceFeeLabel = 'Tax and Fee';

   // setTaxFee(serviceFee);
   let grandTotal = totalPrice + taxFee - discount;

   function updateCoupon() {
      if (coupon !== '' && discount > 0) {
         let teesheet_players_id = cartItems.filter(
            (item) => item.is_teetime
         )[0].id;
         redeemCoupon({ coupon, teesheet_players_id });
      }
   }

   const onProcessCreditPayment = async () => {
      try {
         setIsProcessingCredit(true);
         setErrorMessage('');
         let u = uuidv4();
         setUuid(u);

         if (orderId === 0 || (orderId !== 0 && !isSplit)) {
            //create order
            let orderInfo = await addCartItems({
               cartItems,
               taxFee,
               discount,
            });
            addOrderId(orderInfo.orderId);
            let data = {
               transactionType: 1,
               tenderType: 2,
               saleAmount: totalPrice - discount,
               serviceFeeLabel: 'Tax and Fee',
               serviceFeeAmount: taxFee,
               clerkNumber: userInfo.id,
               invoiceNumber: orderInfo.orderId,
               uniqueId: u,
            };
            processTransaction(data)
               .then((res) => {
                  console.log(res);

                  if (res.message === 'APPROVED') {
                     let balance =
                        orderBalance -
                        Number(res.data.AuthorizedAmount) -
                        discount;

                     addOrderBalance(balance);
                     if (balance <= 0.05) {
                        setIsProcessingCredit(false);
                        setIsPaid(true);
                        updatePaymentStatus({ cartItems, status: 1 });
                        updateCoupon();
                        resetCart();
                        queryClient.invalidateQueries(['tee-sheet']);
                        queryClient.invalidateQueries('current-tee-sheet');
                        hideCart();
                     }
                  } else if (res.message === 'TransactionCancelled') {
                     setErrorMessage('Transaction cancelled');
                     setIsProcessingCredit(false);
                  } else {
                     setErrorMessage('Transaction failed, please try again');
                     setIsProcessingCredit(false);
                  }
               })
               .catch((err) => {
                  console.log(err, '😀');
                  setErrorMessage('Transaction failed, please try again');
                  setIsProcessingCredit(false);
               });
         } else {
            // if split, do not charge tax and fee on cash payment, only on credit payment

            let amount = creditTotal - taxFee;
            let salesAmount = creditTotal - taxFee;
            let serviceFeeAmount = taxFee;
            if (amount <= 0) {
               salesAmount = creditTotal;
               serviceFeeAmount = 0;
            }
            let data = {
               transactionType: 1,
               tenderType: 2,
               saleAmount: salesAmount,
               serviceFeeLabel: 'Tax and Fee',
               serviceFeeAmount: serviceFeeAmount,
               clerkNumber: userInfo.id,
               invoiceNumber: orderId,
               uniqueId: u,
            };
            processTransaction(data)
               .then((res) => {
                  console.log(res);

                  if (res.message === 'APPROVED') {
                     let balance =
                        orderBalance -
                        Number(res.data.AuthorizedAmount) -
                        discount;

                     addOrderBalance(balance);
                     if (balance <= 0.05) {
                        setIsProcessingCredit(false);
                        setIsPaid(true);
                        updatePaymentStatus({ cartItems, status: 1 });
                        updateCoupon();
                        resetCart();
                        queryClient.invalidateQueries(['tee-sheet']);
                        queryClient.invalidateQueries('current-tee-sheet');
                        hideCart();
                     }
                  } else if (res.message === 'TransactionCancelled') {
                     setErrorMessage('Transaction cancelled');
                     setIsProcessingCredit(false);
                  } else {
                     setErrorMessage('Transaction failed, please try again');
                     setIsProcessingCredit(false);
                  }
               })
               .catch((err) => {
                  console.log(err, '😀');
                  setErrorMessage('Transaction failed, please try again');
                  setIsProcessingCredit(false);
               });
         }
      } catch (err) {
         console.log(err);
      }
   };

   const onProcessCashPayment = async () => {
      setIsProcessingCash(true);
      setErrorMessage('');
      let u = uuidv4();
      setUuid(u);
      if (orderId === 0 || (orderId !== 0 && !isSplit)) {
         //create order
         let orderInfo = await addCartItems({ cartItems, taxFee, discount });
         addOrderId(orderInfo.orderId);
         let data = {
            transactionType: 1,
            tenderType: 5,
            saleAmount: totalPrice - discount,
            serviceFeeLabel: 'Tax and Fee',
            serviceFeeAmount: taxFee,
            clerkNumber: userInfo.id,
            invoiceNumber: orderInfo.orderId,
            uniqueId: u,
         };
         processTransaction(data)
            .then((res) => {

               if (res.message === 'APPROVED') {
                  let balance =
                     orderBalance -
                     Number(res.data.AuthorizedAmount) -
                     discount;

                  addOrderBalance(balance);
                  if (balance <= 0.05) {
                     setIsProcessingCash(false);
                     setIsPaid(true);
                     updatePaymentStatus({ cartItems, status: 1 });
                     updateCoupon();
                     resetCart();
                     queryClient.invalidateQueries(['tee-sheet']);
                     queryClient.invalidateQueries('current-tee-sheet');
                     hideCart();
                  }
               } else if (res.message === 'TransactionCancelled') {
                  setErrorMessage('Transaction cancelled');
                  setIsProcessingCash(false);
               } else {
                  setErrorMessage('Transaction failed, please try again');
                  setIsProcessingCash(false);
               }
            })
            .catch((err) => {
               console.log(err, '😀');
               setErrorMessage('Transaction failed, please try again');
               setIsProcessingCash(false);
            });
      } else {
         // if split, do not charge tax and fee on cash payment, only on credit payment
         let u = uuidv4();
         let data = {
            transactionType: 1,
            tenderType: 5,
            saleAmount: cashTotal,
            serviceFeeLabel: 'Tax and Fee',
            serviceFeeAmount: 0,
            clerkNumber: userInfo.id,
            invoiceNumber: orderId,
            uniqueId: u,
         };
         processTransaction(data)
            .then((res) => {
               console.log(res);

               if (res.message === 'APPROVED') {
                  let balance =
                     orderBalance -
                     Number(res.data.AuthorizedAmount) -
                     discount;

                  addOrderBalance(balance);
                  setIsProcessingCash(false);
                  if (balance <= 0.05) {
                     setIsPaid(true);
                     updatePaymentStatus({ cartItems, status: 1 });
                     updateCoupon();
                     resetCart();
                     queryClient.invalidateQueries(['tee-sheet']);
                     queryClient.invalidateQueries('current-tee-sheet');
                     hideCart();
                  }
               } else if (res.message === 'TransactionCancelled') {
                  setErrorMessage('Transaction cancelled');
                  setIsProcessingCash(false);
               } else {
                  setErrorMessage('Transaction failed, please try again');
                  setIsProcessingCash(false);
               }
            })
            .catch((err) => {
               console.log(err, '😀');
               setErrorMessage('Transaction failed, please try again');
               setIsProcessingCash(false);
            });
      }
   };

   const handleRemove = (id) => {
      removeFromCart(id);
   };

   const handleRemoveProduct = (id) => {
      onRemove(id);
   };

   const onProcessSplitPayment = async () => {
      setIsSplit(true);
      if (orderId === 0) {
         //create order
         let orderInfo = await addCartItems({ cartItems, taxFee, discount });
         addOrderId(orderInfo.orderId);
         addOrderBalance(grandTotal);
      }
   };

   const applyCoupon = async () => {
      setCouponError(false);
      //check if coupon is valid
      if (couponData.length > 0) {
         // if (couponData[0].redeemed === 1) {
         //    setCouponError(true)
         // } else {
         if (cartItems.filter((item) => item.is_teetime).length > 0) {
            //only apply to tee time
            let discount = Math.ceil(
               0.2 * cartItems.filter((item) => item.is_teetime)[0].price
            );
            addDiscount(discount);
         } else {
            setCouponError(true);
         }
         // }
      } else {
         if (coupon !== '') {
            setCouponError(true);
         } else {
            setCouponError(false);
         }
      }
   };

   const handleCouponChange = (e) => {
      setCoupon(e.target.value);
      setCouponError(false);
   };

   const renderSplitPayment = () => {
      return (
         <div>
            <div className='flex justify-between items-center mt-3'>
               <p className='text-gray-500 dark:text-gray-200'>Balance:</p>
               <p className='font-semibold'>{formatMoney(orderBalance)}</p>
            </div>
            <div className='flex justify-between items-center mt-3'>
               <p className='text-gray-500 dark:text-gray-200'>Cash:</p>
               <input
                  type='number'
                  id='cash'
                  value={cashTotal}
                  onChange={handleCashChange}
                  className='w-20'
               />
            </div>
            <div className='flex justify-between items-center mt-3'>
               <p className='text-gray-500 dark:text-gray-200'>Credit:</p>
               <input
                  type='number'
                  id='credit'
                  value={creditTotal}
                  onChange={handleCreditChange}
                  className='w-20'
               />
            </div>
         </div>
      );
   };
   const renderEmptyCart = () => {
      return (
         <div>
            <p className='font-semibold '>Your cart is empty</p>
         </div>
      );
   };

   const renderCartItems = () => {
      return (
         <>
            {cartItems?.map((item, index) => {
               if (item.is_teetime) {
                  return (
                     <div key={index}>
                        <div>
                           <div className='flex items-center   leading-8 gap-5 border-b-1 border-color dark:border-gray-600 p-4'>
                              <img
                                 className='rounded-lg h-50 w-20'
                                 src={'./img/' + item.image}
                                 alt=''
                              />
                              <div>
                                 <p className='font-semibold '>
                                    {item.first_name} {item.last_name}
                                 </p>
                                 <p className='text-gray-600 dark:text-gray-400 text-sm font-semibold'>
                                    {moment
                                       .utc(item.teetime)
                                       .format('MM/DD: HH:mm A')}
                                 </p>
                                 <p className='text-gray-600 dark:text-gray-400 text-sm font-semibold'>
                                    {item.roundType} - {item.transportation}
                                 </p>
                                 <div className='flex gap-4 mt-2 items-center'>
                                    <p className='font-semibold text-sm'>
                                       {formatMoney(item.price)}
                                    </p>
                                 </div>
                              </div>
                              <div>
                                 <button
                                    type='button'
                                    disabled={isPaid}
                                    onClick={() => handleRemove(item.id)}
                                    style={{ color: '#f44336' }}
                                    className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
                                 >
                                    <RiDeleteBin6Line />
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                  );
               } else {
                  return (
                     <div key={index}>
                        <div>
                           <div className='flex items-center   leading-8 gap-5 border-b-1 border-color dark:border-gray-600 p-4'>
                              <img
                                 className='rounded-lg h-80 w-24'
                                 src={'./img/' + item.image}
                                 alt=''
                              />
                              <div>
                                 <p className='font-semibold '>{item.name}</p>
                                 <p className='text-gray-600 dark:text-gray-400 text-sm font-semibold'>
                                    {item.category}
                                 </p>
                                 <div className='flex gap-4 mt-2 items-center'>
                                    <p className='font-semibold text-lg'>
                                       {formatMoney(item.price)}
                                    </p>
                                    <div className='flex items-center border-1 border-r-0 border-color rounded'>
                                       <p
                                          className='p-2 border-r-1 dark:border-gray-600 border-color text-red-600 '
                                          onClick={() =>
                                             toggleCartItemQuantity(
                                                item.id,
                                                'dec'
                                             )
                                          }
                                       >
                                          <AiOutlineMinus />
                                       </p>
                                       <p className='p-2 border-r-1 border-color dark:border-gray-600 text-green-600'>
                                          {item.quantity}
                                       </p>
                                       <p
                                          className='p-2 border-r-1 border-color dark:border-gray-600 text-green-600'
                                          onClick={() =>
                                             toggleCartItemQuantity(
                                                item.id,
                                                'inc'
                                             )
                                          }
                                       >
                                          <AiOutlinePlus />
                                       </p>
                                    </div>
                                    <div>
                                       <button
                                          type='button'
                                          disabled={isPaid}
                                          onClick={() =>
                                             handleRemoveProduct(item.id)
                                          }
                                          style={{ color: '#f44336' }}
                                          className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
                                       >
                                          <RiDeleteBin6Line />
                                       </button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  );
               }
            })}
            <div className='flex items-center   leading-8 gap-5 border-b-1 border-color dark:border-gray-600 p-4'>
               <img
                  className='rounded-lg h-80 w-24'
                  src={'./img/discount.png'}
                  alt=''
               />
               <div>
                  <TextField
                     id='discount'
                     label='Discount'
                     type='number'
                     variant='outlined'
                     value={discount}
                     onChange={(e) => addDiscount(e.target.value)}
                     sx={{ width: '100px' }}
                  />
               </div>
               <div>
                  <button
                     type='button'
                     disabled={isPaid}
                     onClick={() => {}}
                     style={{ color: '#f44336' }}
                     className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
                  >
                     <AddTaskIcon />
                  </button>
               </div>
            </div>
            <div className='flex items-center   leading-8 gap-5 border-b-1 border-color dark:border-gray-600 p-4'>
               <img
                  className='rounded-lg h-80 w-24'
                  src={'./img/coupon.png'}
                  alt=''
               />
               <div>
                  <TextField
                     id='coupon'
                     label='Coupon'
                     type='text'
                     variant='outlined'
                     value={coupon}
                     onChange={handleCouponChange}
                     sx={{ width: '100px' }}
                  />
               </div>
               <div>
                  <button
                     type='button'
                     disabled={isPaid}
                     onClick={() => applyCoupon()}
                     style={{ color: '#f44336' }}
                     className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
                  >
                     <AddTaskIcon />
                  </button>
               </div>
            </div>
            {couponError && (
               <div className='flex items-center   leading-8 gap-5 border-b-1 border-color dark:border-gray-600 p-4'>
                  <Alert severity='error'>
                     Invalid coupon or has been used
                  </Alert>
               </div>
            )}

            <div className='mt-3 mb-3'>
               <div className='flex justify-between items-center'>
                  <p className='text-gray-500 dark:text-gray-200'>Sub Total:</p>
                  <p className='font-semibold'>{formatMoney(totalPrice)}</p>
               </div>
               <div className='flex justify-between items-center mt-3'>
                  <p className='text-gray-500 dark:text-gray-200'>
                     Tax and Fees:
                  </p>
                  <p className='font-semibold'>{formatMoney(taxFee)}</p>
               </div>
               <div className='flex justify-between items-center mt-3'>
                  <p className='text-gray-500 dark:text-gray-200'>Discount:</p>
                  <p style={{ color: '#f44336' }} className='font-semibold'>
                     {formatMoney(discount * 1.0)}
                  </p>
               </div>
               <div className='flex justify-between items-center mt-3'>
                  <p className='text-gray-500 dark:text-gray-200'>Total:</p>
                  <p className='font-semibold'>{formatMoney(grandTotal)}</p>
               </div>
            </div>
            <div>
               <Error error={errorMessage} />
            </div>
            {isSplit && renderSplitPayment()}
            <div className='flex justify-between items-center mt-3'>
               <LoadingButton
                  variant='contained'
                  disabled={
                     isPaid ||
                     (isSplit && creditTotal <= taxFee) ||
                     (isSplit && creditTotal <= 0)
                  }
                  loading={isProcessingCredit}
                  loadingPosition='start'
                  startIcon={<CreditCardIcon />}
                  onClick={onProcessCreditPayment}
               >
                  Credit
               </LoadingButton>
               <LoadingButton
                  variant='contained'
                  disabled={isPaid || (isSplit && cashTotal <= 0)}
                  loading={isProcessingCash}
                  loadingPosition='start'
                  startIcon={<AttachMoneyIcon />}
                  onClick={onProcessCashPayment}
               >
                  Cash
               </LoadingButton>
               <LoadingButton
                  variant='contained'
                  disabled={isPaid}
                  loadingPosition='start'
                  startIcon={<CallSplitIcon />}
                  onClick={onProcessSplitPayment}
               >
                  Split
               </LoadingButton>
            </div>
         </>
      );
   };

   const handleCashChange = (e) => {
      setCashTotal(e.target.value);
      let creditTotal = orderBalance - e.target.value;
      setCreditTotal(creditTotal);
   };

   const handleCreditChange = (e) => {
      setCreditTotal(e.target.value);
      let cashTotal = orderBalance - e.target.value;
      setCashTotal(cashTotal);
   };

   return (
      <div className='bg-half-transparent w-full fixed nav-item top-0 right-0 '>
         <div className='float-right h-screen overflow-scroll  duration-1000 ease-in-out dark:text-gray-200 transition-all dark:bg-[#484B52] bg-white md:w-400 p-8'>
            <div className='flex justify-between items-center'>
               <p className='font-semibold text-lg'>Shopping Cart</p>
               <Button
                  icon={<MdOutlineCancel />}
                  color='rgb(153, 171, 180)'
                  bgHoverColor='light-gray'
                  size='2xl'
                  borderRadius='50%'
               />
            </div>
            {!cartItems.length ? renderEmptyCart() : renderCartItems()}
         </div>
      </div>
   );
};

export default Cart;
