import axios from "axios";
import { useQuery } from "react-query";

const fetchCategories = async () => {
   const { data } = await axios.post(
      process.env.REACT_APP_API_SERVER + `/api/category/all`
   );

   return data.result;
};

export function useCategories() {
   return useQuery("categories", () => fetchCategories());
}
