import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import LeagueScheduleDetail from "./LeagueScheduleDetail";
import { useLeagueScheduleByDate } from "../services/hooks/useLeagueScheduleByDate";
import moment from "moment";
import LeaguePlayerPaymentDialog from "./LeaguePlayerPaymentDialog";

const LeaguePlayerCheckin = () => {
   const date = "2022-08-10";
   const { data: schedules } = useLeagueScheduleByDate(date);
   const [schedule_id, setSchedule_id] = React.useState(null);
   const [paymentOpen, setPaymentOpen] = React.useState(false);

   const handleScheduleChange = (event) => {
      setSchedule_id(event.target.value);
   };

   return (
      <div>
         <div className="flex m-4 pt-3">
            <FormControl sx={{ width: "40%" }}>
               <InputLabel id="schedule-select-label">Schedule:</InputLabel>
               <Select
                  labelId="schedule-select-label"
                  id="schedule-select"
                  value={schedule_id}
                  label="schedule"
                  onChange={handleScheduleChange}
               >
                  {schedules?.map((schedule) => (
                     <MenuItem key={schedule.id} value={schedule.id}>
                        {schedule.name} - {date}
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>
         </div>
         <div className="mt-5">
            {schedule_id && <LeagueScheduleDetail leagueScheduleId={1} />}
         </div>
      </div>
   );
};

export default LeaguePlayerCheckin;
