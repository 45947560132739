import React from 'react';
import { useCartContext } from '../contexts/CartProvider';

import { useStateContext } from '../contexts/ContextProvider';

const Button = ({
   icon,
   bgColor,
   color,
   bgHoverColor,
   size,
   text,
   borderRadius,
   width,
}) => {
   const { setIsClicked, initialState } = useStateContext();
   const { addDiscount } = useCartContext();
   const handClick = () => {
      setIsClicked(initialState);
      addDiscount(0);
   };
   return (
      <button
         type='button'
         onClick={handClick}
         style={{ backgroundColor: bgColor, color, borderRadius }}
         className={` text-${size} p-3 w-${width} hover:drop-shadow-xl hover:bg-${bgHoverColor}`}
      >
         {icon} {text}
      </button>
   );
};

export default Button;
