import authHeader from "../../services/authHeader";
import axios from "axios";
import { useMutation } from "react-query";

const updateStatus = async (value) => {
   const { data } = await axios.post(
      process.env.REACT_APP_API_SERVER + "/api/order/status",
      value,
      { headers: authHeader() }
   );
   return data;
};

export function usePaymentStatus() {
   const { isLoading, mutateAsync } = useMutation(updateStatus);
   return { isUpdating: isLoading, updatePaymentStatus: mutateAsync };
}
