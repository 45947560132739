import React, { useRef } from "react";

import {
   GridComponent,
   ColumnsDirective,
   ColumnDirective,
   Inject,
   Toolbar,
   Edit,
   Page,
} from "@syncfusion/ej2-react-grids";
import { DataManager, UrlAdaptor } from "@syncfusion/ej2-data";
import LeaguePlayerPaymentDialog from "./LeaguePlayerPaymentDialog";

const LeagueScheduleDetail = ({ leagueScheduleId }) => {
   const gridRef = useRef(null);
   const [paymentOpen, setPaymentOpen] = React.useState(false);
   const [LeagueScheduleDetailId, setLeagueScheduleDetailId] =
      React.useState(null);
   const [leagueScheduleDetail, setLeagueScheduleDetail] = React.useState(null);
   const toolbarOptions = ["Add"];
   const editSettings = {
      allowEditing: false,
      allowAdding: true,
      allowDeleting: false,
      newRowPosition: "Top",
   };

   const dataManager = new DataManager({
      adaptor: new UrlAdaptor(),
      insertUrl:
         process.env.REACT_APP_API_SERVER +
         `/api/league/schedule/player/insert?league_schedule_id=${leagueScheduleId}`,
      url:
         process.env.REACT_APP_API_SERVER +
         `/api/league/schedule/details?league_schedule_id=${leagueScheduleId}`,
   });

   function paymentTemp(props) {
      if (props.payment_status === 0) {
         return <span className="text-red-600">UnPaid</span>;
      } else return <span className="text-green-600">Paid</span>;
   }
   const handleDoubleClick = (args) => {
      if (args.cellIndex > 0) {
         setLeagueScheduleDetailId(args.rowData.id);
         setLeagueScheduleDetail(args.rowData);
         setPaymentOpen(true);
      }
   };

   const handlePaymentClose = () => {
      
      setPaymentOpen(false);
   };
   return (
      <div className="mt-5 w-full">
         <GridComponent
            dataSource={dataManager}
            ref={gridRef}
            editSettings={editSettings}
            toolbar={toolbarOptions}
            allowPaging={false}
            recordDoubleClick={handleDoubleClick}
         >
            <ColumnsDirective>
               <ColumnDirective
                  field="id"
                  headerText="ID"
                  width="60"
                  allowEditing={false}
                  isPrimaryKey={true}
               />
               <ColumnDirective
                  field="last_name"
                  headerText="Last Name"
                  width="150"
                  allowEditing={false}
               />
               <ColumnDirective
                  field="first_name"
                  headerText="First Name"
                  width="150"
                  allowEditing={false}
               />
               <ColumnDirective
                  field="mobile_phone"
                  headerText="Phone"
                  width="150"
                  allowEditing={false}
               />
               <ColumnDirective
                  field="email"
                  headerText="Email"
                  width="150"
                  allowEditing={false}
               />
               <ColumnDirective
                  field="payment_status"
                  headerText="Status"
                  width="150"
                  allowEditing={false}
                  template={paymentTemp}
               />
            </ColumnsDirective>
            <Inject services={[Page, Toolbar, Edit]} />
         </GridComponent>
         {paymentOpen && (
            <LeaguePlayerPaymentDialog
               open={paymentOpen}
               onClose={handlePaymentClose}
               leagueScheduleDetail={leagueScheduleDetail}
               leagueScheduleId={leagueScheduleId}
            />
         )}
      </div>
   );
};

export default LeagueScheduleDetail;
