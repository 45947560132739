import React from "react";
import {
   GridComponent,
   ColumnsDirective,
   ColumnDirective,
   Page,
   Selection,
   Inject,
   Edit,
   Toolbar,
   Sort,
   Filter,
   Search,
} from "@syncfusion/ej2-react-grids";

import { Header } from "../components";
import { useMembers } from "../services/hooks/useMembers";

const Members = () => {
   const { data } = useMembers();
   const toolbarOptions = ["Search"];
   return (
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
         <Header title="Members" />
         <GridComponent
            dataSource={data}
            toolbar={toolbarOptions}
            enableHover={false}
            allowPaging
            pageSettings={{ pageSize: 10 }}
            allowSorting
         >
            <ColumnsDirective>
               <ColumnDirective
                  field="first_name"
                  headerText="First name"
                  width="80"
               />
               <ColumnDirective
                  field="last_name"
                  headerText="Name"
                  width="120"
               />
               <ColumnDirective field="email" headerText="Email" width="150" />
               <ColumnDirective
                  field="mobile_phone"
                  headerText="Phone"
                  width="100"
               />
               <ColumnDirective
                  field="member_type_name"
                  headerText="Type"
                  width="100"
               />
            </ColumnsDirective>
            <Inject
               services={[Page, Selection, Toolbar, Edit, Sort, Filter, Search]}
            />
         </GridComponent>
      </div>
   );
};

export default Members;
