import React from "react";
import {
   Card,
   CardActions,
   CardContent,
   CardHeader,
   TextField,
} from "@mui/material";

import { useSnackbar } from "../contexts/SnackbarProvider";
import { useFormik } from "formik";
import * as Yup from "yup";

import moment from "moment";
import { LoadingButton } from "@mui/lab";

import { useUnblockTeetime } from "../services/hooks/useUnblockTeetime";

const UnblockTeeTime = () => {
   const { isUnblocking, unblockTeetime } = useUnblockTeetime();

   const snackbar = useSnackbar();
   const handleSubmit = (values) => {
      unblockTeetime(values)
         .then(() => {
            formik.resetForm();
            snackbar.success("Teetimes have been updated.");
         })
         .catch((err) =>
            snackbar.error(
               "Something went wrong! If the problem persists, contact us!"
            )
         );
   };
   const formik = useFormik({
      initialValues: {
         startDate: moment().format("YYYY-MM-DD"),
         endDate: moment().format("YYYY-MM-DD"),
         startTime: "07:00",
         endTime: "19:00",
      },
      validationSchema: Yup.object({
         startDate: Yup.string().required("Required"),
         endDate: Yup.string().required("Required"),
         startTime: Yup.string().required("Required"),
         endTime: Yup.string().required("Required"),
      }),
      onSubmit: (values) => handleSubmit(values),
   });

   return (
      <form onSubmit={formik.handleSubmit} noValidate>
         <Card sx={{ width: "300px", marginTop: "20px" }}>
            <CardHeader title={"Unlock Teetimes"} />
            <CardContent>
               <TextField
                  margin="normal"
                  id="startDate"
                  label="Start Date"
                  variant="outlined"
                  disabled={isUnblocking}
                  value={formik.values.startDate}
                  onChange={formik.handleChange}
                  error={formik.touched.start && Boolean(formik.errors.start)}
                  helperText={formik.touched.start && formik.errors.start}
               />
               <TextField
                  margin="normal"
                  id="endDate"
                  label="End Date"
                  variant="outlined"
                  disabled={isUnblocking}
                  value={formik.values.endDate}
                  onChange={formik.handleChange}
                  error={formik.touched.end && Boolean(formik.errors.end)}
                  helperText={formik.touched.end && formik.errors.end}
               />
               <TextField
                  margin="normal"
                  id="startTime"
                  label="Start Time"
                  variant="outlined"
                  disabled={isUnblocking}
                  value={formik.values.startTime}
                  onChange={formik.handleChange}
                  error={
                     formik.touched.startTime &&
                     Boolean(formik.errors.startTime)
                  }
                  helperText={
                     formik.touched.startTime && formik.errors.startTime
                  }
               />
               <TextField
                  margin="normal"
                  id="endTime"
                  label="End Time"
                  variant="outlined"
                  disabled={isUnblocking}
                  value={formik.values.endTime}
                  onChange={formik.handleChange}
                  error={
                     formik.touched.endTime && Boolean(formik.errors.endTime)
                  }
                  helperText={formik.touched.endTime && formik.errors.endTime}
               />
            </CardContent>
            <CardActions>
               <LoadingButton
                  type="submit"
                  loading={isUnblocking}
                  variant="contained"
               >
                  {"Unblock"}
               </LoadingButton>
            </CardActions>
         </Card>
      </form>
   );
};

export default UnblockTeeTime;
