import axios from "axios";
import { useQuery } from "react-query";

const fetchTopProducts = async () => {
   const { data } = await axios.get(
      process.env.REACT_APP_API_SERVER + "/api/product/top"
   );
   return data;
};

export function useTopProducts() {
   return useQuery("top_products", () => fetchTopProducts());
}
