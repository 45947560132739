import axios from "axios";
import { useMutation } from "react-query";

const addTeetimes = async (values) => {
   const { data } = await axios.post(
      process.env.REACT_APP_API_SERVER + "/api/teetimes",
      values
   );
   return data;
};

export function useCreateTeetime() {
   const { isLoading, mutateAsync } = useMutation(addTeetimes);
   return { isAdding: isLoading, addTeetimes: mutateAsync };
}
