import React from "react";
import {
   FormControl,
   IconButton,
   InputLabel,
   Paper,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Select,
   Typography,
   MenuItem,
} from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useProductsByCategory } from "../services/hooks/useProductsByCategory";
import { useCategories } from "../services/hooks/useCategories";
import { useCartContext } from "../contexts/CartProvider";
import { formatMoney } from "../services/utils";

const Product = () => {
   const [category_id, setCategory_id] = React.useState(1);
   const { data: categories } = useCategories();
   const { data: products } = useProductsByCategory(category_id);
   const { addToCart, onAdd } = useCartContext();

   const handleCategoryChange = (event) => {
      setCategory_id(event.target.value);
   };

   function handleAddToCart(product_id) {
      onAdd(
         {
            id: product_id,
            name: products.find((product) => product.id === product_id).name,
            image: products.find((product) => product.id === product_id).image,
            price: products.find((product) => product.id === product_id)
               .sales_price,
            tax: products.find((product) => product.id === product_id).tax,
            is_teetime: false,
         },
         1
      );
   }

   return (
      <div>
         <div className="flex m-4 pt-3">
            <FormControl sx={{ width: "40%" }}>
               <InputLabel id="category-select-label">Category:</InputLabel>
               <Select
                  labelId="category-select-label"
                  id="category-select"
                  value={category_id}
                  label="Category"
                  onChange={handleCategoryChange}
               >
                  {categories?.map((category) => (
                     <MenuItem key={category.id} value={category.id}>
                        {category.name}
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>
         </div>
         <div>
            <TableContainer component={Paper} sx={{ width: "80%" }}>
               <Table sx={{ minWidth: 300 }} aria-label="simple table">
                  <TableHead>
                     <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell align="left">Image</TableCell>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="center">Price</TableCell>
                        <TableCell align="left"></TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {products?.map((product) => (
                        <TableRow key={product.id}>
                           <TableCell>{product.id}</TableCell>
                           <TableCell>
                              <img
                                 className="rounded-lg h-30 w-16"
                                 src={"./img/" + product.image}
                                 alt=""
                              />
                           </TableCell>
                           <TableCell>{product.name}</TableCell>
                           <TableCell align="center">
                              {formatMoney(product.price)}
                           </TableCell>
                           <TableCell>
                              <IconButton
                                 color="primary"
                                 aria-label="add to shopping cart"
                                 onClick={() => handleAddToCart(product.id)}
                              >
                                 <AddShoppingCartIcon />
                              </IconButton>
                           </TableCell>
                        </TableRow>
                     ))}
                  </TableBody>
               </Table>
            </TableContainer>
         </div>
      </div>
   );
};

export default Product;
