import axios from "axios";
import { useMutation } from "react-query";

const redeemCoupon = async (value) => {
   const { data } = await axios.post(
      process.env.REACT_APP_API_SERVER + "/api/order/coupon",
      value
   );
   return data;
};
export function useRedeemCoupon() {
   const { isLoading, mutate } = useMutation(redeemCoupon);
   return { isRedeemCoupon: isLoading, redeemCoupon: mutate };
}
