import React from "react";
import { useParams } from "react-router";
import { useEventOrderItemsByDate } from "../services/hooks/useEventOrderItemsByDate";
import moment from "moment";

import { formatMoney } from "../services/utils";

const EventRevenueReport = () => {
   const { data } = useParams();

   const start = data.split("&")[0];
   const end = data.split("&")[1];
   const [startDate, setStartDate] = React.useState(start);
   const [endDate, setEndDate] = React.useState(end);
   const { isLoading, data: items } = useEventOrderItemsByDate(
      startDate,
      endDate
   );

   if (isLoading) {
      return <div>Loading...</div>;
   }
   let ids = items.map((item) => item.events_id);
   let eventIds = [...new Set(ids)];

   function getReportTotal() {
      let total = 0;
      items.forEach((item) => {
         total += item.price * item.quantity;
      });
      return total;
   }

   function getItemsTotal(eventItems) {
      let total = 0;
      eventItems.forEach((item) => {
         total += item.price * item.quantity;
      });
      return total;
   }
   function getItemsRows(eventItems) {
      return eventItems.map((item) => {
         return (
            <>
               <tr className="">
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"></td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"></td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                     {item.name}
                  </td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"></td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                     {item.quantity}
                  </td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                     {formatMoney(item.price)}
                  </td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                     {formatMoney(item.tax * item.price * item.quantity)}
                  </td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                     {formatMoney(item.price * item.quantity)}
                  </td>
               </tr>
            </>
         );
      });
   }
   function getEventItems(event_id) {
      let eventItems = items.filter((item) => {
         if (item.events_id === event_id) {
            return item;
         }
      });

      return (
         <>
            <tr className="border-b-5 border-t">
               <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {moment(eventItems[0].event_date).format("YYYY-MM-DD")}
               </td>
               <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                  {eventItems[0].event_name}
               </td>
               <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                  {eventItems[0].event_type}
               </td>
               <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                  {eventItems[0].status}
               </td>
               <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"></td>
               <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"></td>
               <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"></td>
               <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"></td>
            </tr>
            {getItemsRows(eventItems)}
            <tr className="border-b">
               <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"></td>
               <td
                  className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
                  colSpan={2}
               >
                  Subtotal for {eventItems[0].event_name}
               </td>
               <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"></td>
               <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"></td>
               <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"></td>
               <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"></td>
               <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                  {formatMoney(getItemsTotal(eventItems))}
               </td>
            </tr>
         </>
      );
   }
   return (
      <div className="w-full">
         <div className="flex flex-row justify-center mb-2">
            <span className="text-xl font-semibold">Richland Golf Club</span>
         </div>
         <div className="flex flex-row justify-center mb-2">
            <span className="text-xl font-semibold">
               Event Revenue Forecasting Report
            </span>
         </div>
         <div className="flex flex-row justify-center mb-5">
            <span className="text-xl font-semibold">
               Dates From:{start} Through {end}
            </span>
         </div>
         <div>
            <table className="min-w-full">
               <thead className="border-b">
                  <tr>
                     <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                     >
                        Date
                     </th>
                     <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                     >
                        Event
                     </th>
                     <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                     >
                        Type
                     </th>
                     <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                     >
                        Status
                     </th>
                     <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                     >
                        Qty
                     </th>
                     <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                     >
                        Price
                     </th>
                     <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                     >
                        Tax
                     </th>
                     <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                     >
                        Total
                     </th>
                  </tr>
               </thead>
               <tbody>
                  {eventIds.map((event_id) => {
                     return getEventItems(event_id);
                  })}
                  <tr className="border-t-5">
                     <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"></td>
                     <td
                        className="text-lg px-6 py-4 whitespace-nowrap"
                        colSpan={2}
                     >
                        Report Total
                     </td>
                     <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"></td>
                     <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"></td>
                     <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"></td>
                     <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"></td>
                     <td className="text-lg px-6 py-4 whitespace-nowrap">
                        {formatMoney(getReportTotal())}
                     </td>
                  </tr>
               </tbody>
            </table>
         </div>
      </div>
   );
};

export default EventRevenueReport;
