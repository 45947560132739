import axios from "axios";
import { useMutation } from "react-query";

const updateLeaguePlayerPaymentStatus = async (value) => {
   const { data } = await axios.post(
      process.env.REACT_APP_API_SERVER + `/api/league/schedule/payment`,
      value
   );
   return data;
};

export function useUpdateLeaguePlayerPaymentStatus() {
   const { isLoading, mutateAsync } = useMutation(
      updateLeaguePlayerPaymentStatus
   );
   return {
      isUpdating: isLoading,
      updateLeaguePlayerPaymentStatus: mutateAsync,
   };
}
