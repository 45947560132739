import {
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   FormControl,
   FormControlLabel,
   FormLabel,
   Grid,
   Radio,
   RadioGroup,
   Button,
   InputLabel,
   OutlinedInput,
   InputAdornment,
   TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";

import { formatMoney } from "../services/utils";
import { useAddEventPayment } from "../services/hooks/useAddEventPayment";
import { useStateContext } from "../contexts/ContextProvider";
import { useAuth } from "../contexts/AuthProvider";
import { useAddTransaction } from "../services/hooks/useAddTransaction";

const EventPaymentDialog = ({ open, onClose, event }) => {
   const [amount, setAmount] = React.useState(0);
   const [paymentType, setPaymentType] = React.useState("1");
   const [notes, setNotes] = React.useState("");
   const [isProcessing, setIsProcessing] = React.useState(false);
   const { isAddingTransaction, addTransaction } = useAddTransaction();
   const [isPaid, setIsPaid] = useState(false);
   const { userInfo } = useAuth();

   const { addEventPayment } = useAddEventPayment();
   const { currentPos } = useStateContext();

   const socketUrl = `ws://${currentPos}:13000`;
   const serviceFeeLabel = "Tax and Fee";

   async function processMessage(msg) {
      try {
         //check if msg start with "charge"
         if (msg.startsWith("ChargeanywhereTransactionResult")) {
            //substring from 0 to index
            msg = msg.substring(msg.indexOf("?") + 1);
            //split msg into array
            let msgArray = msg.split("&");
            //convert array to json
            let msgJson = {};
            msgArray.forEach((item) => {
               let itemArray = item.split("=");
               msgJson[itemArray[0]] = itemArray[1];
            });

            msgJson.orderId = 0;
            msgJson.userId = userInfo.id;
            msgJson.eventId = event[0].id;
            let result = await addTransaction(msgJson);
            let tax = 0.0;
            if (paymentType === "1") {
               tax = amount * 0.06;
            }
            await addEventPayment({
               event_id: event[0].id,
               amount,
               payment_type: paymentType,
               notes,
               transaction_id: result.id,
               user_id: userInfo.id,
               tax,
            });
            if (msgJson.TransactionResult === "APPROVED") {
               setIsPaid(true);
               onClose();
            }
            // } else {
            //    console.log("Payment failed");
            //    snackbar.error("Payment cancelled");
            // }
            setIsProcessing(false);
         }
      } catch (e) {
         console.log(e);
      }
   }

   const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
      onOpen: () => console.log("opened"),
      onMessage: (message) => processMessage(message.data),
      //Will attempt to reconnect on all close events, such as server shutting down
      shouldReconnect: (closeEvent) => true,
   });
   const connectionStatus = {
      [ReadyState.CONNECTING]: "Connecting",
      [ReadyState.OPEN]: "Open",
      [ReadyState.CLOSING]: "Closing",
      [ReadyState.CLOSED]: "Closed",
      [ReadyState.UNINSTANTIATED]: "Uninstantiated",
   }[readyState];

   const onProcessCreditPayment = async () => {
      try {
         if (connectionStatus === "Open") {
            setIsProcessing(true);
            let msg = `ChargeanywhereProcessTransaction://?version=1.0&transactionType=1&tenderType=2&saleAmount=${amount}&serviceFeeLabel=${serviceFeeLabel}&serviceFeeAmount=${
               amount * 0.06
            }&clerkNumber=${userInfo.id}&invoiceNumber=${event[0].id}`;

            sendMessage(msg);
         } else {
            alert("POS is not connected");
         }
      } catch (err) {
         console.log(err);
      }
   };

   const onProcessCashPayment = async () => {
      if (connectionStatus === "Open") {
         setIsProcessing(true);

         let msg = `ChargeanywhereProcessTransaction://?version=1.0&transactionType=1&tenderType=5&saleAmount=${amount}&serviceFeeLabel=${serviceFeeLabel}&serviceFeeAmount=${0}&clerkNumber=${
            userInfo.id
         }&invoiceNumber=${event[0].id}`;
         sendMessage(msg);
      } else {
         alert("POS is not connected");
      }
   };

   async function handlePayment() {
      try {
         if (paymentType === "1") {
            //credit
            onProcessCreditPayment();
         } else if (paymentType === "2") {
            //cash
            onProcessCashPayment();
         } else {
            //check
            await addEventPayment({
               event_id: event[0].id,
               amount,
               payment_type: paymentType,
               notes,
               transaction_id: 0,
               user_id: userInfo.id,
            });
            setIsProcessing(false);
            onClose();
         }
      } catch (e) {
         console.log(e);
      }
   }
   return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
         <DialogTitle>Event Payment</DialogTitle>
         <DialogContent>
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
               <Grid item xs={3}>
                  Event:
               </Grid>
               <Grid item xs={9}>
                  {event[0].event_name}
               </Grid>
               <Grid item xs={3}>
                  Total:
               </Grid>
               <Grid item xs={9}>
                  {formatMoney(event[0].total)}
               </Grid>
               <Grid item xs={3}>
                  Paid:
               </Grid>
               <Grid item xs={9}>
                  {formatMoney(event[0].payment)}
               </Grid>
               <Grid item xs={3}>
                  Balance:
               </Grid>
               <Grid item xs={9}>
                  {formatMoney(event[0].balance)}
               </Grid>
               <Grid item xs={6}>
                  <FormControl>
                     <FormLabel id="demo-radio-buttons-group-label">
                        Payment Method
                     </FormLabel>
                     <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={paymentType}
                        onChange={(e) => setPaymentType(e.target.value)}
                        name="radio-buttons-group"
                     >
                        <FormControlLabel
                           value="1"
                           control={<Radio />}
                           label="Credit card"
                        />
                        <FormControlLabel
                           value="2"
                           control={<Radio />}
                           label="Cash"
                        />
                        <FormControlLabel
                           value="3"
                           control={<Radio />}
                           label="Check"
                        />
                     </RadioGroup>
                  </FormControl>
               </Grid>
               <Grid item xs={6}>
                  <FormControl fullWidth sx={{ m: 1 }}>
                     <InputLabel htmlFor="outlined-adornment-amount">
                        Amount
                     </InputLabel>
                     <OutlinedInput
                        id="outlined-adornment-amount"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        startAdornment={
                           <InputAdornment position="start">$</InputAdornment>
                        }
                        label="Amount"
                     />
                  </FormControl>
                  <TextField
                     fullWidth
                     label="Notes"
                     id="notes"
                     value={notes}
                     onChange={(e) => setNotes(e.target.value)}
                  />
               </Grid>
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button color="primary" onClick={onClose}>
               Cancel
            </Button>
            <LoadingButton
               color="info"
               variant="contained"
               onClick={handlePayment}
               loading={isProcessing}
            >
               Make Payment
            </LoadingButton>
         </DialogActions>
      </Dialog>
   );
};

export default EventPaymentDialog;
