import React from "react";

import { LoadingButton } from "@mui/lab";
import {
   Card,
   CardActions,
   CardContent,
   CardHeader,
   FormControl,
   InputLabel,
   MenuItem,
   TextField,
   Select,
} from "@mui/material";
import { useSnackbar } from "../contexts/SnackbarProvider";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { useLeagues } from "../services/hooks/useLeagues";
import { useAddLeagueSchedule } from "../services/hooks/useAddLeagueSchedule";
import { useQueryClient } from "react-query";

const AddLeagueSchedule = () => {
   const snackbar = useSnackbar();
   const queryClient = useQueryClient();
   const [day_of_week, setDay_of_week] = React.useState(3);
   const [league_id, setLeague_id] = React.useState(1);
   const { data: leagues, loading, error } = useLeagues();
   const { isAdding, addLeagueSchedule } = useAddLeagueSchedule();
   const handleSubmit = async (values) => {
      let val = { ...values, day_of_week, league_id };

      addLeagueSchedule(val)
         .then(() => {
            formik.resetForm();
            snackbar.success("League schedule has been updated.");
         })
         .catch((err) =>
            snackbar.error(
               "Something went wrong! If the problem persists, contact us!"
            )
         );
      //refresh cache for league schedules
      queryClient.invalidateQueries("league_schedules");
   };

   const formik = useFormik({
      initialValues: {
         start: moment().format("YYYY-MM-DD"),
         end: moment().add(7, "days").format("YYYY-MM-DD"),
         startTime: "15:00",
         endTime: "17:00",
      },
      validationSchema: Yup.object({
         start: Yup.string().required("Required"),
         end: Yup.string().required("Required"),
         startTime: Yup.string().required("Required"),
         endTime: Yup.string().required("Required"),
      }),
      onSubmit: (values) => handleSubmit(values),
   });

   return (
      <form onSubmit={formik.handleSubmit} noValidate>
         <Card sx={{ width: "400px", marginTop: "20px" }}>
            <CardHeader title={"Add League Schedules"} />
            <CardContent>
               <FormControl sx={{ width: "80%" }}>
                  <InputLabel id="league-select-label">League:</InputLabel>
                  <Select
                     labelId="league-select-label"
                     id="league-select"
                     value={league_id}
                     label="Leagues"
                     onChange={(event) => setLeague_id(event.target.value)}
                  >
                     {leagues?.map((league) => (
                        <MenuItem key={league.id} value={league.id}>
                           {league.name}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
               <TextField
                  margin="normal"
                  id="start"
                  label="start"
                  variant="outlined"
                  disabled={isAdding}
                  value={formik.values.start}
                  onChange={formik.handleChange}
                  error={formik.touched.start && Boolean(formik.errors.start)}
                  helperText={formik.touched.start && formik.errors.start}
               />
               <TextField
                  margin="normal"
                  id="end"
                  label="end"
                  variant="outlined"
                  disabled={isAdding}
                  value={formik.values.end}
                  onChange={formik.handleChange}
                  error={formik.touched.end && Boolean(formik.errors.end)}
                  helperText={formik.touched.end && formik.errors.end}
               />
               <FormControl sx={{ m: 1, minWidth: 150 }}>
                  <InputLabel id="day_of_week-label">Day of Week</InputLabel>
                  <Select
                     labelId="day_of_week-label"
                     id="day_of_week"
                     value={day_of_week}
                     onChange={(event) => setDay_of_week(event.target.value)}
                     autoWidth
                     label="Day of Week"
                  >
                     <MenuItem value={0}>Sunday</MenuItem>
                     <MenuItem value={1}>Monday</MenuItem>
                     <MenuItem value={2}>Tuesday</MenuItem>
                     <MenuItem value={3}>Wednesday</MenuItem>
                     <MenuItem value={4}>Thursday</MenuItem>
                     <MenuItem value={5}>Friday</MenuItem>
                     <MenuItem value={6}>Saturday</MenuItem>
                  </Select>
               </FormControl>

               <TextField
                  margin="normal"
                  id="startTime"
                  label="Start Time"
                  variant="outlined"
                  disabled={isAdding}
                  value={formik.values.startTime}
                  onChange={formik.handleChange}
                  error={
                     formik.touched.startTime &&
                     Boolean(formik.errors.startTime)
                  }
                  helperText={
                     formik.touched.startTime && formik.errors.startTime
                  }
               />
               <TextField
                  margin="normal"
                  id="endTime"
                  label="End Time"
                  variant="outlined"
                  disabled={isAdding}
                  value={formik.values.endTime}
                  onChange={formik.handleChange}
                  error={
                     formik.touched.endTime && Boolean(formik.errors.endTime)
                  }
                  helperText={formik.touched.endTime && formik.errors.endTime}
               />
            </CardContent>
            <CardActions>
               <LoadingButton
                  type="submit"
                  loading={isAdding}
                  variant="contained"
               >
                  {"Update"}
               </LoadingButton>
            </CardActions>
         </Card>
      </form>
   );
};

export default AddLeagueSchedule;
