import React from "react";
import { Routes, Route } from "react-router-dom";
import { PrivateRoute } from "./components";
import {
   Home,
   Teetimes,
   PricingSheet,
   Customers,
   Users,
   Reports,
   Login,
   Settings,
   Products,
   TopProducts,
   Members,
   TeesheetReport,
   SalesReportPrint,
   Events,
} from "./pages";
import { useAuth } from "./contexts/AuthProvider";
import Booking from "./pages/Booking";
import EventRevenueReport from "./pages/EventRevenueReport";
import Test from "./pages/Test";
import Leagues from "./pages/Leagues";

const ROLE = {
   ADMIN: 3,
   MANAGER: 2,
   USER: 1,
};
const AppRoutes = () => {
   const { userInfo } = useAuth();
   return (
      <Routes>
         <Route element={<PrivateRoute isAllowed={!!userInfo} />}>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/members" element={<Members />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/products" element={<Products />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/top-products" element={<TopProducts />} />
            <Route path="/events" element={<Events />} />
            <Route path="/teetimes" element={<Teetimes />} />
            <Route path="/leagues" element={<Leagues />} />
         </Route>
         <Route
            element={
               <PrivateRoute
                  isAllowed={!!userInfo && userInfo.role_id >= ROLE.MANAGER}
               />
            }
         >
            <Route path="/pricing-sheet" element={<PricingSheet />} />
         </Route>
         <Route
            element={
               <PrivateRoute
                  isAllowed={!!userInfo && userInfo.role_id === ROLE.ADMIN}
                  redirectPath="/home"
               />
            }
         >
            <Route path="/users" element={<Users />} />
         </Route>
         <Route path="/teesheet-report/:date" element={<TeesheetReport />} />
         <Route
            path="/event-revenue-report/:data"
            element={<EventRevenueReport />}
         />
         <Route path="/sales-report/:date" element={<SalesReportPrint />} />
         <Route path="/test" element={<Test />} />
         <Route path="/login" element={<Login />} />
         <Route path="/403" element={403} />
      </Routes>
   );
};

export default AppRoutes;
