import axios from "axios";
import { useQuery } from "react-query";

const fetchLeagueSchedules = async () => {
   const { data } = await axios.post(
      process.env.REACT_APP_API_SERVER + `/api/league/schedule/all`
   );   return data.result;
};

export function useLeagueSchedules() {
   return useQuery("league_schedules", () => fetchLeagueSchedules());
}
