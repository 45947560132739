import axios from "axios";
import { useQuery } from "react-query";

const fetchCustomers = async () => {
   const { data } = await axios.get(
      process.env.REACT_APP_API_SERVER + `/api/customer`
   );

   return data;
};

export function useCustomers() {
   return useQuery("customers", () => fetchCustomers());
}
