import axios from "axios";
import { useQuery } from "react-query";
import moment from "moment";

const fetchTeeSheetTableData = async (date) => {
   const { data } = await axios.get(
      process.env.REACT_APP_API_SERVER +
         `/api/teetimes/teesheet_table_data/${date}`
   );
   return data;
};

export function useTeeSheetTableData(date) {
   return useQuery(
      ["tee-sheet", moment(date).format("YYYY-MM-DD")],
      () => fetchTeeSheetTableData(moment(date).format("YYYY-MM-DD")),
      {
         // Refetch the data every second
         refetchInterval: 1000,
      }
   );
}
